import { Popover, Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const PopoverStyled = styled(Popover)(() => ({
  marginTop: 24,
  [Popover.classes.paper]: {
    minWidth: 292,
    maxWidth: 320,
  },
}));

export const PopoverContentStyled = styled(Stack)(() => ({
  '.headerUser-popoverContent-wrapper': {
    padding: '12px 16px',
  } as CSSObject,
  '.headerUser-popoverContent-line': {
    backgroundColor: Colors.gray100,
    height: 1,
  } as CSSObject,
  '.headerUser-popoverContent-line2': {
    backgroundColor: Colors.gray50,
    height: 1,
  } as CSSObject,
}));
