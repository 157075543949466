import { DocumentGroupEntity, OccupationType } from 'api/types/entity';
import { UpdateClientParams } from 'api/types/params';
import { isGlobalProject } from 'config';
import { yesOrNot } from 'helpers/yesOrNo';
import {
  parseAdditionalInformationFileIdsParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseBiographyParam,
  parseCitizenshipsParam,
  parseServiceContractParam,
  parseAdditionalDocumentFileIdsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { ClientFormType } from '../../forms/clientForm';

import { getBankAccountsParam } from './getBankAccountsParam';
import { getBusinessRelationshipParam } from './getBusinessRelationshipParam';
import { getDebtsParam } from './getDebtsParam';
import { getFinancialPositionParam } from './getFinancialPositionParam';
import { getFinancingSourcesParam } from './getFinancingSourcesParam';
import { getKnowledgeAndExperienceParam } from './getKnowledgeAndExperienceParam';
import { getSourcesOfIncomeParam } from './getSourcesOfIncomeParam';
import { getW8BENFormParam } from './getW8BENFormParam';
import { getWithdrawalAccountsParam } from './getWithdrawalAccountsParam';

export const getClientSubmitData = (
  versionNumber: number,
  values: ClientFormType,
  documentGroups?: DocumentGroupEntity[]
): UpdateClientParams => {
  const isGlobal = isGlobalProject();
  const { placeOfBirth, registrationAddress, residentialAddress } =
    parseAddressWithFilesParam(values);
  return {
    versionNumber,
    reason: values.reasonChange.reason,
    firstName: values.personalData.firstName,
    lastName: values.personalData.lastName,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth,
    gender: values.personalData.gender?.value,
    // not for global project
    bankAccounts: !isGlobal ? getBankAccountsParam(values) : undefined,
    // not for global project
    biography: !isGlobal ? parseBiographyParam(values) : undefined,
    citizenships: parseCitizenshipsParam(values),
    // not for global project
    debts: !isGlobal
      ? getDebtsParam(
          values,
          values.biography?.occupation?.value === OccupationType.entrepreneur
        )
      : undefined,
    // not for global project
    financingSources: !isGlobal ? getFinancingSourcesParam(values) : undefined,

    financialPosition: isGlobal ? getFinancialPositionParam(values) : undefined,

    sourcesOfIncome: isGlobal ? getSourcesOfIncomeParam(values) : undefined,

    orderSubmissionContacts: values.orderSubmissionContacts.map(
      ({ type, value }) => ({
        value,
        type: type.value,
      })
    ),
    // not for global project
    registrationAddress: !isGlobal ? registrationAddress : undefined,
    residentialAddress,
    placeOfBirth,

    taxResidences: parseTaxResidencyParam(values, true),

    withdrawalAccounts: getWithdrawalAccountsParam(values),
    pepConnection: values.pepConnection.relation?.value!,
    // not for global project
    connectedPepInfo: !isGlobal ? parsePepConnectionParam(values) : undefined,
    accreditedInvestorStatus:
      yesOrNot(values.statuses.accreditedInvestorStatus?.value) ?? null,

    riskLevel: values.riskLevel.riskLevel?.value ?? null,
    informationUpdateFrequency:
      values.riskLevel.informationUpdateFrequency?.value ?? null,
    riskLevelReviewDate: values.riskLevel.riskLevelReviewDate ?? null,
    lastInformationUpdateDate:
      values.riskLevel.lastInformationUpdateDate ?? null,
    lastOperationMonitoringResults:
      values.riskLevel.lastOperationMonitoringResults ?? null,
    usedServices: values.riskLevel.usedServices ?? null,

    code: values.accountCodes.code ?? null,
    centralDepositoryAccountNumber:
      values.accountCodes.centralDepositoryAccountNumber ?? null,
    centralDepositoryAccountNumberOpeningDate:
      values.accountCodes.centralDepositoryAccountNumberOpeningDate ?? null,
    personalAccountNumber: values.accountCodes.personalAccountNumber,
    personalAccountNumberOpeningDate:
      values.accountCodes.personalAccountNumberOpeningDate,
    managerId: values.accountCodes.manager?.id ?? null,

    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),

    additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
      values.documentsFiles,
      documentGroups
    ),

    openAccountOrder: !isGlobal
      ? {
          date: values.openAccountOrder.date || null,
          number: values.openAccountOrder.number || null,
          orderReceivedDate: values.openAccountOrder.orderReceivedDate || null,
        }
      : undefined,

    category: values.general.category?.value || null,
    economicSector: values.general.economicSector?.value || null,
    taxBenefits: values.general.taxBenefits || null,
    managementType: values.general.managementType?.value || null,
    isFinancialMonitoringSubject:
      yesOrNot(values.general.isFinancialMonitoringSubject?.value) ?? null,
    isKazakhstanResident:
      yesOrNot(values.general.isKazakhstanResident?.value) ?? null,

    serviceContract: parseServiceContractParam(values),

    identityDocument: parseIdentityDocumentParam(values),

    tariff: values.general.tariff?.value,

    businessRelationship: isGlobal
      ? getBusinessRelationshipParam(values)
      : undefined,

    riskProfile: isGlobal ? values.riskProfile?.value : undefined,
    W8BENForm: isGlobal ? getW8BENFormParam(values) : undefined,

    knowledgeAndExperience: isGlobal
      ? getKnowledgeAndExperienceParam(values)
      : undefined,
  };
};
