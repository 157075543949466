// eslint-disable-next-line no-restricted-imports
import { MutationInput_ClientController_SaveOnboardingStatus_Input_Value as ClientOnboardingStatusTypeApi } from 'api/generated/graphql';
import { ClientOnboardingStatusType } from 'api/types/entity';

const statusMap: Record<
  ClientOnboardingStatusType,
  ClientOnboardingStatusTypeApi | undefined
> = {
  [ClientOnboardingStatusType.FillingOut]:
    ClientOnboardingStatusTypeApi.FillingOut,
  [ClientOnboardingStatusType.AccountOpened]:
    ClientOnboardingStatusTypeApi.AccountOpened,
  [ClientOnboardingStatusType.ComplianceCheck]:
    ClientOnboardingStatusTypeApi.ComplianceCheck,
  [ClientOnboardingStatusType.AccountOpeningDenied]:
    ClientOnboardingStatusTypeApi.AccountOpeningDenied,
  [ClientOnboardingStatusType.AmlCftCheck]:
    ClientOnboardingStatusTypeApi.AmlCftCheck,
  [ClientOnboardingStatusType.AccountOpening]:
    ClientOnboardingStatusTypeApi.AccountOpening,
  [ClientOnboardingStatusType.AwaitingAccountOpening]:
    ClientOnboardingStatusTypeApi.AwaitingAccountOpening,
  [ClientOnboardingStatusType.AwaitingAmlCftCheck]:
    ClientOnboardingStatusTypeApi.AwaitingAmlCftCheck,
  [ClientOnboardingStatusType.AwaitingComplianceCheck]:
    ClientOnboardingStatusTypeApi.AwaitingComplianceCheck,
  [ClientOnboardingStatusType.AwaitingValidation]:
    ClientOnboardingStatusTypeApi.AwaitingValidation,
  [ClientOnboardingStatusType.BoardReview]:
    ClientOnboardingStatusTypeApi.BoardReview,
  [ClientOnboardingStatusType.ValidationCheck]:
    ClientOnboardingStatusTypeApi.ValidationCheck,
  [ClientOnboardingStatusType.PendingBoardReview]:
    ClientOnboardingStatusTypeApi.PendingBoardReview,
  [ClientOnboardingStatusType.SigningDocuments]:
    ClientOnboardingStatusTypeApi.SigningDocuments,
  [ClientOnboardingStatusType.AwaitingPaymentConfirmation]: undefined,
  [ClientOnboardingStatusType.SigningQuestionnaire]: undefined,
};

export const parseClientOnboardingStatusParam = (
  onboardingStatus: ClientOnboardingStatusType
): ClientOnboardingStatusTypeApi =>
  statusMap[onboardingStatus] || ClientOnboardingStatusTypeApi.FillingOut;
