import { PasswordInput, PasswordInputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface PasswordFieldProps
  extends FieldType,
    Omit<PasswordInputProps, 'name' | 'onChange' | 'onChangeText' | 'value'> {}

/**
 * PhoneField.
 */
export const PasswordField = ({
  name,
  allowSubmit,
  ...props
}: PasswordFieldProps) => {
  const { field, helpers, inputProps } = useField<string | undefined>(name, {
    allowSubmit,
  });

  return (
    <PasswordInput
      {...props}
      name={name}
      value={field.value ?? ''}
      onBlur={field.onBlur(name)}
      onChange={() => helpers.setError('')}
      onChangeText={(v) => helpers.setValue(v)}
      {...inputProps}
    />
  );
};
