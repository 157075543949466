import { useTranslation } from 'libs/i18n';
import { useNavigate } from 'libs/navigation';

import { useSetPassword } from '../hooks';
import { SetPasswordView } from '../views/SetPasswordView';

export const SetPasswordContainer = () => {
  const { t } = useTranslation();
  const { state } = useNavigate<{}, { otpRequired: boolean }>();

  const {
    formErrors,
    submitLoading,
    isOpenVerificationDialog,
    onSubmit,
    onCloseVerificationDialog,
  } = useSetPassword(state?.otpRequired);

  if (!state) {
    window.location.replace('/');
    return null;
  }

  return (
    <SetPasswordView
      formErrors={formErrors}
      isOpenVerificationDialog={isOpenVerificationDialog}
      submitLoading={submitLoading}
      subtitle={t('auth.setPassword.subtitle')}
      title={t('auth.setPassword.title')}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onSubmit={onSubmit}
    />
  );
};
