import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';

import { Field } from '../../../forms/personalDataForm';

export const PersonalDataSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      datax="personalDataSection"
      title={t('client.client.personalData.title')}
    >
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.personalData.email.label')}
          name="personalData.email"
          placeholder={t('client.client.personalData.email.placeholder')}
        />
        <Field.Text
          fullWidth
          label={t('client.client.personalData.firstName.label')}
          name="personalData.firstName"
          placeholder={t('client.client.personalData.firstName.placeholder')}
        />
      </Row>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.personalData.lastName.label')}
          name="personalData.lastName"
          placeholder={t('client.client.personalData.lastName.placeholder')}
        />
        <Field.Text
          fullWidth
          label={t('client.client.personalData.middleName.label')}
          name="personalData.middleName"
          placeholder={t('client.client.personalData.middleName.placeholder')}
        />
      </Row>
      <Field.DatePicker
        label={t('client.client.personalData.dateOfBirth.label')}
        name="personalData.dateOfBirth"
        placeholder={t('client.client.personalData.dateOfBirth.placeholder')}
      />
    </Section>
  );
};
