import { ClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { RiskProfileFormType } from '../../forms/riskProfileForm';

export const parseRiskProfileValue = (client: ClientEntity, t: TFunc) => {
  const values: RiskProfileFormType['riskProfile'] | null = client.riskProfile
    ? {
        label: t(`client.client.riskProfile.types.${client.riskProfile}`),
        value: client.riskProfile,
      }
    : null;

  return values;
};
