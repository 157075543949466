import { CurrencyType } from 'api/types/entity/priceEntity';
import { formatPrice, parsePrice } from 'helpers';
import { Autocomplete, InputProps, NumberInput, Stack } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

import { CurrencyContainer } from './PriceField.styles';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'name' | 'onChange' | 'onChangeText' | 'value'> {}

export interface PriceFieldValue {
  price: number;
  currency: CurrencyType;
}

/**
 * Price field.
 */
export const PriceField = ({ name, allowSubmit, ...props }: TextFieldProps) => {
  const priceName = `${name}.price`;
  const currencyName = `${name}.currency`;

  const { field, helpers, inputProps } = useField<number | undefined>(
    priceName
  );

  const {
    field: fieldCurrency,
    helpers: helpersCurrency,
    inputProps: inputPropsCurrency,
  } = useField<CurrencyType | undefined>(currencyName);

  const isError = inputProps.error || inputPropsCurrency.error;
  const helperText = inputProps.helperText || inputPropsCurrency.helperText;

  return (
    <Stack direction="row" spacing={24}>
      <NumberInput
        {...props}
        fullWidth
        error={isError}
        format={formatPrice}
        helperText={helperText}
        name={name}
        parse={parsePrice}
        readOnly={inputProps.readOnly}
        showCopyButton={inputProps.showCopyButton}
        value={field.value}
        onBlur={field.onBlur(name)}
        onChange={() => {
          helpersCurrency.setError('');
          helpers.setError('');
        }}
        onChangeText={(v) => {
          helpers.setValue(v);
        }}
      />
      <CurrencyContainer readOnly={inputProps.readOnly}>
        <Autocomplete
          hideClearIcon
          className="priceField-Currency"
          error={isError}
          fullWidth={false}
          label=" "
          name={currencyName}
          optionLabelKey="label"
          options={Object.values(CurrencyType).map((v) => ({
            value: v,
            label: v,
          }))}
          optionValueKey="value"
          readOnly={inputProps.readOnly}
          showCopyButton={inputProps.showCopyButton}
          value={
            fieldCurrency.value
              ? { value: fieldCurrency.value, label: fieldCurrency.value }
              : null
          }
          variant="select"
          onChange={(e, v) => {
            helpers.setError('');
            helpersCurrency.setError('');
            helpersCurrency.setValue(v?.value);
          }}
        />
      </CurrencyContainer>
    </Stack>
  );
};
