import { useCallback, useEffect, useState } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useUpdateExpiredPassword as useUpdateExpiredPasswordApi } from 'api/requests';
import { getValidationErrors } from 'helpers';
import { useNotify } from 'libs/notify';

import { FormSubmit, FormErrors } from '../forms/passwordForm';

export const useUpdateExpiredPassword = () => {
  const notify = useNotify();

  const { setPassword, error, loading } = useUpdateExpiredPasswordApi();

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const onSubmit: FormSubmit = useCallback(
    async (values) => {
      const loginData = await setPassword(values.password);
      if (loginData) {
        tokenStorage.login({ ...loginData, otpRequired: false });
        window.location.replace('/');
      }
    },
    [setPassword]
  );

  // handle error
  useEffect(() => {
    if (error) {
      const validationErrors = getValidationErrors<'password'>(error);
      if (validationErrors?.password) {
        setFormErrors({ password: validationErrors.password[0].description });
      } else {
        notify.error(error);
        setFormErrors({ password: undefined });
      }
    } else {
      setFormErrors({ password: undefined });
    }
  }, [error, notify, setFormErrors]);

  return {
    submitLoading: loading,
    formErrors,
    onSubmit,
  };
};
