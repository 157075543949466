import { PropsWithChildren } from 'react';

import { useTranslation } from 'libs/i18n';
import { Button, Dialog, Stack, Typography } from 'libs/ui';

import { ContainerStyled } from './ConfirmationDialog.styles';
import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog = ({
  title,
  subtitle,
  confirmButtonLabel,
  backButtonLabel,
  onConfirm,
  onBack,
  children,
  className,
  loading,
  headerCentered,
  buttonConfirm,
  hideButtonConfirm,
  hideBackButton,
  confirmButtonColorVariant,
  ...dialogProps
}: ConfirmationDialogProps & PropsWithChildren) => {
  const { t } = useTranslation();

  const ButtonConfirm = buttonConfirm ?? Button;

  return (
    <Dialog className={className} maxWidth="lg" {...dialogProps}>
      <ContainerStyled>
        <Stack spacing={36}>
          <Stack
            alignItems={headerCentered ? 'center' : undefined}
            as="header"
            spacing={20}
          >
            <Typography align="center" as="h3" variant="title">
              {title}
            </Typography>
            {!!subtitle && (
              <Typography
                align={headerCentered ? 'center' : undefined}
                className="confirmDialog-subtitle"
              >
                {subtitle}
              </Typography>
            )}
          </Stack>

          {children}

          <Stack spacing={12}>
            {!hideButtonConfirm && (
              <ButtonConfirm
                colorVariant={confirmButtonColorVariant}
                label={confirmButtonLabel ?? t('common.confirm')}
                loading={loading}
                onClick={onConfirm}
              />
            )}
            {!hideBackButton && (
              <Stack alignItems="center">
                <Button
                  fullWidth={hideButtonConfirm}
                  label={backButtonLabel ?? t('common.back')}
                  mode={hideButtonConfirm ? 'outlined' : 'text'}
                  onClick={onBack}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </ContainerStyled>
    </Dialog>
  );
};
