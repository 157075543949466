import { PropsWithChildren } from 'react';

import { StatusesSection } from 'modules/client/common/components/sections';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, PropsWithChildren {}

export const GeneralSection = ({
  children,

  ...formProps
}: Props) => (
  <StatusesSection datax="generalSection">
    <FormContent {...formProps} />
  </StatusesSection>
);
