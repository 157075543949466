import { EducationLevelType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/client/forms/knowledgeAndExperienceForm';

export const EducationFields = () => {
  const { t } = useTranslation();

  return (
    <Field.Autocomplete
      label={t(
        `client.client.knowledgeAndExperience.education.educationField.label`
      )}
      name="knowledgeAndExperience.education.educationLevel"
      optionLabelKey="label"
      options={Object.values(EducationLevelType).map((v) => ({
        label: t(`client.client.knowledgeAndExperience.education.types.${v}`),
        value: v,
      }))}
      optionValueKey="value"
      placeholder={t(
        `client.client.knowledgeAndExperience.education.educationField.placeholder`
      )}
      variant="select"
    />
  );
};
