// eslint-disable-next-line no-restricted-imports
import { LegalClientOpenAccountOrderDto_Input } from 'api/generated/graphql';
import { LegalClientOpenAccountOrderEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';

export const parseLegalClientOpenAccountOrderParam = (
  openAccountOrder: LegalClientOpenAccountOrderEntity
): LegalClientOpenAccountOrderDto_Input => ({
  date: openAccountOrder.date ? formatToISODate(openAccountOrder.date) : null,
  number: openAccountOrder.number || null,
});
