import { CountryEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import { useFormContext } from 'modules/client/common/forms/taxResidencyForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  enabledDocuments?: boolean;
}

export const FormContent = ({
  countriesLoading,
  countries,
  enabledDocuments,
}: FormContentProps) => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.taxResidences.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              index={index}
              value={value}
            />
          </Stack>
          {enabledDocuments && !value.taxNumberIsMissing && (
            <DocumentsTableFeature name={`taxResidences.${index}.documents`} />
          )}

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
