import { formatToDateTime } from 'helpers';
import { useConvertDateToUserTimezone } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/activityForm';

interface Props {
  isBaseForm?: boolean;
}

export const ActivitySection = ({ isBaseForm }: Props) => {
  const { t } = useTranslation();

  const { convertDateToUserTimezone } = useConvertDateToUserTimezone();

  return (
    <Section datax="activitySection" title={t('client.client.activity.title')}>
      <Row>
        <Field.Text
          fullWidth
          readOnly
          label={t('client.client.activity.id.label')}
          name="activity.id"
          placeholder={t('client.client.activity.id.placeholder')}
        />

        <Field.Text
          fullWidth
          readOnly
          label={t('client.client.activity.createdAt.label')}
          name="activity.createdAt"
          placeholder={t('client.client.activity.createdAt.placeholder')}
        />
      </Row>
      {!isBaseForm && (
        <>
          <Row>
            <Field.Text
              fullWidth
              readOnly
              formatValue={(v) =>
                v ? formatToDateTime(convertDateToUserTimezone(v as Date)) : v
              }
              label={t('client.client.activity.lastSessionDate.label')}
              name="activity.lastSessionDate"
              placeholder={t(
                'client.client.activity.lastSessionDate.placeholder'
              )}
            />

            <Field.Text
              fullWidth
              readOnly
              label={t('client.client.activity.ip.label')}
              name="activity.ip"
              placeholder={t('client.client.activity.ip.placeholder')}
            />
          </Row>

          <Field.Text
            fullWidth
            readOnly
            label={t('client.client.activity.userAgent.label')}
            name="activity.userAgent"
            placeholder={t('client.client.activity.userAgent.placeholder')}
          />
        </>
      )}
    </Section>
  );
};
