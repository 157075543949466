export enum AccessTokenType {
  ExpiredPassword = 'ExpiredPassword',
  OtpRequired = 'OtpRequired',
  Regular = 'Regular',
}

export interface LoginData {
  accessToken: string;
  refreshToken: string;
  otpRequired: boolean;
  accessTokenType?: AccessTokenType;
}
