import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps } from 'libs/ui';

export interface DialogConfirmResetPasswordProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  loading?: boolean;
  fullName: string;
  onConfirm: () => void;
}

export const DialogConfirmResetPassword = ({
  loading,
  isOpen,
  fullName,
  onConfirm,
  onClose,
}: DialogConfirmResetPasswordProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      fullWidth
      headerCentered
      confirmButtonColorVariant="red"
      confirmButtonLabel={t(
        'components.dialogConfirmResetPassword.confirmLabel'
      )}
      isOpen={isOpen}
      loading={loading}
      maxWidth="md"
      subtitle={t('components.dialogConfirmResetPassword.subtitle', {
        fullName,
      })}
      title={t('components.dialogConfirmResetPassword.title')}
      onBack={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
