import { HiddenBoxStyled } from 'components';
import { useField } from 'libs/form/fields/useField';
import { useTranslation } from 'libs/i18n';
import { Box, Stack, Typography } from 'libs/ui';
import {
  Field,
  FinancialPositionFormType,
  useFormContext,
} from 'modules/client/client/forms/financialPositionForm';
import { Row } from 'modules/client/common/components';

type NestedKeys<T> = {
  [K in keyof T]: T[K] extends object
    ? `${K & string}.${keyof T[K] & string}`
    : never;
}[keyof T];

type FinancialPositionFormPaths = NestedKeys<
  FinancialPositionFormType['financialPosition']
>;

interface Props {
  sectionName: FinancialPositionFormPaths;
}

export const SectionBox = ({ sectionName }: Props) => {
  const parentPath = sectionName.split(
    '.'
  )[0] as keyof FinancialPositionFormType['financialPosition'];

  const childPath = sectionName.split('.')[1];

  const { t } = useTranslation();

  const { field } = useField(`financialPosition.${sectionName}.enabled`);

  const { readOnly } = useFormContext();
  const editable = !readOnly;

  const options = t(
    `client.client.financialPosition.${parentPath}.amountField.types`,
    { returnObjects: true }
  );

  const enabled = !!field.value;

  if (!editable && !enabled) {
    return null;
  }

  return (
    <Stack spacing={20}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">
          {t(`client.client.financialPosition.${sectionName}.title`)}
        </Typography>
        {editable && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={16}
          >
            <Typography variant="bodyMedium">{t('common.choose')}</Typography>

            <Field.Switch name={`financialPosition.${sectionName}.enabled`} />
          </Stack>
        )}
      </Stack>
      <HiddenBoxStyled hidden={!enabled}>
        <Row>
          {childPath === 'other' && (
            <Box flex={1}>
              <Field.Text
                fullWidth
                label={t(
                  `client.client.financialPosition.${parentPath}.other.otherField.label`
                )}
                name={`financialPosition.${parentPath}.other.otherText`}
                placeholder={t(
                  `client.client.financialPosition.${parentPath}.other.otherField.placeholder`
                )}
              />
            </Box>
          )}
          <Box flex={1}>
            <Field.Autocomplete
              label={t(
                `client.client.financialPosition.${parentPath}.amountField.label`
              )}
              name={`financialPosition.${sectionName}.value`}
              optionLabelKey="label"
              options={Object.keys(options).map((v) => ({
                label: t(
                  // @ts-ignore
                  `client.client.financialPosition.${parentPath}.amountField.types.${v}`
                ),
                value: v,
              }))}
              optionValueKey="value"
              placeholder={t(
                `client.client.financialPosition.${parentPath}.amountField.placeholder`
              )}
              variant="select"
            />
          </Box>
        </Row>
      </HiddenBoxStyled>
    </Stack>
  );
};
