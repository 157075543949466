import { PasswordValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  password,
  FormSubmit,
  string,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface ChangePasswordFormType {
  oldPassword: string;
  newPassword: string;
  otpCode?: string;
}

export const CODE_LENGTH = 6;

export const getSchema = (
  t: TFunc,
  passwordValidation: PasswordValidation
): ObjectSchema<ChangePasswordFormType> =>
  object({
    oldPassword: password(passwordValidation, t),
    newPassword: password(passwordValidation, t),
    otpCode: string().min(CODE_LENGTH),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ChangePasswordFormType>();

export type FormErrors = FormErrorsBase<ChangePasswordFormType>;

export type ChangePasswordFormSubmit = FormSubmit<ChangePasswordFormType>;
