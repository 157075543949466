import { FinancialPositionParam } from 'api/types/params/client/financialPositionParam';

import { FinancialPositionFormType } from '../../forms/financialPositionForm';

export const getFinancialPositionParam = (
  values: FinancialPositionFormType
): FinancialPositionParam => {
  const data: FinancialPositionParam = {
    regularMonthlyIncome: {
      businessIncome: values.financialPosition.regularMonthlyIncome
        .businessIncome?.enabled
        ? values.financialPosition.regularMonthlyIncome.businessIncome?.value
            ?.value
        : undefined,
      investmentIncome: values.financialPosition.regularMonthlyIncome
        .investmentIncome?.enabled
        ? values.financialPosition.regularMonthlyIncome.investmentIncome?.value
            ?.value
        : undefined,
      work: values.financialPosition.regularMonthlyIncome.work?.enabled
        ? values.financialPosition.regularMonthlyIncome.work?.value?.value
        : undefined,
      other: values.financialPosition.regularMonthlyIncome.other?.enabled
        ? {
            otherText:
              values.financialPosition.regularMonthlyIncome.other?.otherText,
            value:
              values.financialPosition.regularMonthlyIncome.other?.value?.value,
          }
        : undefined,
    },
    assetValue: {
      liquidAssets: values.financialPosition.assetValue.liquidAssets?.enabled
        ? values.financialPosition.assetValue.liquidAssets?.value?.value
        : undefined,
      securities: values.financialPosition.assetValue.securities?.enabled
        ? values.financialPosition.assetValue.securities?.value?.value
        : undefined,
      realEstate: values.financialPosition.assetValue.realEstate?.enabled
        ? values.financialPosition.assetValue.realEstate?.value?.value
        : undefined,
      other: values.financialPosition.assetValue.other?.enabled
        ? {
            otherText: values.financialPosition.assetValue.other?.otherText,
            value: values.financialPosition.assetValue.other?.value?.value,
          }
        : undefined,
    },
    financialLiabilities: {
      bankLoans: values.financialPosition.financialLiabilities.bankLoans
        ?.enabled
        ? values.financialPosition.financialLiabilities.bankLoans?.value?.value
        : undefined,
      mortgageLoan: values.financialPosition.financialLiabilities.mortgageLoan
        ?.enabled
        ? values.financialPosition.financialLiabilities.mortgageLoan?.value
            ?.value
        : undefined,
      other: values.financialPosition.financialLiabilities.other?.enabled
        ? {
            otherText:
              values.financialPosition.financialLiabilities.other?.otherText,
            value:
              values.financialPosition.financialLiabilities.other?.value?.value,
          }
        : undefined,
    },
    expenses: {
      livingExpenses: values.financialPosition.expenses.livingExpenses?.enabled
        ? values.financialPosition.expenses.livingExpenses?.value?.value
        : undefined,
      loanRepayment: values.financialPosition.expenses.loanRepayment?.enabled
        ? values.financialPosition.expenses.loanRepayment?.value?.value
        : undefined,
      rent: values.financialPosition.expenses.rent?.enabled
        ? values.financialPosition.expenses.rent?.value?.value
        : undefined,
      other: values.financialPosition.expenses.other?.enabled
        ? {
            otherText: values.financialPosition.expenses.other?.otherText,
            value: values.financialPosition.expenses.other?.value?.value,
          }
        : undefined,
    },
  };
  return data;
};
