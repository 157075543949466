import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { Title } from '../components';

import { PersonalAccountSettings } from './PersonalAccountSettings';

export const OnboardingSettings = () => {
  const { t } = useTranslation();
  return (
    <Stack as="section" spacing={32}>
      <Title title={t('systemSettings.onboarding.title')} />

      <PersonalAccountSettings />
    </Stack>
  );
};
