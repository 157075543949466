import { SystemSettingsEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { SystemSettingsFormType } from '../forms/systemSettingsForm';

export const getInitialValue = (
  t: TFunc,
  settings: SystemSettingsEntity
): SystemSettingsFormType => ({
  isAutomaticPersonalAccountGenerationEnabled: yesOrNotOption(
    t,
    settings.isAutomaticPersonalAccountGenerationEnabled
  ),
  sessionLengthInDays: settings.sessionLengthInDays,

  passwordExpiresInDays: settings.passwordExpiresInDays || undefined,

  isPasswordLimited: !!settings.passwordExpiresInDays,
});
