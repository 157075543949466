import { useUpdateClient as useUpdateClientApi } from 'api/requests/client';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { ClientFormType } from '../forms/clientForm';
import { getClientSubmitData } from '../helpers';

export const useUpdateClient = () => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateClient, loading: submitLoading, error } = useUpdateClientApi();

  const handleUpdateClient = async (
    client: ClientEntity,
    values: ClientFormType,
    isEditablePersonalAccountNumber: boolean
  ) => {
    if (client.versionNumber !== undefined) {
      if (!isEditablePersonalAccountNumber) {
        values.accountCodes.personalAccountNumber = undefined;
        values.accountCodes.personalAccountNumberOpeningDate = undefined;
      }
      const params = getClientSubmitData(
        client.versionNumber,
        values,
        client.documentGroups
      );
      const updatedClient = await updateClient(client.id, params);

      if (updatedClient) {
        notifySuccessSave(updatedClient.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    submitLoading,
    updateClient: handleUpdateClient,
    error,
  };
};
