import { useMemo } from 'react';

import { useCreateLegalClientHead } from 'api/requests';
import { useCountries } from 'api/requests/country';
import { LegalClientEntity, LegalClientHeadEntity } from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import {
  FormErrors,
  getSchema,
  LegalClientHeadFormType,
} from '../forms/legalClientHeadForm';
import {
  getLegalClientHeadInitialValue,
  getLegalClientHeadSubmitData,
} from '../helpers';

export const useLegalClientAddHead = (
  legalClient: LegalClientEntity,
  onSuccess: (legalClientHead: LegalClientHeadEntity) => void
) => {
  const { countries, loading: countriesLoading } = useCountries();
  const { t } = useTranslation();
  const notify = useNotify();

  const {
    loading: submitLoading,
    createLegalClientHead,
    error: submitError,
  } = useCreateLegalClientHead();

  const initialValues = getLegalClientHeadInitialValue(t);

  const validationSchema = getSchema(t);

  const onSubmit: FormSubmit<LegalClientHeadFormType> = async (values) => {
    const params = getLegalClientHeadSubmitData(
      legalClient.id,
      legalClient.versionNumber!,
      values
    );

    const head = await createLegalClientHead(params);
    if (head) {
      onSuccess(head);
      notify.info(t('client.legal.legalClientHead.addDialog.successAdd'));
    }
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (submitError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { personalData: { email: submitError?.message } };
    }

    return undefined;
  }, [submitError?.code, submitError?.message]);

  useServerErrorNotify(submitError);

  return {
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    formError,
    onSubmit,
  };
};
