export enum IncomeRangeType {
  /** Чистая сумма менее 1 000 USD */
  LESS_THAN_1000 = 'LESS_THAN_1000',
  /** Чистая сумма от 1 000 USD до 5 000 USD */
  BETWEEN_1000_AND_5000 = 'BETWEEN_1000_AND_5000',
  /** Чистая сумма более 5 000 USD */
  MORE_THAN_5000 = 'MORE_THAN_5000',
}

export enum AssetRangeType {
  /** Менее 50 000 USD */
  LESS_THAN_50000 = 'LESS_THAN_50000',
  /** От 50 000 USD до 100 000 USD */
  BETWEEN_50000_AND_100000 = 'BETWEEN_50000_AND_100000',
  /** Более 100 000 USD */
  MORE_THAN_100000 = 'MORE_THAN_100000',
}

export enum ExpenseRangeType {
  /** Чистая сумма менее 1 000 USD */
  LESS_THAN_1000 = 'LESS_THAN_1000',
  /** Чистая сумма от 1 000 USD до 5 000 USD */
  BETWEEN_1000_AND_5000 = 'BETWEEN_1000_AND_5000',
  /** Чистая сумма более 5 000 USD */
  MORE_THAN_5000 = 'MORE_THAN_5000',
}

export enum LiabilityRangeType {
  /** Менее 50 000 USD */
  LESS_THAN_50000 = 'LESS_THAN_50000',
  /** От 50 000 USD до 100 000 USD */
  BETWEEN_50000_AND_100000 = 'BETWEEN_50000_AND_100000',
  /** Более 100 000 USD */
  MORE_THAN_100000 = 'MORE_THAN_100000',
}

export interface FinancialPositionEntity {
  regularMonthlyIncome: {
    work?: IncomeRangeType;
    businessIncome?: IncomeRangeType;
    investmentIncome?: IncomeRangeType;
    other?: {
      otherText?: string;
      value?: IncomeRangeType;
    };
  };
  assetValue: {
    liquidAssets?: AssetRangeType;
    securities?: AssetRangeType;
    realEstate?: AssetRangeType;
    other?: {
      otherText?: string;
      value?: AssetRangeType;
    };
  };
  expenses: {
    livingExpenses?: ExpenseRangeType;
    rent?: ExpenseRangeType;
    loanRepayment?: ExpenseRangeType;
    other?: {
      otherText?: string;
      value?: ExpenseRangeType;
    };
  };

  financialLiabilities: {
    mortgageLoan?: LiabilityRangeType;
    bankLoans?: LiabilityRangeType;
    other?: {
      otherText?: string;
      value?: LiabilityRangeType;
    };
  };
}
