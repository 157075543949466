import { ClientEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { W8BenFormFormType } from '../../forms/w8BenFormForm';

export const parseW8BENFormValue = (client: ClientEntity, t: TFunc) => {
  const values: W8BenFormFormType['W8BenForm'] = {
    isUSSecuritiesInvestmentPlanned: yesOrNotOption(
      t,
      client.W8BENForm?.isUSSecuritiesInvestmentPlanned
    ),
    files: client.W8BENForm?.files,
  };

  return values;
};
