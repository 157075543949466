import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { ClientSaveSourcesOfIncomeParam } from 'api/types/params';

import { SourcesOfIncomeFormType } from '../../forms/sourcesOfIncomeForm';

export const getSourcesOfIncomeParam = (
  values: SourcesOfIncomeFormType
): ClientSaveSourcesOfIncomeParam | undefined => {
  const incomeSources = values.sourcesOfIncome.incomeSources!.map(
    (v) => v.value
  );

  const data: ClientSaveSourcesOfIncomeParam = {
    occupation: values.sourcesOfIncome.occupation!.value,
    incomeSources,
    otherIncomeSource: incomeSources.includes(SourcesOfIncomeSourceType.Other)
      ? values.sourcesOfIncome.otherIncomeSource
      : undefined,
    otherOccupation:
      values.sourcesOfIncome.occupation!.value ===
      SourcesOfIncomeOccupationType.Other
        ? values.sourcesOfIncome.otherOccupation
        : undefined,
    workplaceName:
      values.sourcesOfIncome.occupation!.value ===
        SourcesOfIncomeOccupationType.Employee ||
      values.sourcesOfIncome.occupation!.value ===
        SourcesOfIncomeOccupationType.BusinessOwner
        ? values.sourcesOfIncome.workplaceName
        : undefined,
    position:
      values.sourcesOfIncome.occupation!.value ===
        SourcesOfIncomeOccupationType.Employee ||
      values.sourcesOfIncome.occupation!.value ===
        SourcesOfIncomeOccupationType.BusinessOwner
        ? values.sourcesOfIncome.position
        : undefined,
  };
  return data;
};
