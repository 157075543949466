import {
  AssetRangeType,
  ExpenseRangeType,
  IncomeRangeType,
  LiabilityRangeType,
} from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
  boolean,
} from 'libs/form';
import { SelectType } from 'types/selectType';

export interface FinancialPositionFormType {
  financialPosition: {
    regularMonthlyIncome: {
      work?: {
        value?: SelectType<IncomeRangeType>;
        enabled?: boolean;
      };

      businessIncome?: {
        value?: SelectType<IncomeRangeType>;
        enabled?: boolean;
      };
      investmentIncome?: {
        value?: SelectType<IncomeRangeType>;
        enabled?: boolean;
      };
      other?: {
        otherText?: string;
        value?: SelectType<IncomeRangeType>;
        enabled?: boolean;
      };
    };
    assetValue: {
      liquidAssets?: {
        value?: SelectType<AssetRangeType>;
        enabled?: boolean;
      };
      securities?: {
        value?: SelectType<AssetRangeType>;
        enabled?: boolean;
      };
      realEstate?: {
        value?: SelectType<AssetRangeType>;
        enabled?: boolean;
      };
      other?: {
        otherText?: string;
        value?: SelectType<AssetRangeType>;
        enabled?: boolean;
      };
    };
    expenses: {
      livingExpenses?: {
        value?: SelectType<ExpenseRangeType>;
        enabled?: boolean;
      };
      rent?: {
        value?: SelectType<ExpenseRangeType>;
        enabled?: boolean;
      };
      loanRepayment?: {
        value?: SelectType<ExpenseRangeType>;
        enabled?: boolean;
      };
      other?: {
        otherText?: string;
        value?: SelectType<ExpenseRangeType>;
        enabled?: boolean;
      };
    };

    financialLiabilities: {
      mortgageLoan?: {
        value?: SelectType<LiabilityRangeType>;
        enabled?: boolean;
      };
      bankLoans?: {
        value?: SelectType<LiabilityRangeType>;
        enabled?: boolean;
      };
      other?: {
        otherText?: string;
        value?: SelectType<LiabilityRangeType>;
        enabled?: boolean;
      };
    };
  };
}

export const getFinancialPositionSchema =
  (): ObjectSchema<FinancialPositionFormType> =>
    object({
      financialPosition: object({
        regularMonthlyIncome: object({
          work: object({
            value: object({
              value: mixed<IncomeRangeType>()
                .oneOf(Object.values(IncomeRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          businessIncome: object({
            value: object({
              value: mixed<IncomeRangeType>()
                .oneOf(Object.values(IncomeRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          investmentIncome: object({
            value: object({
              value: mixed<IncomeRangeType>()
                .oneOf(Object.values(IncomeRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          other: object({
            otherText: string()
              .max(200)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            value: object({
              value: mixed<IncomeRangeType>()
                .oneOf(Object.values(IncomeRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
        }),
        assetValue: object({
          liquidAssets: object({
            value: object({
              value: mixed<AssetRangeType>()
                .oneOf(Object.values(AssetRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          securities: object({
            value: object({
              value: mixed<AssetRangeType>()
                .oneOf(Object.values(AssetRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          realEstate: object({
            value: object({
              value: mixed<AssetRangeType>()
                .oneOf(Object.values(AssetRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          other: object({
            otherText: string()
              .max(200)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            value: object({
              value: mixed<AssetRangeType>()
                .oneOf(Object.values(AssetRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
        }),
        expenses: object({
          livingExpenses: object({
            value: object({
              value: mixed<ExpenseRangeType>()
                .oneOf(Object.values(ExpenseRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          rent: object({
            value: object({
              value: mixed<ExpenseRangeType>()
                .oneOf(Object.values(ExpenseRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          loanRepayment: object({
            value: object({
              value: mixed<ExpenseRangeType>()
                .oneOf(Object.values(ExpenseRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          other: object({
            otherText: string()
              .max(200)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            value: object({
              value: mixed<ExpenseRangeType>()
                .oneOf(Object.values(ExpenseRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
        }),
        financialLiabilities: object({
          mortgageLoan: object({
            value: object({
              value: mixed<LiabilityRangeType>()
                .oneOf(Object.values(LiabilityRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          bankLoans: object({
            value: object({
              value: mixed<LiabilityRangeType>()
                .oneOf(Object.values(LiabilityRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
          other: object({
            otherText: string()
              .max(200)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            value: object({
              value: mixed<LiabilityRangeType>()
                .oneOf(Object.values(LiabilityRangeType))
                .required(),
              label: string().required(),
            })
              .default(null)
              .when('enabled', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.nullable(),
              }),
            enabled: boolean().required(),
          }),
        }),
      }),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<FinancialPositionFormType>();

export type FormErrors = FormErrorsBase<FinancialPositionFormType>;
