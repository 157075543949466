// eslint-disable-next-line no-restricted-imports
import {
  FinancialInstrumentFragment,
  KnowledgeAndExperienceFragment,
} from 'api/generated/graphql';
import {
  EducationLevelType,
  InvestmentDurationType,
  KnowledgeAndExperienceEntity,
  TradeAmountType,
  TradeFrequencyType,
} from 'api/types/entity';

const parseFinancialInstruments = (data?: FinancialInstrumentFragment | null) =>
  data
    ? {
        investmentAmount: data.investmentAmount ?? undefined,
        transactionCount: data.transactionCount ?? undefined,
        yearOfFirstInvestment: data.firstInvestmentYear ?? undefined,
      }
    : undefined;

export const parseKnowledgeAndExperienceEntity = (
  data: KnowledgeAndExperienceFragment
): KnowledgeAndExperienceEntity => ({
  education: {
    educationLevel: data.education.educationLevel
      ? EducationLevelType[data.education.educationLevel]
      : undefined,
  },
  financialInstruments: {
    bonds: parseFinancialInstruments(data.financialInstruments.bonds),
    derivatives: parseFinancialInstruments(
      data.financialInstruments.derivatives
    ),
    mutualFunds: parseFinancialInstruments(
      data.financialInstruments.mutualFunds
    ),
    stocks: parseFinancialInstruments(data.financialInstruments.stocks),
    structuredProducts: parseFinancialInstruments(
      data.financialInstruments.structuredProducts
    ),
  },
  futureOperations: {
    investmentDuration: data.futureOperations.investmentDuration
      ? InvestmentDurationType[data.futureOperations.investmentDuration]
      : undefined,
    tradeAmount: data.futureOperations.tradeAmount
      ? TradeAmountType[data.futureOperations.tradeAmount]
      : undefined,
    tradeFrequency: data.futureOperations.tradeFrequency
      ? TradeFrequencyType[data.futureOperations.tradeFrequency]
      : undefined,
  },
});
