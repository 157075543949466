import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useValidatePasswordChangeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

interface Args {
  oldPassword: string;
  newPassword: string;
}

export const useValidatePasswordChange = () => {
  const [handle, { data, loading, error: apolloError }] =
    useValidatePasswordChangeMutation();

  const error = useError(apolloError);

  return {
    data,
    loading,
    error,
    validatePasswordChange: useCallback(
      async (args: Args) => {
        const res = await handle({ variables: args });
        return res.data?.validatePasswordChange;
      },
      [handle]
    ),
  };
};
