import { FinancialPositionEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { FinancialPositionFormType } from '../../forms/financialPositionForm';

type NestedKeys<T> = {
  [K in keyof T]: T[K] extends object
    ? `${K & string}.${keyof T[K] & string}`
    : never;
}[keyof T];

type FinancialPositionFormPaths = NestedKeys<FinancialPositionEntity>;

const parse = <T extends FinancialPositionFormPaths>(
  financialPosition: FinancialPositionEntity | undefined,
  t: TFunc,
  sectionName: T
) => {
  const parentPath = sectionName.split('.')[0] as keyof FinancialPositionEntity;

  const childPath = sectionName.split(
    '.'
  )[1] as keyof FinancialPositionEntity[typeof parentPath];

  const value =
    childPath !== 'other'
      ? financialPosition?.[parentPath]?.[childPath]
      : financialPosition?.[parentPath]?.other?.value;

  return {
    enabled: !!value,
    value: value
      ? {
          label: t(
            // @ts-ignore
            `client.client.financialPosition.${parentPath}.amountField.types.${value}`
          ) as string,
          value: value as any,
        }
      : undefined,

    ...(childPath === 'other' && {
      otherText: financialPosition?.[parentPath].other?.otherText ?? '',
    }),
  };
};

export const parseFinancialPositionValue = (
  client: FinancialPositionEntity | undefined,
  t: TFunc
) => {
  const values: FinancialPositionFormType['financialPosition'] | undefined = {
    financialLiabilities: {
      mortgageLoan: parse(client, t, 'financialLiabilities.mortgageLoan'),
      bankLoans: parse(client, t, 'financialLiabilities.bankLoans'),
      other: parse(client, t, 'financialLiabilities.other'),
    },
    assetValue: {
      liquidAssets: parse(client, t, 'assetValue.liquidAssets'),
      securities: parse(client, t, 'assetValue.securities'),
      realEstate: parse(client, t, 'assetValue.realEstate'),
      other: parse(client, t, 'assetValue.other'),
    },
    expenses: {
      livingExpenses: parse(client, t, 'expenses.livingExpenses'),
      loanRepayment: parse(client, t, 'expenses.loanRepayment'),
      rent: parse(client, t, 'expenses.rent'),
      other: parse(client, t, 'expenses.other'),
    },
    regularMonthlyIncome: {
      businessIncome: parse(client, t, 'regularMonthlyIncome.businessIncome'),
      investmentIncome: parse(
        client,
        t,
        'regularMonthlyIncome.investmentIncome'
      ),
      work: parse(client, t, 'regularMonthlyIncome.work'),
      other: parse(client, t, 'regularMonthlyIncome.other'),
    },
  };

  return values;
};
