import { FC, useCallback, useEffect, useMemo } from 'react';

import { PermissionType, RoleType } from 'api/types/entity';
import {
  ActionMenu,
  ContentHeader,
  DialogConfirmResetPassword,
  DialogConfirmRevokeOtpVerification,
  SendCodeTimer,
} from 'components';
import { lodash, parseFullName } from 'helpers';
import { useFormPrompt } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { ROUTES } from 'libs/navigation';
import { PermissionWrapper, usePermissions } from 'libs/permissions';
import { Button, Chip, MenuItem, Stack } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { Submit, useFormContext } from '../../forms/adminForm';

export interface AdminFormHeaderProps {
  submitLoading?: boolean;
  isActive?: boolean;
  isDeactivated?: boolean;
  resendTime?: number;
  revokeOtpVerificationLoading?: boolean;
  resetPasswordLoading?: boolean;
  onResetPassword: () => Promise<any>;
  onResendInvite: () => void;
  onActivate: () => void;
  onDeactivate: () => void;
  onRevokeOtpVerification?: () => Promise<any>;
}

enum ActionType {
  activate = 'activate',
  deactivate = 'deactivate',
  resetPassword = 'resetPassword',
  edit = 'edit',
  revokeOtpVerification = 'revokeOtpVerification',
}

export const AdminFormHeader: FC<AdminFormHeaderProps> = ({
  submitLoading,
  isActive,
  resendTime,
  isDeactivated,
  revokeOtpVerificationLoading,
  resetPasswordLoading,
  onResetPassword,
  onResendInvite,
  onActivate,
  onDeactivate,
  onRevokeOtpVerification,
}) => {
  const {
    readOnly,
    setReadOnly,
    resetForm,
    dirty,
    submitDisabled,
    initialValues,
  } = useFormContext();

  const { t } = useTranslation();

  const { confirmIsChanged } = useFormPrompt();
  const { checkPermissions } = usePermissions();

  const {
    isOpen: isOpenRevokeOtp,
    onClose: onCloseRevokeOtp,
    open: openRevokeOtp,
  } = useDialog();

  const {
    isOpen: isOpenResetPasswordDialog,
    onClose: onCloseResetPasswordDialog,
    open: openResetPasswordDialog,
  } = useDialog();

  const editable = !readOnly;
  const isSuperadmin = initialValues?.role.value === RoleType.SuperAdmin;
  const fullName = parseFullName(initialValues);

  const menuAction = useMemo(
    () =>
      isDeactivated
        ? lodash.compact([
            checkPermissions([PermissionType.AdminActivate]) && {
              value: ActionType.activate,
              label: t('admin.admin.activate'),
              Icon: Icon.Edit,
            },
          ])
        : lodash.compact([
            checkPermissions([PermissionType.AdminEdit]) && {
              value: ActionType.edit,
              label: t('common.edit'),
              Icon: Icon.Edit,
            },
            isActive &&
              !isSuperadmin &&
              checkPermissions([PermissionType.AdminResetPassword]) && {
                value: ActionType.resetPassword,
                label: t('admin.admin.resetPassword'),
                Icon: Icon.Refresh,
              },

            !isSuperadmin &&
              checkPermissions([PermissionType.AdminRevokeOtpVerification]) &&
              onRevokeOtpVerification && {
                value: ActionType.revokeOtpVerification,
                label: t('common.revokeOtpVerification'),
                Icon: Icon.Lock,
              },

            !isSuperadmin &&
              checkPermissions([PermissionType.AdminDeactivate]) && {
                value: ActionType.deactivate,
                label: t('admin.admin.deactivate'),
                Icon: Icon.Bucket,
                color: Colors.red100,
              },
          ]),
    [
      isDeactivated,
      checkPermissions,
      t,
      isActive,
      isSuperadmin,
      onRevokeOtpVerification,
    ]
  );

  const disableEditable = () => {
    confirmIsChanged(() => setReadOnly(true));
  };

  const onActionRevokeOtpVerification = useCallback(() => {
    openRevokeOtp();
  }, [openRevokeOtp]);

  const onConfirmRevokeOtp = useCallback(async () => {
    await onRevokeOtpVerification?.().finally(() => onCloseRevokeOtp());
  }, [onCloseRevokeOtp, onRevokeOtpVerification]);

  const onConfirmResetPassword = useCallback(async () => {
    await onResetPassword?.().finally(() => onCloseResetPasswordDialog());
  }, [onCloseResetPasswordDialog, onResetPassword]);

  const onClickOption = (action: MenuItem<ActionType>) => {
    switch (action.value) {
      case ActionType.edit:
        setReadOnly(false);
        break;
      case ActionType.resetPassword:
        openResetPasswordDialog();
        break;
      case ActionType.activate:
        onActivate();
        break;
      case ActionType.deactivate:
        onDeactivate();
        break;
      case ActionType.revokeOtpVerification:
        onActionRevokeOtpVerification();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!editable) {
      resetForm();
    }
  }, [editable, resetForm]);

  return (
    <Stack spacing={32}>
      <ContentHeader
        backPath={ROUTES.admins.fullPath}
        canBack={!editable}
        header={editable ? t('admin.admin.editTitle') : fullName}
        rightContent={
          <Stack alignItems="center" direction="row" spacing={36}>
            {editable ? (
              <>
                <Button
                  label={t('common.cancel')}
                  mode="text"
                  onClick={disableEditable}
                />
                <PermissionWrapper permissions={[PermissionType.AdminEdit]}>
                  <Submit
                    disabled={submitDisabled || !dirty}
                    label={t('common.save')}
                    loading={submitLoading}
                  />
                </PermissionWrapper>
              </>
            ) : (
              <>
                {!isSuperadmin && !isActive && !isDeactivated && (
                  <PermissionWrapper
                    permissions={[PermissionType.AdminResendInvite]}
                  >
                    <SendCodeTimer
                      buttonLabel={t('admin.admin.resendInvite')}
                      buttonMode="text"
                      seconds={resendTime}
                      onClickSend={onResendInvite}
                    />
                  </PermissionWrapper>
                )}
                {menuAction.length && (
                  <ActionMenu
                    anchorOrigin={{ horizontal: 'right', vertical: 40 }}
                    label={t('common.actionLabel')}
                    options={menuAction}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClickOption={onClickOption}
                  />
                )}
              </>
            )}
          </Stack>
        }
      />

      {isDeactivated && (
        <Chip text={t('admin.admin.deactivated')} variant="red" />
      )}

      <DialogConfirmRevokeOtpVerification
        fullName={fullName}
        isOpen={isOpenRevokeOtp}
        loading={revokeOtpVerificationLoading}
        onClose={onCloseRevokeOtp}
        onConfirm={onConfirmRevokeOtp}
      />

      <DialogConfirmResetPassword
        fullName={fullName}
        isOpen={isOpenResetPasswordDialog}
        loading={resetPasswordLoading}
        onClose={onCloseResetPasswordDialog}
        onConfirm={onConfirmResetPassword}
      />
    </Stack>
  );
};
