import { useMemo } from 'react';

import { PasswordValidationRule } from 'api/types/data';
import { VerificationCodeFormContentDialog } from 'components';
import { PasswordValidation } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { FormBody } from 'modules/auth/common/components';

import { PasswordRules } from '../components';
import {
  Field,
  getSchema,
  FormSubmit,
  FormErrors,
  PasswordFormType,
  CODE_LENGTH,
} from '../forms/passwordForm';

import { ContainerStyled } from './styles';

interface Props {
  passwordValidationRule?: PasswordValidationRule;
  formErrors?: FormErrors;
  submitLoading?: boolean;
  isOpenVerificationDialog?: boolean;
  title: string;
  subtitle: string;
  onSubmit: FormSubmit;
  onCloseVerificationDialog?: () => void;
}

export const SetPasswordView = ({
  passwordValidationRule,
  formErrors,
  submitLoading,
  isOpenVerificationDialog = false,
  title,
  subtitle,
  onSubmit,
  onCloseVerificationDialog,
}: Props) => {
  const { t } = useTranslation();

  const passwordValidation = useMemo(
    () => PasswordValidation.parseByValidationRule(passwordValidationRule),
    [passwordValidationRule]
  );

  const schema = useMemo(
    () => getSchema(t, passwordValidation),
    [passwordValidation, t]
  );

  return (
    <ContainerStyled>
      <FormBody
        initialErrors={formErrors}
        submitLoading={submitLoading}
        subTitle={subtitle}
        title={title}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Stack spacing={32}>
          <Field.Password
            allowSubmit
            fullWidth
            autoComplete="new-password"
            label={t('components.passwordForm.label')}
            name="password"
            placeholder={t('components.passwordForm.placeholder')}
          />
          <PasswordRules
            passwordFieldName="password"
            passwordValidation={passwordValidation}
          />
        </Stack>

        <VerificationCodeFormContentDialog<PasswordFormType>
          codeFieldName="otpCode"
          isOpen={isOpenVerificationDialog}
          length={CODE_LENGTH}
          loading={submitLoading}
          onClose={onCloseVerificationDialog}
        />
      </FormBody>
    </ContainerStyled>
  );
};
