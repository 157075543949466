import { useRef, useLayoutEffect } from 'react';

import { TranslationPath, useTranslation } from 'libs/i18n';

interface Params {
  /**
   * Whether to return previous title when unmounting
   * @default true
   */
  previousOnUnmount: boolean;
}
/**
 * Set document title
 * @param {string} title - The document title
 * @param {boolean} previousOnUnmount - Whether to return previous title when unmounting
 */
export const usePageTitle = (title: string, params?: Params) => {
  const prevTitle = useRef(document.title);

  const { t } = useTranslation();

  const previousOnUnmount = params?.previousOnUnmount ?? true;

  useLayoutEffect(() => {
    document.title = t('postfixPageTitle', { title });
  }, [t, title]);

  useLayoutEffect(
    () => () => {
      if (previousOnUnmount) {
        document.title = prevTitle.current;
      }
    },
    [previousOnUnmount]
  );
};

export const usePageTitleTranslation = (
  title: TranslationPath,
  params?: Params
) => {
  const { t } = useTranslation();

  usePageTitle(t(title), params);
};
