import { OnboardingDataStatus } from 'api/types/data';
import { ClientOnboardingStatusType } from 'api/types/entity';
import { lodash } from 'helpers';

const onboardingStatusesForView: Record<ClientOnboardingStatusType, boolean> = {
  [ClientOnboardingStatusType.AwaitingPaymentConfirmation]: true,
  [ClientOnboardingStatusType.ValidationCheck]: true,
  [ClientOnboardingStatusType.ComplianceCheck]: true,
  [ClientOnboardingStatusType.AmlCftCheck]: true,
  [ClientOnboardingStatusType.BoardReview]: true,
  [ClientOnboardingStatusType.AccountOpened]: false,
  [ClientOnboardingStatusType.AccountOpening]: false,
  [ClientOnboardingStatusType.AccountOpeningDenied]: false,
  [ClientOnboardingStatusType.AwaitingAccountOpening]: false,
  [ClientOnboardingStatusType.AwaitingValidation]: false,
  [ClientOnboardingStatusType.FillingOut]: false,
  [ClientOnboardingStatusType.SigningDocuments]: false,
  [ClientOnboardingStatusType.AwaitingComplianceCheck]: false,
  [ClientOnboardingStatusType.PendingBoardReview]: false,
  [ClientOnboardingStatusType.AwaitingAmlCftCheck]: false,
  [ClientOnboardingStatusType.SigningQuestionnaire]: false,
};

/**
 * Filters the onboarding statuses to include only those that should be visible.
 * If there are duplicate statuses, only the most recent one with a filled result is kept.
 */
export const filterVisibleOnboardingStatuses = (
  onboardingDataStatus: OnboardingDataStatus[] | undefined
): OnboardingDataStatus[] =>
  onboardingDataStatus
    ? lodash
        .sortBy(onboardingDataStatus, (v) => v.createdAt)
        .reduce((acc: OnboardingDataStatus[], statusData) => {
          const sameStatusInAccIdx = acc.findIndex(
            ({ value }) => value === statusData.value
          );

          if (onboardingStatusesForView[statusData.value]) {
            const hasResult = !!statusData.result?.length;

            if (
              sameStatusInAccIdx !== -1 &&
              acc[sameStatusInAccIdx].createdAt < statusData.createdAt &&
              hasResult
            ) {
              acc.splice(sameStatusInAccIdx, 1);
            }

            if (hasResult) {
              acc.push(statusData);
            }
          }

          return acc;
        }, [])
    : [];
