import {
  ClientEntity,
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { SourcesOfIncomeFormType } from '../../forms/sourcesOfIncomeForm';

export const SourcesOfIncomeSourcePath: Record<
  SourcesOfIncomeSourceType,
  TranslationPath
> = {
  [SourcesOfIncomeSourceType.Salary]:
    'client.client.sourcesOfIncome.sourceType.Salary',
  [SourcesOfIncomeSourceType.Savings]:
    'client.client.sourcesOfIncome.sourceType.Savings',
  [SourcesOfIncomeSourceType.Inheritance]:
    'client.client.sourcesOfIncome.sourceType.Inheritance',
  [SourcesOfIncomeSourceType.BusinessActivity]:
    'client.client.sourcesOfIncome.sourceType.BusinessActivity',
  [SourcesOfIncomeSourceType.Other]:
    'client.client.sourcesOfIncome.sourceType.Other',
};

export const SourcesOfIncomeOccupationPath: Record<
  SourcesOfIncomeOccupationType,
  TranslationPath
> = {
  [SourcesOfIncomeOccupationType.Employee]:
    'client.client.sourcesOfIncome.occupationType.Employee',
  [SourcesOfIncomeOccupationType.IndividualEntrepreneur]:
    'client.client.sourcesOfIncome.occupationType.IndividualEntrepreneur',
  [SourcesOfIncomeOccupationType.BusinessOwner]:
    'client.client.sourcesOfIncome.occupationType.BusinessOwner',
  [SourcesOfIncomeOccupationType.Student]:
    'client.client.sourcesOfIncome.occupationType.Student',
  [SourcesOfIncomeOccupationType.Retiree]:
    'client.client.sourcesOfIncome.occupationType.Retiree',
  [SourcesOfIncomeOccupationType.Unemployed]:
    'client.client.sourcesOfIncome.occupationType.Unemployed',
  [SourcesOfIncomeOccupationType.Other]:
    'client.client.sourcesOfIncome.occupationType.Other',
};

export const parseSourcesOfIncomeValue = (client: ClientEntity, t: TFunc) => {
  const values: SourcesOfIncomeFormType['sourcesOfIncome'] | undefined = {
    ...client.sourcesOfIncome,
    incomeSources:
      client.sourcesOfIncome?.incomeSources?.map((v) => ({
        value: v,
        label: t(SourcesOfIncomeSourcePath[v]),
      })) ?? [],

    occupation: client.sourcesOfIncome?.occupation
      ? {
          value: client.sourcesOfIncome?.occupation,
          label: t(
            SourcesOfIncomeOccupationPath[client.sourcesOfIncome?.occupation]
          ),
        }
      : null,
  };

  return values;
};
