import { OccupationType } from 'api/types/entity';
import { isGlobalProject } from 'config';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';
import { residentialAddressWithFilesSchema } from 'modules/client/common/forms/residentialAddressForm';
import { applySchema } from 'modules/client/common/helpers';

import { AccountCodesFormType, accountCodesSchema } from './accountCodesForm';
import { ActivityFormType, activitySchema } from './activityForm';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from './additionalDocumentsForm';
import { BankAccountsFormType, bankAccountsSchema } from './bankAccountsForm';
import { BiographyFormType, biographySchema } from './biographyForm';
import {
  BusinessRelationshipFormType,
  getBusinessRelationshipSchema,
} from './businessRelationshipForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import { DebtsFormType, getDebtsSchema } from './debtsForm';
import { DocumentsFormType, documentsSchema } from './documentsForm';
import {
  FinancialPositionFormType,
  getFinancialPositionSchema,
} from './financialPositionForm';
import {
  FinancingSourcesFormType,
  getFinancingSourcesSchema,
} from './financingSourcesForm';
import { GeneralFormType, generalSchema } from './generalForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  KnowledgeAndExperienceFormType,
  getKnowledgeAndExperienceSchema,
} from './knowledgeAndExperienceForm';
import {
  OpenAccountOrderFormType,
  openAccountOrderSchema,
} from './openAccountOrderForm';
import {
  OrderSubmissionContactsFormType,
  getOrderSubmissionContactsSchema,
} from './orderSubmissionContactsForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import { ResidentialAddressFormType } from './residentialAddressForm';
import { RiskLevelFormType, riskLevelSchema } from './riskLevelForm';
import { RiskProfileFormType, riskProfileSchema } from './riskProfileForm';
import {
  ServiceContractFormType,
  serviceContractSchema,
} from './serviceContractForm';
import {
  getSourcesOfIncomeSchema,
  SourcesOfIncomeFormType,
} from './sourcesOfIncomeForm';
import { StatusesFormType, statusesSchema } from './statusesForm';
import {
  TaxResidencyFormType,
  taxResidencesSchema,
  taxResidencesWithDocumentsSchema,
} from './taxResidencyForm';
import { W8BenFormFormType, getW8BenFormSchema } from './w8BenFormForm';
import {
  WithdrawalAccountsFormType,
  withdrawalAccountsSchema,
} from './withdrawalAccountsForm';

export interface ClientFormType
  extends StatusesFormType,
    GeneralFormType,
    RiskLevelFormType,
    AccountCodesFormType,
    ServiceContractFormType,
    ActivityFormType,
    ContactsFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    PlaceOfBirthFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    BiographyFormType,
    BankAccountsFormType,
    FinancingSourcesFormType,
    PepConnectionFormType,
    DebtsFormType,
    WithdrawalAccountsFormType,
    DocumentsFormType,
    AdditionalDocumentsFormType,
    IdentityDocumentFormType,
    OpenAccountOrderFormType,
    OrderSubmissionContactsFormType,
    ReasonChangeFormType,
    BusinessRelationshipFormType,
    RiskProfileFormType,
    FinancialPositionFormType,
    SourcesOfIncomeFormType,
    KnowledgeAndExperienceFormType,
    W8BenFormFormType {}

export const getSchema = (
  t: TFunc,
  initialValues?: ClientFormType
): ObjectSchema<ClientFormType> => {
  const isGlobal = isGlobalProject();
  return object({
    reasonChange: string(),
  })
    .concat(statusesSchema)
    .concat(generalSchema)
    .concat(accountCodesSchema)
    .concat(serviceContractSchema)
    .concat(activitySchema)
    .concat(riskLevelSchema)
    .concat(applySchema(!isGlobal, openAccountOrderSchema))
    .concat(getContactsSchema(t))
    .concat(getPersonalDataSchema(t, isGlobal))
    .concat(citizenshipsSchema)
    .concat(isGlobal ? taxResidencesWithDocumentsSchema : taxResidencesSchema)
    .concat(applySchema(!isGlobal, registrationAddressSchema))
    .concat(residentialAddressWithFilesSchema)
    .concat(placeOfBirthSchema)
    .concat(identityDocumentSchema)

    .concat(getPepConnectionSchema(t, { notRequestInformation: isGlobal }))
    .concat(applySchema(!isGlobal, biographySchema))
    .concat(applySchema(!isGlobal, bankAccountsSchema))
    .concat(applySchema(!isGlobal, getFinancingSourcesSchema(t)))
    .concat(applySchema(isGlobal, getBusinessRelationshipSchema(t)))
    .concat(applySchema(isGlobal, riskProfileSchema))
    .concat(applySchema(isGlobal, getFinancialPositionSchema()))
    .concat(applySchema(isGlobal, getW8BenFormSchema()))
    .concat(applySchema(isGlobal, getSourcesOfIncomeSchema(t)))
    .concat(applySchema(isGlobal, getKnowledgeAndExperienceSchema()))

    .concat(
      applySchema(
        !isGlobal,
        getDebtsSchema(
          initialValues?.biography?.occupation?.value ===
            OccupationType.entrepreneur
        )
      )
    )
    .concat(documentsSchema)
    .concat(getAdditionalDocumentsSchema())
    .concat(getOrderSubmissionContactsSchema(t))
    .concat(withdrawalAccountsSchema)
    .concat(reasonChangeSchema);
};

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ClientFormType>();

export type FormErrors = FormErrorsBase<ClientFormType>;
