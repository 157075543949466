// eslint-disable-next-line no-restricted-imports
import { ClientOpenAccountOrderDto } from 'api/generated/graphql';
import { ClientOpenAccountOrderEntity } from 'api/types/entity';

export const parseClientOpenAccountOrderEntity = (
  openAccountOrder: ClientOpenAccountOrderDto
): ClientOpenAccountOrderEntity => ({
  date: openAccountOrder.date ? new Date(openAccountOrder.date) : undefined,
  number: openAccountOrder.number,
});
