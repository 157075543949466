import { TwoFactorQrCodeGenerateData } from 'api/types/data';
import { OneLinkToMobileStore } from 'components';
import { useTranslation } from 'libs/i18n';
import { Typography, Stack } from 'libs/ui';
import { FormBody } from 'modules/auth/common/components';

import {
  TwoFactorFormType,
  FormErrors,
  Field,
  schema,
} from '../../forms/twoFactorForm';

import { QrCode } from './QrCode';
import { ListStyled } from './styles';

export interface TwoFactorFormProps {
  onSubmit: (values: TwoFactorFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError: FormErrors;
  qrData?: TwoFactorQrCodeGenerateData;
  qrLoading?: boolean;
  submitLoading?: boolean;
}

export const TwoFactorForm = ({
  onSubmit,
  onGoBack,
  canGoBack,
  formError,
  qrData,
  qrLoading,
  submitLoading,
}: TwoFactorFormProps) => {
  const { t } = useTranslation();

  const googleAndroidLink =
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  const googleIosLink =
    'https://apps.apple.com/ru/app/google-authenticator/id388497605';

  const appleAndroidLink =
    'https://apps.apple.com/ru/app/passwords/id6473799789';
  const appleIosLink = 'https://apps.apple.com/ru/app/passwords/id6473799789';

  return (
    <FormBody
      canGoBack={canGoBack}
      initialErrors={formError}
      submitLoading={submitLoading}
      title={t('auth.twoFactorConnection.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <Stack direction="row" justifyContent="space-between" spacing={32}>
          <ListStyled as="ol" spacing={32}>
            <Typography as="li">
              {t('auth.twoFactorConnection.step1.text')}
              <OneLinkToMobileStore
                androidLink={googleAndroidLink}
                className="registrationTwoFactor-googleLink"
                iosLink={googleIosLink}
                label={t('auth.twoFactorConnection.googleAuthentificator')}
                renderEmpty={() => (
                  <>{t('auth.twoFactorConnection.googleAuthentificator')}</>
                )}
              />
              {t('auth.twoFactorConnection.step1.or')}
              <OneLinkToMobileStore
                androidLink={appleAndroidLink}
                className="registrationTwoFactor-appleLink"
                iosLink={appleIosLink}
                label={t('auth.twoFactorConnection.applePasswords')}
                renderEmpty={() => (
                  <>{t('auth.twoFactorConnection.applePasswords')}</>
                )}
              />
              .
            </Typography>
            <Typography as="li">
              {t('auth.twoFactorConnection.step2')}
            </Typography>
            <Typography as="li">
              {t('auth.twoFactorConnection.step3')}
            </Typography>
          </ListStyled>
          <QrCode data={qrData} loading={qrLoading} />
        </Stack>
        <Field.Number
          allowSubmit
          fullWidth
          label={t('components.code.codeLabel')}
          name="code"
          placeholder={t('components.code.codePlaceholder')}
        />
      </Stack>
    </FormBody>
  );
};
