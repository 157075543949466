import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import isPlainObject from 'lodash.isplainobject';
import merge from 'lodash.merge';
import mergeWith from 'lodash.mergewith';
import sortBy from 'lodash.sortby';
import uniq from 'lodash.uniq';
import uniqby from 'lodash.uniqby';

function defaultTo<D, T>(args: T[], defaultValue: D) {
  return compact(args)[0] ?? defaultValue;
}

const compactObject = (obj: object) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => Boolean(value)));

const sortBySearch = <T>(
  collection: T[],
  search: string,
  iteratees: (v: T) => string
) => {
  const sorted = sortBy(collection, iteratees);

  search = search.toLowerCase();

  sorted.sort((a, b) => {
    const valueA = iteratees(a);
    const valueB = iteratees(b);
    if (
      valueA.toLowerCase().startsWith(search) &&
      valueB.toLowerCase().startsWith(search)
    )
      return valueA.localeCompare(valueB);

    if (valueA.toLowerCase().startsWith(search)) {
      return -1;
    }
    if (valueB.toLowerCase().startsWith(search)) {
      return 1;
    }

    return valueA.localeCompare(valueB);
  });

  return sorted;
};

const searchBy = <T>(
  collection: T[],
  search: string,
  iteratees: (v: T) => string
) => {
  if (!search) return collection;

  const itemsFiltered = sortBySearch(
    collection.filter((v) =>
      iteratees(v).toLowerCase().includes(search.toLowerCase())
    ),
    search,
    iteratees
  );
  return itemsFiltered;
};

const lodash = {
  compact,
  compactObject,
  isPlainObject,
  chunk,
  sortBy,
  sortBySearch,
  searchBy,
  isEmpty,
  defaultTo,
  debounce,
  uniq,
  uniqby,
  merge,
  mergeWith,
};

export { lodash };
