// eslint-disable-next-line no-restricted-imports
import {
  UpdateClientRepresentativeByAdminDto_Input,
  MutationInput_ClientRepresentativeController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateRepresentativeClientParams } from 'api/types/params';
import { formatToISODate, lodash } from 'helpers';

import { parseIdentityDocumentParam } from '../parseParam';

import { parseBiographyParam, parseBaseDebtsParam } from './parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateRepresentativeClientParams = (
  params: UpdateRepresentativeClientParams
): UpdateClientRepresentativeByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,

  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,
  biography: params.biography
    ? parseBiographyParam(params.biography)
    : undefined,
  debts: params.debts ? parseBaseDebtsParam(params.debts) : undefined,
  bankAccounts: params.bankAccounts,
  placeOfBirth: params.placeOfBirth,
  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: params.pepConnection
    ? relationType[params.pepConnection]
    : undefined,

  basisForRepresentation: {
    ...params.basisForRepresentation,
    dateOfExpiration: formatToISODate(
      params.basisForRepresentation.dateOfExpiration
    ),
    dateOfIssue: formatToISODate(params.basisForRepresentation.dateOfIssue),
    notary: !lodash.isEmpty(params.basisForRepresentation.notary)
      ? {
          fullName: params.basisForRepresentation.notary.fullName || undefined,
          licenseInfo:
            params.basisForRepresentation.notary.licenseInfo || undefined,
        }
      : undefined,
  },

  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  identityDocument: parseIdentityDocumentParam(params.identityDocument),
});
