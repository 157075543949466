import { W8BENFormParam } from 'api/types/params';
import { isYes } from 'helpers';

import { W8BenFormFormType } from '../../forms/w8BenFormForm';

export const getW8BENFormParam = (data: W8BenFormFormType): W8BENFormParam => ({
  isUSSecuritiesInvestmentPlanned: isYes(
    data.W8BenForm.isUSSecuritiesInvestmentPlanned
  ),
  fileIds: data.W8BenForm.files?.map((v) => v.id!) ?? [],
});
