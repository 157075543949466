// eslint-disable-next-line no-restricted-imports
import { Query_ClientController_GetById_Gender as GenderApi } from 'api/generated/graphql';
import { ClientGenderType } from 'api/types/entity';

const genderMap: Record<GenderApi, ClientGenderType> = {
  [GenderApi.Male]: ClientGenderType.Male,
  [GenderApi.Female]: ClientGenderType.Female,
};

export const parseClientGenderType = (gender: GenderApi) => genderMap[gender];
