import { useTranslation } from 'libs/i18n';
import { Input } from 'libs/ui';
import { Section } from 'modules/client/common/components';

export const USTaxResidencySection = () => {
  const { t } = useTranslation();

  return (
    <Section
      datax="USTaxResidency"
      title={t('client.client.USTaxResidency.title')}
    >
      <Input
        readOnly
        showCopyButton
        label={t('client.client.USTaxResidency.field.label')}
        value={t('common.no')}
      />
    </Section>
  );
};
