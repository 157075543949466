// eslint-disable-next-line no-restricted-imports
import { PaletteOptions } from '@mui/material';

import { Colors } from './colors';

/**
 * Defines the palette options for the light theme.
 */
const light: PaletteOptions = {
  error: { main: Colors.red },
  success: { main: Colors.blue200 },
  text: { primary: Colors.black },

  backdropBg: 'rgba(12, 24, 44, 0.75)',

  /** SWITCH */
  switchBg: Colors.gray100,
  switchThumb: Colors.white,
  switchThumbChecked: Colors.black,
  switchDisabledBg: Colors.gray200,
  switchDisabledThumb: Colors.gray250,

  /** SELECT */
  selectBg: Colors.white,
  selectMenuItem: Colors.black,
  selectSelectedMenuItem: Colors.blue150,
  selectMenuItemBorder: Colors.gray50,

  /** CHECKBOX */
  checkboxBorder: Colors.gray200,
  checkboxHover: Colors.gray50,
  checkboxDisabledBg: Colors.gray100,
  checkboxSelectedBorder: Colors.black,
  checkboxSelectedBg: Colors.black,
  checkboxSelectedIconBg: Colors.white,
  checkboxSelectedHoverIconBg: Colors.gray100,
  checkboxSelectedDisabledIconBg: Colors.gray50,

  /** INPUT */
  inputLabel: Colors.black,
  inputPlaceholder: Colors.gray250,
  inputBorder: Colors.gray200,
  inputBorderError: Colors.red,
  inputBorderFocused: Colors.blue150,
  inputBorderHover: Colors.black,
  inputFilledBg: Colors.white,
  inputFilledBorder: Colors.gray150,
  inputCompactBg: Colors.gray50,
  inputCompactBorder: Colors.gray150,
};

/**
 * Defines the palette options for the dark theme.
 */
const dark: PaletteOptions = {
  ...light,
};

/**
 * Object that contains palette options for both light and dark themes.
 */
export const palettes = {
  light,
  dark,
};

/**
 * Alias for the palette type of the light theme.
 */
export type PaletteType = typeof light;
