import {
  BusinessRelationshipGoalsType,
  BusinessRelationshipInvestmentGoalType,
} from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface BusinessRelationshipFormType {
  businessRelationship: {
    businessRelationshipGoals: SelectType<BusinessRelationshipGoalsType>[];
    otherBusinessRelationshipGoal?: string;

    investmentGoals: SelectType<BusinessRelationshipInvestmentGoalType>[];
    otherInvestmentGoal?: string;
  };
}

const isOtherBusinessRelationshipGoal = (
  values: SelectType<BusinessRelationshipGoalsType>[]
) =>
  values?.find((v) => v?.value === BusinessRelationshipGoalsType.Other) !==
  undefined;

const isOtherInvestmentGoal = (
  values: SelectType<BusinessRelationshipInvestmentGoalType>[]
) =>
  values?.find(
    (v) => v?.value === BusinessRelationshipInvestmentGoalType.Other
  ) !== undefined;

export const getBusinessRelationshipSchema = (
  t: TFunc
): ObjectSchema<BusinessRelationshipFormType> =>
  object({
    businessRelationship: object({
      otherBusinessRelationshipGoal: string().when(
        'businessRelationshipGoals',
        {
          is: isOtherBusinessRelationshipGoal,
          then: (schema) => schema.required().max(200),
        }
      ),
      otherInvestmentGoal: string().when('investmentGoals', {
        is: isOtherInvestmentGoal,
        then: (schema) => schema.required().max(200),
      }),

      businessRelationshipGoals: array()
        .of(
          object({
            value: mixed<BusinessRelationshipGoalsType>()
              .oneOf(Object.values(BusinessRelationshipGoalsType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),

      investmentGoals: array()
        .of(
          object({
            value: mixed<BusinessRelationshipInvestmentGoalType>()
              .oneOf(Object.values(BusinessRelationshipInvestmentGoalType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .min(1, t('validation.required'))
        .required(),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BusinessRelationshipFormType>();

export type FormErrors = FormErrorsBase<BusinessRelationshipFormType>;
