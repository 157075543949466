import { isGlobalProject } from 'config';
import { lodash } from 'helpers';

import * as ru from './locales/ru';
import { Langs } from './types';

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export const mergeLocales = (lng: Langs = Langs.RU) => {
  const merge = (locale: typeof ru) => {
    const isGlobal = isGlobalProject();
    const priority = isGlobal ? locale.global : locale.finance;
    const secondary = isGlobal ? locale.finance : locale.global;

    const result = lodash.mergeWith(
      secondary,
      priority,
      (objValue, srcValue) => {
        if (objValue instanceof Array) {
          return srcValue;
        }
        return undefined;
      }
    );
    return result as typeof locale.finance & typeof locale.global;
  };

  if (lng === Langs.RU) {
    return merge(ru);
  }

  return merge(ru);
};
