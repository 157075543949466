import { FC, useMemo } from 'react';

import { PasswordValidationRule } from 'api/types/data';
import { VerificationCodeFormContentDialog } from 'components';
import { PasswordValidation } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate } from 'libs/navigation';
import { Stack } from 'libs/ui';
import { FormBody } from 'modules/auth/common/components';
import { PasswordRules } from 'modules/auth/setPassword/components';

import {
  getSchema,
  Field,
  ChangePasswordFormSubmit,
  ChangePasswordFormType,
  FormErrors,
  CODE_LENGTH,
} from '../forms/changePasswordForm';

import { ContainerStyled } from './styles';

export type ChangePasswordViewProps = {
  initialErrors?: FormErrors;
  submitLoading?: boolean;
  isOpenVerificationDialog: boolean;
  passwordValidationRule?: PasswordValidationRule;
  onCloseVerificationDialog: () => void;
  onSubmit: ChangePasswordFormSubmit;
};

export const ChangePasswordView: FC<ChangePasswordViewProps> = ({
  initialErrors,
  submitLoading,
  isOpenVerificationDialog,
  passwordValidationRule,
  onCloseVerificationDialog,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { goBack, navigate, canGoBack } = useNavigate();

  const passwordValidation = useMemo(
    () => PasswordValidation.parseByValidationRule(passwordValidationRule),
    [passwordValidationRule]
  );

  const schema = getSchema(t, passwordValidation);

  const onGoBack = () => {
    if (canGoBack) {
      goBack();
    } else {
      navigate(ROUTES.fullPath, '');
    }
  };

  return (
    <ContainerStyled>
      <FormBody
        canGoBack
        initialErrors={initialErrors}
        submitLabel={t('common.save')}
        submitLoading={submitLoading}
        subTitle={t('auth.changePassword.subtitle')}
        title={t('auth.changePassword.title')}
        validationSchema={schema}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
      >
        <Stack spacing={32}>
          <Stack spacing={24}>
            <Field.Password
              allowSubmit
              label={t('auth.changePassword.oldPassword.label')}
              name="oldPassword"
              placeholder={t('auth.changePassword.oldPassword.placeholder')}
            />
            <Field.Password
              allowSubmit
              autoComplete="new-password"
              label={t('auth.changePassword.newPassword.label')}
              name="newPassword"
              placeholder={t('auth.changePassword.newPassword.placeholder')}
            />
          </Stack>
          <PasswordRules
            passwordFieldName="newPassword"
            passwordValidation={passwordValidation}
          />
        </Stack>

        <VerificationCodeFormContentDialog<ChangePasswordFormType>
          codeFieldName="otpCode"
          isOpen={isOpenVerificationDialog}
          length={CODE_LENGTH}
          title={t('auth.changePassword.verificationDialog.title')}
          onClose={onCloseVerificationDialog}
        />
      </FormBody>
    </ContainerStyled>
  );
};
