import { FileEntity, LegalClientEntity } from 'api/types/entity';
import { lodash } from 'helpers';

export const parseLegalClientDocumentsFiles = (
  client: LegalClientEntity
): FileEntity[] =>
  lodash.compact([
    ...(client.additionalDocumentFiles || []),
    ...(client.constitutionalDocument?.files || []),
    ...(client.ownershipStructureFiles || []),
    ...(client.signaturesCardFiles || []),
    client.invoice?.file,
    ...(client.invoice?.paymentConfirmationFiles || []),
    ...(client.signedQuestionnaireFiles || []),
  ]);
