import React from 'react';

import { Typography } from 'libs/ui';

interface Props {
  subtitle: string;
}

export const Subtitle = ({ subtitle }: Props) => (
  <Typography as="h4" variant="subtitle">
    {subtitle}
  </Typography>
);
