import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { EducationFields } from './EducationFields';
import { FinancialInstrumentsFields } from './FinancialInstrumentsFields';
import { FutureOperationsFields } from './FutureOperationsFields';
import { Section } from './Section';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Section
        title={t('client.client.knowledgeAndExperience.education.title')}
      >
        <EducationFields />
      </Section>

      <Section
        title={t(
          'client.client.knowledgeAndExperience.financialInstruments.title'
        )}
      >
        <FinancialInstrumentsFields />
      </Section>

      <Section
        title={t('client.client.knowledgeAndExperience.futureOperations.title')}
      >
        <FutureOperationsFields />
      </Section>
    </Stack>
  );
};
