import { useMemo, useState } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import {
  DialogDeleteAndReasonChange,
  DialogReasonChange,
  Section,
} from 'modules/client/common/components';

import { BranchesItem, BranchesTable } from './BranchesTable';

export interface CompanyBranchesSectionProps {
  branches: LegalClientInnerItemEntity[];
  onClickAddBranch?: () => void;
  onClickBranch?: (id: string) => void;
  setBranchRowHref?: (id: string) => string | undefined;
  onArchiveBranch?: (id: string, reason: string) => Promise<void>;
  onDearchiveBranch?: (id: string, reason: string) => Promise<void>;
  onDeleteBranch?: (id: string, reason: string) => Promise<void>;
}

export const CompanyBranchesSection = ({
  branches,
  onClickAddBranch,
  onClickBranch,
  onArchiveBranch,
  onDearchiveBranch,
  onDeleteBranch,
  setBranchRowHref,
}: CompanyBranchesSectionProps) => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  const [dialogLoading, setDialogLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<BranchesItem>();

  const items: BranchesItem[] = useMemo(() => {
    const result = branches.map((h) => ({
      id: h.id,
      name: h.name,
      location: h.location || '',
      city: h.registrationCity || '',
      archived: h.archived,
      createdAt: h.createdAt,
    }));

    result.sort((a, b) =>
      a.archived === b.archived
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.archived
        ? 1
        : -1
    );

    return result;
  }, [branches]);

  const {
    isOpen: isOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
    open: openDialogReason,
  } = useDialog();
  const {
    isOpen: isOpenArchiveDialog,
    onClose: onCloseArchiveDialog,
    open: openArchiveDialog,
  } = useDialog();

  const onClickDelete = (row: BranchesItem) => {
    setActiveItem(row);
    openDialogReason();
  };

  const onClickArchive = (row: BranchesItem) => {
    setActiveItem(row);
    openArchiveDialog();
  };

  const onDelete = async (reason: string) => {
    if (activeItem && onDeleteBranch) {
      setDialogLoading(true);
      try {
        await onDeleteBranch(activeItem.id, reason);
      } catch (error) {
        /* empty */
      }
      setDialogLoading(false);
    }
    onCloseDeleteDialog();
  };

  const onArchiveAndDearchive = async (reason: string) => {
    if (activeItem) {
      setDialogLoading(true);
      if (!activeItem.archived && onArchiveBranch) {
        try {
          await onArchiveBranch(activeItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      if (activeItem.archived && onDearchiveBranch) {
        try {
          await onDearchiveBranch(activeItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      onCloseArchiveDialog();
      setDialogLoading(false);
    }
  };

  return (
    <Section
      datax="companyBranchesSection"
      headerRight={
        !readOnly &&
        onClickAddBranch && (
          <Button
            label={t('client.legal.companyBranches.addBranch')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onClickAddBranch}
          />
        )
      }
      title={t('client.legal.companyBranches.title')}
    >
      <BranchesTable
        editable={!readOnly}
        items={items}
        setRowHref={
          setBranchRowHref ? (i) => setBranchRowHref(i.id) : undefined
        }
        onCellClick={onClickBranch ? (i) => onClickBranch(i.id) : undefined}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
      />

      <DialogDeleteAndReasonChange
        deleteLoading={dialogLoading}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
      />

      {/** Archive dialog */}
      <DialogReasonChange
        isOpen={isOpenArchiveDialog}
        submitLoading={dialogLoading}
        onClose={onCloseArchiveDialog}
        onSave={onArchiveAndDearchive}
      />
    </Section>
  );
};
