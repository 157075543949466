import { ClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import {
  parseActivityInitialValue,
  parseAddressValue,
  parseDebtsInitialValue,
  parseOrderSubmissionContactsInitialValue,
  parseTaxResidencesValue,
  parseWithdrawalAccountsInitialValue,
  parseBiographyInitialValue,
  parsePepConnectionInitialValue,
  parseStatusesInitialValue,
  parseIdentityDocumentInitialValue,
  parseServiceContractInitialValue,
  parseRiskLevelInitialValue,
  parseResidentialAddressValue,
} from 'modules/client/common/helpers';

import { ClientFormType } from '../../forms/clientForm';

import { parseAccountCodesInitialValue } from './parseAccountCodesInitialValue';
import { parseBusinessRelationshipValue } from './parseBusinessRelationshipValue';
import { parseDocumentsFiles } from './parseDocumentsFiles';
import { parseFinancialPositionValue } from './parseFinancialPositionValue';
import { parseFinancingSourcesValue } from './parseFinancingSourcesValue';
import { parseGeneralValue } from './parseGeneralValue';
import { parseKnowledgeAndExperienceValue } from './parseKnowledgeAndExperienceValue';
import { parseRiskProfileValue } from './parseRiskProfileValue';
import { parseSourcesOfIncomeValue } from './parseSourcesOfIncomeValue';
import { parseW8BENFormValue } from './parseW8BENFormValue';

export const getClientInitialValue = (
  client: ClientEntity,
  t: TFunc
): ClientFormType => ({
  contacts: {
    email: client.email,
    phone: client.phone,
  },

  reasonChange: { reason: '' },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName ?? '',
    dateOfBirth: client.dateOfBirth,
    gender: client.gender
      ? {
          label: t(`client.client.personalData.gender.${client.gender}`),
          value: client.gender,
        }
      : undefined,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: parseTaxResidencesValue(client.taxResidences),

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseResidentialAddressValue(client.residentialAddress),

  placeOfBirth: {
    country: client.placeOfBirth?.country ?? null,
    locality: client.placeOfBirth?.locality ?? '',
  },

  biography: parseBiographyInitialValue(t, client.biography),

  bankAccounts: client.bankAccounts?.map((v) => ({
    country: v.country || null,
    swiftCode: v.swiftCode,
  })) || [{ country: null, swiftCode: '' }],

  financingSources: parseFinancingSourcesValue(client.financingSources, t),
  financialPosition: parseFinancialPositionValue(client.financialPosition, t),

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  debts: parseDebtsInitialValue(client.debts, t),

  withdrawalAccounts: parseWithdrawalAccountsInitialValue(
    client.withdrawalAccounts
  ),

  orderSubmissionContacts: parseOrderSubmissionContactsInitialValue(
    t,
    client.orderSubmissionContacts
  ),

  statuses: parseStatusesInitialValue(client, t),

  accountCodes: parseAccountCodesInitialValue(client),

  additionalDocumentsFiles: client.additionalInformationFiles,

  documentsFiles: parseDocumentsFiles(client),

  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
  openAccountOrder: {
    date: client.openAccountOrder?.date ?? null,
    number: client.openAccountOrder?.number ?? '',
  },

  general: parseGeneralValue(client, t),

  serviceContract: parseServiceContractInitialValue(t, client.serviceContract),

  riskLevel: parseRiskLevelInitialValue(client, t),

  activity: parseActivityInitialValue(client),

  businessRelationship: parseBusinessRelationshipValue(
    client.businessRelationship,
    t
  ),
  riskProfile: parseRiskProfileValue(client, t),
  W8BenForm: parseW8BENFormValue(client, t),
  sourcesOfIncome: parseSourcesOfIncomeValue(client, t),

  knowledgeAndExperience: parseKnowledgeAndExperienceValue(
    t,
    client.knowledgeAndExperience
  ),
});
