import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Field } from 'modules/client/legal/forms/companyRegistrationPlaceForm';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const CompanyRegistrationPlaceSection = ({
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Section
      datax="companyRegistrationPlaceSection"
      title={t('client.legal.companyRegistrationPlace.title')}
    >
      <Field.Autocomplete
        label={t('client.legal.companyRegistrationPlace.county.label')}
        loading={countriesLoading}
        name="registrationPlace.country"
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t(
          'client.legal.companyRegistrationPlace.county.placeholder'
        )}
      />

      <Field.Text
        label={t('client.legal.companyRegistrationPlace.locality.label')}
        name="registrationPlace.locality"
        placeholder={t(
          'client.legal.companyRegistrationPlace.locality.placeholder'
        )}
      />

      <Field.Text
        label={t(
          'client.legal.companyRegistrationPlace.specialRegistrationArea.label'
        )}
        name="registrationPlace.specialRegistrationArea"
        placeholder={t(
          'client.legal.companyRegistrationPlace.specialRegistrationArea.placeholder'
        )}
      />
    </Section>
  );
};
