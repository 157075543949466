export enum BusinessRelationshipGoalsType {
  SecuritiesBrokerage = 'SecuritiesBrokerage', // Брокерское обслуживание на рынке ценных бумаг
  Other = 'Other',
}

export enum BusinessRelationshipInvestmentGoalType {
  RetirementSecurity = 'RetirementSecurity', // Обеспечение старости
  FamilySecurity = 'FamilySecurity', // Безопасность моей семьи
  FuturePurchasesSavings = 'FuturePurchasesSavings', // Сбережения на будущие покупки
  SpeculativeTrading = 'SpeculativeTrading', // Спекулятивная торговля
  RegularIncome = 'RegularIncome', // Регулярный доход
  CapitalAppreciation = 'CapitalAppreciation', // Увеличение стоимости
  Other = 'Other', // Другое
}

export interface BusinessRelationshipEntity {
  businessRelationshipGoals: BusinessRelationshipGoalsType[];
  investmentGoals: BusinessRelationshipInvestmentGoalType[];
  otherBusinessRelationshipGoal?: string;
  otherInvestmentGoal?: string;
}
