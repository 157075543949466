// eslint-disable-next-line no-restricted-imports
import { ClientBusinessRelationshipFragment } from 'api/generated/graphql';
import {
  BusinessRelationshipEntity,
  BusinessRelationshipGoalsType,
  BusinessRelationshipInvestmentGoalType,
} from 'api/types/entity';
import { lodash } from 'helpers';

export const parseBusinessRelationshipEntity = (
  businessRelationship: ClientBusinessRelationshipFragment
): BusinessRelationshipEntity => ({
  businessRelationshipGoals: lodash
    .compact(businessRelationship.businessRelationshipGoals)
    .map((v) => BusinessRelationshipGoalsType[v]),
  investmentGoals: lodash
    .compact(businessRelationship.investmentGoals)
    .map((v) => BusinessRelationshipInvestmentGoalType[v]),
  otherBusinessRelationshipGoal:
    businessRelationship.otherBusinessRelationshipGoal ?? undefined,
  otherInvestmentGoal: businessRelationship.otherInvestmentGoal ?? undefined,
});
