import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  array,
} from 'libs/form';
import { localFileScheme } from 'modules/client/common/helpers';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

import { LocalFileEntity } from '../types';

export interface W8BenFormFormType {
  W8BenForm: {
    isUSSecuritiesInvestmentPlanned?: Maybe<YesOrNoSelectType>;
    files?: LocalFileEntity[];
  };
}

const isPlanned = (v?: YesOrNoSelectType) => v?.value === YesOrNoType.Yes;

export const getW8BenFormSchema = (): ObjectSchema<W8BenFormFormType> =>
  object({
    W8BenForm: object({
      isUSSecuritiesInvestmentPlanned: yesOrNotSelectValidation
        .default(null)
        .required(),
      files: array().when('isUSSecuritiesInvestmentPlanned', {
        is: isPlanned,
        then: (schema) =>
          schema.of(localFileScheme.required()).required().min(1),
      }),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<W8BenFormFormType>();

export type FormErrors = FormErrorsBase<W8BenFormFormType>;
