import { lodash } from 'helpers';

import { DocumentGroupEntity } from '../../../../../api/types/entity';
import { LocalFileEntity } from '../../types';

export const parseAdditionalDocumentFileIdsParam = (
  files: LocalFileEntity[] | undefined,
  documentGroups?: DocumentGroupEntity[] | undefined
) => {
  const documentsInGroups = new Set(
    documentGroups?.flatMap(
      (group) => group.documents?.map((doc) => doc.id) || []
    ) || []
  );

  return lodash.compact(
    files
      ?.filter(
        (file) =>
          file.id && file.uploadedByAdmin && !documentsInGroups.has(file.id)
      )
      .map((file) => file.id)
  );
};
