import {
  BusinessRelationshipGoalsType,
  BusinessRelationshipInvestmentGoalType,
} from 'api/types/entity';
import { BusinessRelationshipParam } from 'api/types/params';

import { BusinessRelationshipFormType } from '../../forms/businessRelationshipForm';

export const getBusinessRelationshipParam = (
  values: BusinessRelationshipFormType
): BusinessRelationshipParam => {
  const businessRelationshipGoals =
    values.businessRelationship.businessRelationshipGoals.map((v) => v.value);

  const investmentGoals = values.businessRelationship.investmentGoals.map(
    (v) => v.value
  );

  const data: BusinessRelationshipParam = {
    businessRelationshipGoals,
    investmentGoals,

    otherBusinessRelationshipGoal: businessRelationshipGoals.includes(
      BusinessRelationshipGoalsType.Other
    )
      ? values.businessRelationship.otherBusinessRelationshipGoal
      : undefined,

    otherInvestmentGoal: investmentGoals.includes(
      BusinessRelationshipInvestmentGoalType.Other
    )
      ? values.businessRelationship.otherInvestmentGoal
      : undefined,
  };
  return data;
};
