import { ClientGenderType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/personalDataForm';

interface Props {
  enabledGender?: boolean;
}

export const PersonalDataSection = ({ enabledGender }: Props) => {
  const { t } = useTranslation();

  return (
    <Section
      datax="personalDataSection"
      title={t('client.client.personalData.title')}
    >
      <Row>
        {enabledGender && (
          <Box flex={1}>
            <Field.Autocomplete
              label={t('client.client.personalData.gender.label')}
              name="personalData.gender"
              optionLabelKey="label"
              options={Object.values(ClientGenderType).map((v) => ({
                label: t(`client.client.personalData.gender.${v}`),
                value: v,
              }))}
              optionValueKey="value"
              placeholder={t('client.client.personalData.gender.placeholder')}
              variant="select"
            />
          </Box>
        )}
        <Box flex={1}>
          <Field.Text
            fullWidth
            label={t('client.client.personalData.lastName.label')}
            name="personalData.lastName"
            placeholder={t('client.client.personalData.lastName.placeholder')}
          />
        </Box>
      </Row>
      <Row>
        <Field.Text
          fullWidth
          label={t('client.client.personalData.firstName.label')}
          name="personalData.firstName"
          placeholder={t('client.client.personalData.firstName.placeholder')}
        />
        <Field.Text
          fullWidth
          label={t('client.client.personalData.middleName.label')}
          name="personalData.middleName"
          placeholder={t('client.client.personalData.middleName.placeholder')}
        />
      </Row>
      <Field.DatePicker
        label={t('client.client.personalData.dateOfBirth.label')}
        name="personalData.dateOfBirth"
        placeholder={t('client.client.personalData.dateOfBirth.placeholder')}
      />
    </Section>
  );
};
