import { OnboardingData } from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import { Chip, ChipProps, ChipVariant } from 'libs/ui';

import { getOnboardingProgressPercent } from '../getOnboardingProgressPercent';

interface ClientsListItem {
  status: ClientOnboardingStatusType | ClientAccountStatusType;
  onboarding?: OnboardingData;
}

export const renderClientStatus = (
  item: ClientsListItem,
  t: TFunc,
  chipProps?: Partial<ChipProps>
) => {
  let variant: ChipVariant = 'blue';
  let text = t(`client.status.${item.status}`);

  switch (item.status) {
    case ClientOnboardingStatusType.FillingOut:
      variant = 'blue';
      const percent = getOnboardingProgressPercent(item.onboarding);
      text += ` (${percent}%)`;
      break;

    case ClientOnboardingStatusType.AwaitingAccountOpening:
    case ClientOnboardingStatusType.SigningDocuments:
    case ClientOnboardingStatusType.ComplianceCheck:
    case ClientOnboardingStatusType.AwaitingComplianceCheck:
    case ClientOnboardingStatusType.AmlCftCheck:
    case ClientOnboardingStatusType.AwaitingAmlCftCheck:
    case ClientOnboardingStatusType.ValidationCheck:
    case ClientOnboardingStatusType.AwaitingValidation:
    case ClientOnboardingStatusType.BoardReview:
    case ClientOnboardingStatusType.PendingBoardReview:
    case ClientOnboardingStatusType.AwaitingPaymentConfirmation:
    case ClientOnboardingStatusType.SigningQuestionnaire:
    case ClientAccountStatusType.Frozen:
      variant = 'yellow';
      break;

    case ClientOnboardingStatusType.AccountOpened:
    case ClientOnboardingStatusType.AccountOpening:
    case ClientAccountStatusType.OpenedWithRestrictions:
    case ClientAccountStatusType.Opened:
      variant = 'green';
      break;

    case ClientOnboardingStatusType.AccountOpeningDenied:
    case ClientAccountStatusType.ClosingInProgress:
    case ClientAccountStatusType.Closed:
      variant = 'red';
      break;

    default:
      variant = 'blue';
      break;
  }

  return (
    <Chip
      {...chipProps}
      size={chipProps?.size ?? 'small'}
      text={text}
      variant={variant}
    />
  );
};
