import { useTranslation } from 'libs/i18n';

import { Field } from '../../../../forms/systemSettingsForm';
import { Subtitle } from '../../components';

export const AuthorizationAndSessionSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <Subtitle
        subtitle={t(
          'systemSettings.accountSystem.authorizationAndSession.title'
        )}
      />

      <Field.Number
        format={(v) => (!v ? '' : Number(v))}
        label={t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.label'
        )}
        name="sessionLengthInDays"
        placeholder={t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.placeholder'
        )}
      />
    </>
  );
};
