import { FC } from 'react';

import { CountryEntity, KycEntity, OccupationType } from 'api/types/entity';
import { isGlobalProject } from 'config';
import { Stack } from 'libs/ui';
import {
  DebtsSection,
  PersonalDataSection,
  CitizenshipSection,
  PlaceOfBirthSection,
  BiographySection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  BankAccountsSection,
  ActivitySection,
  AdditionalDocumentsSection,
  DocumentsSection,
  IdentityDocumentSection,
  KYCSection,
  RegistrationAddressSection,
  ResidentialAddressSection,
} from 'modules/client/common/components/sections';

import { useFormContext } from '../../forms/representativeForm';
import { BasisForRepresentation } from '../sections';

export interface RepresentativeSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  hideActivitySection?: boolean;
}

export const RepresentativeSections: FC<RepresentativeSectionsProps> = ({
  countries,
  countriesLoading,
  kycData,
  hideActivitySection,
}) => {
  const { values } = useFormContext();

  const isGlobal = isGlobalProject();

  const isEntrepreneur =
    values.biography?.occupation?.value === OccupationType.entrepreneur;

  return (
    <Stack spacing={72}>
      {!hideActivitySection && <ActivitySection />}
      <ContactsSection />
      <BasisForRepresentation />
      <PersonalDataSection />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
        enabledDocuments={isGlobal}
      />
      <IdentityDocumentSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PlaceOfBirthSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <KYCSection kycData={kycData} />
      {!isGlobal && (
        <RegistrationAddressSection
          countries={countries}
          countriesLoading={countriesLoading}
        />
      )}
      <ResidentialAddressSection
        enabledFiles
        countries={countries}
        countriesLoading={countriesLoading}
      />

      {!isGlobal && <BiographySection />}

      {!isGlobal && (
        <BankAccountsSection
          countries={countries}
          countriesLoading={countriesLoading}
        />
      )}

      {!isGlobal && <PepConnectionSection />}
      {!isGlobal && <DebtsSection isBase isEntrepreneur={isEntrepreneur} />}

      <DocumentsSection />
      <AdditionalDocumentsSection />
    </Stack>
  );
};
