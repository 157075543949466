import { Checkbox, CheckboxProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface CheckboxFieldProps
  extends FieldType,
    Omit<CheckboxProps, 'name' | 'onChange' | 'checked'> {}

/**
 * Checkbox Field
 */
export const CheckboxField = ({ name, ...props }: CheckboxFieldProps) => {
  const { field, helpers } = useField<boolean | undefined>(name);

  return (
    <Checkbox
      {...props}
      checked={field.value ?? false}
      name={name}
      onChange={(v) => helpers.setValue(v)}
    />
  );
};
