import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {}

export const CompanyFinancingSourcesSection = ({ ...formProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Section
      datax="companyFinancingSourcesSection"
      title={t('client.legal.companyFinancingSources.title')}
    >
      <FormContent {...formProps} />
    </Section>
  );
};
