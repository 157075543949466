import { isYes } from 'helpers';

import { SystemSettingsFormType } from '../forms/systemSettingsForm';

export const getSubmitData = (values: SystemSettingsFormType) => ({
  isAutomaticPersonalAccountGenerationEnabled: isYes(
    values.isAutomaticPersonalAccountGenerationEnabled
  ),
  sessionLengthInDays: values.sessionLengthInDays,
  passwordExpiresInDays: values.isPasswordLimited
    ? values.passwordExpiresInDays!
    : null,
});
