import { OccupationType } from 'api/types/entity';
import { isGlobalProject } from 'config';
import { subYears } from 'date-fns';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  BaseDebtsFormType,
  getBaseDebtsSchema,
} from 'modules/client/common/forms/baseDebtsForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';
import { residentialAddressWithFilesSchema } from 'modules/client/common/forms/residentialAddressForm';
import { taxResidencesWithDocumentsSchema } from 'modules/client/common/forms/taxResidencyForm';
import { applySchema } from 'modules/client/common/helpers';

import { ActivityFormType, activitySchema } from './activityForm';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from './additionalDocumentsForm';
import { BankAccountsFormType, bankAccountsSchema } from './bankAccountsForm';
import {
  BasisForRepresentationFormType,
  getBasisForRepresentationSchema,
} from './basisForRepresentationForm';
import { BiographyFormType, biographySchema } from './biographyForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import { DocumentsFormType, documentsSchema } from './documentsForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import { ResidentialAddressFormType } from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface RepresentativeFormType
  extends ActivityFormType,
    ContactsFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    PlaceOfBirthFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    BiographyFormType,
    BankAccountsFormType,
    PepConnectionFormType,
    BaseDebtsFormType,
    DocumentsFormType,
    BasisForRepresentationFormType,
    IdentityDocumentFormType,
    AdditionalDocumentsFormType,
    ReasonChangeFormType {}

export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getSchema = (
  t: TFunc,
  initialValues?: RepresentativeFormType
): ObjectSchema<RepresentativeFormType> => {
  const isGlobal = isGlobalProject();
  return object()
    .concat(getContactsSchema(t))
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(isGlobal ? taxResidencesWithDocumentsSchema : taxResidencesSchema)
    .concat(applySchema(!isGlobal, registrationAddressSchema))
    .concat(residentialAddressWithFilesSchema)
    .concat(placeOfBirthSchema)
    .concat(applySchema(!isGlobal, biographySchema))
    .concat(applySchema(!isGlobal, bankAccountsSchema))
    .concat(activitySchema)
    .concat(identityDocumentSchema)
    .concat(applySchema(!isGlobal, getPepConnectionSchema(t)))
    .concat(getBasisForRepresentationSchema(t))
    .concat(documentsSchema)
    .concat(reasonChangeSchema)
    .concat(getAdditionalDocumentsSchema())
    .concat(
      applySchema(
        !isGlobal,
        getBaseDebtsSchema(
          initialValues?.biography?.occupation?.value ===
            OccupationType.entrepreneur
        )
      )
    );
};

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RepresentativeFormType>();

export type FormErrors = FormErrorsBase<RepresentativeFormType>;
