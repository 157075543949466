import { CountryEntity } from 'api/types/entity';
import {
  string,
  object,
  array,
  boolean,
  ObjectSchema,
  TypedForm,
} from 'libs/form';
import { Maybe } from 'types/maybe';

import { localFileScheme } from '../helpers/localFile';
import { LocalFileEntity } from '../types';

export interface TaxResidencyFormTaxValue {
  taxNumberIsMissing?: boolean;
  taxNumber?: string;
  reasonForAbsence?: string;
  country?: Maybe<CountryEntity>;
  documents?: LocalFileEntity[];
}

export interface TaxResidencyFormType {
  taxResidences: TaxResidencyFormTaxValue[];
}

const defaultSchema: ObjectSchema<TaxResidencyFormTaxValue> = object({
  taxNumberIsMissing: boolean().default(false),
  taxNumber: string().when('taxNumberIsMissing', {
    is: false,
    then: (schema) => schema.required().max(50),
  }),
  reasonForAbsence: string().when('taxNumberIsMissing', {
    is: true,
    then: (schema) => schema.required().max(2000),
  }),
  country: object({
    id: string().required(),
    name: string().required(),
  }).required(),
  documents: array().default([]),
});

export const taxResidencesSchema: ObjectSchema<TaxResidencyFormType> = object({
  taxResidences: array().of(defaultSchema.required()).required(),
});

export const taxResidencesWithDocumentsSchema: ObjectSchema<TaxResidencyFormType> =
  object({
    taxResidences: array()
      .of(
        defaultSchema
          .concat(
            object({
              documents: array()
                .of(localFileScheme.required())
                .default([])
                .when('taxNumberIsMissing', {
                  is: (v: boolean) => !v,
                  then: (schema) => schema.required().min(1),
                }),
            })
          )
          .required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<TaxResidencyFormType>();
