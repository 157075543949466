import { FC } from 'react';

import {
  ManagerData,
  ClientOnboardingData,
  OnboardingDataStatus,
} from 'api/types/data';
import { CountryEntity, KycEntity, OccupationType } from 'api/types/entity';
import { isGlobalProject } from 'config';
import { Stack } from 'libs/ui';
import { OrderSubmissionSection } from 'modules/client/common/components';
import {
  DebtsSection,
  WithdrawalAccountsSection,
  PersonalDataSection,
  CitizenshipSection,
  PlaceOfBirthSection,
  BiographySection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  BankAccountsSection,
  ActivitySection,
  AdditionalDocumentsSection,
  DocumentsSection,
  AccountCodesSection,
  IdentityDocumentSection,
  ServiceContractSection,
  RiskLevelSection,
  ResultCheckSection,
  ResidentialAddressSection,
  RegistrationAddressSection,
} from 'modules/client/common/components/sections';

import { useFormContext } from '../../forms/clientForm';
import {
  FinancialPositionSection,
  FinancingSourcesSection,
  GeneralSection,
  OpenAccountOrderSection,
  RiskProfileSection,
  W8BENFormSection,
  SourcesOfIncomeSection,
  KnowledgeAndExperienceSection,
  USTaxResidencySection,
} from '../sections';
import { BusinessRelationshipSection } from '../sections/BusinessRelationshipSection';

export interface ClientSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  onboardingData?: ClientOnboardingData;
  managers: ManagerData[];
  managersLoading?: boolean;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  hideActivitySection?: boolean;
  isEditablePersonalAccountNumber?: boolean;
}

export const ClientSections: FC<ClientSectionsProps> = ({
  countries,
  countriesLoading,
  kycData,
  onboardingData,
  managers,
  managersLoading,
  visibleOnboardingStatuses,
  hideActivitySection,
  isEditablePersonalAccountNumber,
}) => {
  const { values } = useFormContext();

  const isGlobal = isGlobalProject();

  const isEntrepreneur =
    values.biography?.occupation?.value === OccupationType.entrepreneur;

  return (
    <Stack spacing={72}>
      <ResultCheckSection
        kycData={kycData}
        statuses={visibleOnboardingStatuses}
      />
      <GeneralSection onboardingData={onboardingData} />
      {!isGlobal && <OpenAccountOrderSection />}
      <ServiceContractSection />
      <RiskLevelSection />
      {!hideActivitySection && <ActivitySection />}
      <AccountCodesSection
        isEditablePersonalAccountNumber={isEditablePersonalAccountNumber}
        managers={managers}
        managersLoading={managersLoading}
      />
      <ContactsSection />
      <PersonalDataSection enabledGender={isGlobal} />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
        enabledDocuments={isGlobal}
      />
      {isGlobal && <USTaxResidencySection />}

      <IdentityDocumentSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PlaceOfBirthSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      {!isGlobal && (
        <RegistrationAddressSection
          countries={countries}
          countriesLoading={countriesLoading}
        />
      )}
      <ResidentialAddressSection
        enabledFiles
        countries={countries}
        countriesLoading={countriesLoading}
      />

      {!isGlobal && <BiographySection />}

      {!isGlobal && (
        <BankAccountsSection
          countries={countries}
          countriesLoading={countriesLoading}
        />
      )}

      {!isGlobal && <FinancingSourcesSection />}
      <PepConnectionSection notRequestInformation={isGlobal} />
      {isGlobal && <SourcesOfIncomeSection />}
      {isGlobal && <KnowledgeAndExperienceSection />}
      {isGlobal && <FinancialPositionSection />}
      {isGlobal && <BusinessRelationshipSection />}
      {isGlobal && <RiskProfileSection />}

      {!isGlobal && <DebtsSection isEntrepreneur={isEntrepreneur} />}
      {isGlobal && <W8BENFormSection />}

      <WithdrawalAccountsSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <OrderSubmissionSection />

      <DocumentsSection />
      <AdditionalDocumentsSection />
    </Stack>
  );
};
