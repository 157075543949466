import { getYesOrNoOptions, isYes } from 'helpers';
import { useTranslation } from 'libs/i18n';
import {
  Field,
  useFormContext,
} from 'modules/client/client/forms/w8BenFormForm';
import { DocumentsTableFeature } from 'modules/client/common/feature';

export interface FormContentProps {}

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.W8BENForm.field.label')}
        name="W8BenForm.isUSSecuritiesInvestmentPlanned"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('client.client.W8BENForm.field.placeholder')}
        variant="select"
      />
      {isYes(values.W8BenForm.isUSSecuritiesInvestmentPlanned) && (
        <DocumentsTableFeature name="W8BenForm.files" />
      )}
    </>
  );
};
