// eslint-disable-next-line no-restricted-imports
import { ClientTaxResidenceFragment } from 'api/generated/graphql';
import { TaxResidencesEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseCountryEntity } from '../../parseCountryEntity';

import { parseFileDocumentEntity } from './parseFileEntity';

export const parseTaxResidenceEntity = (
  taxResidence: Omit<ClientTaxResidenceFragment, '__typename'>
): TaxResidencesEntity => ({
  country: parseCountryEntity(taxResidence.country),
  taxId: taxResidence.taxId ?? undefined,
  reasonForMissingTaxId: taxResidence.reasonForMissingTaxId ?? undefined,
  documents: lodash
    .compact(taxResidence.documents)
    .map(parseFileDocumentEntity),
});
