import { RiskProfileType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Input } from 'libs/ui';
import { Section } from 'modules/client/common/components';

import { Field, useFormContext } from '../../../forms/riskProfileForm';

export const RiskProfileSection = () => {
  const { t } = useTranslation();

  const { readOnly, values } = useFormContext();

  const editable = !readOnly;

  return (
    <Section
      datax="riskProfileSection"
      title={t('client.client.riskProfile.title')}
    >
      {editable ? (
        <Field.Autocomplete
          label={t('client.client.riskProfile.field.label')}
          name="riskProfile"
          optionLabelKey="label"
          options={Object.values(RiskProfileType).map((v) => ({
            label: t(`client.client.riskProfile.types.${v}`),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t('client.client.riskProfile.field.placeholder')}
          variant="select"
        />
      ) : (
        <Input
          multiline
          readOnly
          showCopyButton
          label={t('client.client.riskProfile.field.label')}
          name="riskProfile"
          placeholder={t('client.client.riskProfile.field.placeholder')}
          value={values.riskProfile?.label}
        />
      )}
    </Section>
  );
};
