import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { FormContent } from './FormContent';

export const BasisForRepresentation = () => {
  const { t } = useTranslation();

  return (
    <Section
      datax="basisForRepresentation"
      title={t('client.client.basisForRepresentation.title')}
    >
      <FormContent />
    </Section>
  );
};
