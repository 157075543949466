import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateExpiredPasswordMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useUpdateExpiredPassword = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateExpiredPasswordMutation();

  const error = useError(apolloError);

  return {
    data,
    loading,
    error,
    setPassword: useCallback(
      async (newPassword: string) => {
        const res = await handle({
          variables: { input: { newPassword } },
        });
        return res.data?.setPassword;
      },
      [handle]
    ),
  };
};
