import { TaxResidenceParam } from 'api/types/params';
import { lodash } from 'helpers';

import { TaxResidencyFormType } from '../../forms/taxResidencyForm';

export const parseTaxResidencyParam = (
  values: TaxResidencyFormType,
  includeDocuments?: boolean
): TaxResidenceParam[] => {
  const result: TaxResidenceParam[] = lodash.compact(
    values.taxResidences.map((v) => {
      const res: TaxResidenceParam = {
        country: v.country?.id!,
        taxId: !v.taxNumberIsMissing ? v.taxNumber : undefined,
        reasonForMissingTaxId: v.taxNumberIsMissing
          ? v.reasonForAbsence
          : undefined,
      };

      if (includeDocuments) {
        res.fileIds = v.documents
          ? lodash.compact(v.documents?.map((f) => f.id))
          : undefined;
      }

      return res;
    })
  );

  return result;
};
