import { FC, PropsWithChildren } from 'react';

import { useUser } from 'libs/providers';
import { Box, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { WrapperStyled } from '../Wrapper.styles';

import { HeaderStyled } from './Header.styles';
import { NavigationMenu } from './NavigationMenu';
import { NotificationsFeature } from './NotificationsFeature';
import { User } from './User';

export interface HeaderProps {
  className?: string;
  hideNavigationMenu?: boolean;
  hideNotifications?: boolean;
  hideTimezoneSettings?: boolean;
  hideNotificationsSettings?: boolean;
  hideChangePassword?: boolean;
}

export const Header: FC<HeaderProps & PropsWithChildren> = ({
  className,
  hideNavigationMenu,
  hideNotifications,
  hideTimezoneSettings,
  hideNotificationsSettings,
  hideChangePassword,
}) => {
  const { user } = useUser();

  return (
    <HeaderStyled className={className}>
      <WrapperStyled className="header-wrapper">
        <Stack alignItems="center" direction="row" spacing={48}>
          <Box>
            <Icon.Logo className="header-logo" />
          </Box>
          {!hideNavigationMenu && <NavigationMenu />}
        </Stack>
        <Box className="header-right">
          {user && !hideNotifications && <NotificationsFeature />}
          <User
            hideChangePassword={hideChangePassword}
            hideNotificationsSettings={hideNotificationsSettings}
            hideTimezoneSettings={hideTimezoneSettings}
          />
        </Box>
      </WrapperStyled>
    </HeaderStyled>
  );
};
