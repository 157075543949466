import { useMemo } from 'react';

import { useCreateLegalClientFirstHead } from 'api/requests';
import { useCountries } from 'api/requests/country';
import {
  LegalClientFirstHeadEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import {
  FormErrors,
  getSchema,
  LegalClientFirstHeadFormType,
} from '../forms/legalClientFirstHeadForm';
import {
  getLegalClientFirstHeadInitialValue,
  getLegalClientFirstHeadSubmitData,
} from '../helpers';

export const useLegalClientAddFirstHead = (
  legalClientLegalHead: LegalClientLegalHeadEntity,
  onSuccess: (legalClientFirstHead: LegalClientFirstHeadEntity) => void
) => {
  const { countries, loading: countriesLoading } = useCountries();
  const { t } = useTranslation();
  const notify = useNotify();

  const {
    loading: submitLoading,
    createLegalClientFirstHead,
    error: submitError,
  } = useCreateLegalClientFirstHead();

  const initialValues = getLegalClientFirstHeadInitialValue(t);

  const validationSchema = getSchema(t);

  const onSubmit: FormSubmit<LegalClientFirstHeadFormType> = async (values) => {
    const params = getLegalClientFirstHeadSubmitData(
      legalClientLegalHead.id,
      legalClientLegalHead.versionNumber!,
      values
    );

    const head = await createLegalClientFirstHead(params);
    if (head) {
      onSuccess(head);
      notify.info(t('client.legal.legalClientFirstHead.addDialog.successAdd'));
    }
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (submitError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { personalData: { email: submitError?.message } };
    }

    return undefined;
  }, [submitError?.code, submitError?.message]);

  useServerErrorNotify(submitError);

  return {
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    formError,
    onSubmit,
  };
};
