export enum EducationLevelType {
  /** Магистратура/PhD в сфере экономики или финансов */
  MasterOrPhdInFinance = 'MasterOrPhdInFinance',
  /** Бакалавриат в сфере экономики или финансов */
  BachelorInFinance = 'BachelorInFinance',
  /** Бакалавриат не в сфере экономики или финансов */
  BachelorInOtherFields = 'BachelorInOtherFields',
  /** Среднее в сфере экономики или финансов */
  SecondaryEducationInFinance = 'SecondaryEducationInFinance',
  /** Профессиональный сертификат в сфере финансов */
  ProfessionalCertificateInFinance = 'ProfessionalCertificateInFinance',
  /** Начальное образование */
  PrimaryEducation = 'PrimaryEducation',
}

export enum TradeFrequencyType {
  /** 1-50 сделок в месяц */
  BETWEEN_1_AND_50 = 'BETWEEN_1_AND_50',
  /** 50-100 сделок в месяц */
  BETWEEN_50_AND_100 = 'BETWEEN_50_AND_100',
  /** больше 100 сделок в месяц */
  MORE_THAN_50 = 'MORE_THAN_50',
}

export enum TradeAmountType {
  /** менее 10 000 USD в год */
  LESS_THAN_10K = 'LESS_THAN_10K',
  /** от 50 000 до 100 000 USD в год */
  BETWEEN_50K_100K = 'BETWEEN_50K_100K',
  /** более 100 000 USD в год */
  MORE_THAN_100K = 'MORE_THAN_100K',
}

export enum InvestmentDurationType {
  /** Внутридневная торговля */
  INTRADAY_TRADING = 'INTRADAY_TRADING',
  /** Меньше года */
  LESS_THAN_YEAR = 'LESS_THAN_YEAR',
  /** Больше года */
  MORE_THAN_YEAR = 'MORE_THAN_YEAR',
}

export interface KnowledgeAndExperienceFinancialInstrument {
  yearOfFirstInvestment: number;
  investmentAmount: number;
  transactionCount: number;
}

export interface KnowledgeAndExperienceEntity {
  education: {
    educationLevel?: EducationLevelType;
  };
  financialInstruments: {
    stocks?: KnowledgeAndExperienceFinancialInstrument;
    bonds?: KnowledgeAndExperienceFinancialInstrument;
    mutualFunds?: KnowledgeAndExperienceFinancialInstrument;
    structuredProducts?: KnowledgeAndExperienceFinancialInstrument;
    derivatives?: KnowledgeAndExperienceFinancialInstrument;
  };
  futureOperations: {
    investmentDuration?: InvestmentDurationType;
    tradeFrequency?: TradeFrequencyType;
    tradeAmount?: TradeAmountType;
  };
}
