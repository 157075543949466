import { Dispatch, SetStateAction } from 'react';

import { ClientOnboardingStatusType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';

import { CheckboxFilter } from '../CheckboxFilter';

interface Props {
  statuses: ClientOnboardingStatusType[];
  setStatuses: Dispatch<SetStateAction<ClientOnboardingStatusType[]>>;
  excludeStatuses?: ClientOnboardingStatusType[];
}

export const StatusFilter = ({
  statuses,
  excludeStatuses,
  setStatuses,
}: Props) => {
  const { t } = useTranslation();

  const items = Object.values(ClientOnboardingStatusType)
    .filter((v) => !excludeStatuses?.includes(v))
    .map((v) => ({
      label: t(`client.status.${v}`),
      value: v,
    }));

  return (
    <CheckboxFilter
      items={items}
      selectedItems={statuses}
      setItems={setStatuses}
      title={
        t('client.filters.status') +
        (statuses.length ? ` (${statuses.length})` : '')
      }
    />
  );
};
