import { KnowledgeAndExperienceParam } from 'api/types/params';

import { KnowledgeAndExperienceFormType } from '../../forms/knowledgeAndExperienceForm';

const parseFinancialInstruments = (
  v: KnowledgeAndExperienceFormType['knowledgeAndExperience']['financialInstruments']['bonds']
) =>
  v?.enabled
    ? {
        investmentAmount: v?.investmentAmount!,
        transactionCount: v.transactionCount!,
        yearOfFirstInvestment: v.yearOfFirstInvestment?.value!,
      }
    : undefined;

export const getKnowledgeAndExperienceParam = (
  values: KnowledgeAndExperienceFormType
): KnowledgeAndExperienceParam => ({
  education: {
    educationLevel:
      values.knowledgeAndExperience.education.educationLevel?.value!,
  },
  financialInstruments: {
    bonds: parseFinancialInstruments(
      values.knowledgeAndExperience.financialInstruments?.bonds
    ),
    derivatives: parseFinancialInstruments(
      values.knowledgeAndExperience.financialInstruments?.derivatives
    ),
    mutualFunds: parseFinancialInstruments(
      values.knowledgeAndExperience.financialInstruments?.mutualFunds
    ),
    stocks: parseFinancialInstruments(
      values.knowledgeAndExperience.financialInstruments?.stocks
    ),
    structuredProducts: parseFinancialInstruments(
      values.knowledgeAndExperience.financialInstruments?.structuredProducts
    ),
  },
  futureOperations: {
    investmentDuration:
      values.knowledgeAndExperience.futureOperations.investmentDuration?.value!,
    tradeAmount:
      values.knowledgeAndExperience.futureOperations.tradeAmount?.value!,
    tradeFrequency:
      values.knowledgeAndExperience.futureOperations.tradeFrequency?.value!,
  },
});
