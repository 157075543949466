import { styled } from 'libs/ui/styled';

import { StackProps } from './types';

const Box = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'justifyContent' && prop !== 'alignItems' && prop !== 'spacing',
})<StackProps>(
  ({
    spacing = 0,
    direction = 'column',
    alignItems,
    justifyContent,
    flexWrap,
    flex,
  }) => ({
    display: 'flex',
    flexDirection: direction,
    gap: spacing,
    alignItems,
    justifyContent,
    flexWrap,
    flex,
  })
);

export const Stack = styled(Box)({});
