import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
  number,
  boolean,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface SystemSettingsFormType {
  isAutomaticPersonalAccountGenerationEnabled: Maybe<YesOrNoSelectType>;
  sessionLengthInDays: number;
  passwordExpiresInDays?: number;
  isPasswordLimited: boolean;
}

export const getSchema = (t: TFunc): ObjectSchema<SystemSettingsFormType> =>
  object({
    isAutomaticPersonalAccountGenerationEnabled: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),
    sessionLengthInDays: number()
      .min(
        1,
        t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.errorLength'
        )
      )
      .max(
        30,
        t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.errorLength'
        )
      )
      .required(),

    isPasswordLimited: boolean().required(),

    passwordExpiresInDays: number().when('isPasswordLimited', {
      is: (v: boolean) => v,
      then: (schema) =>
        schema
          .required()
          .min(
            1,
            t(
              'systemSettings.accountSystem.password.passwordExpiresInDaysField.errorLength'
            )
          )
          .max(
            180,
            t(
              'systemSettings.accountSystem.password.passwordExpiresInDaysField.errorLength'
            )
          ),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<SystemSettingsFormType>();

export type FormErrors = FormErrorsBase<SystemSettingsFormType>;
