import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { Field } from '../../../../forms/systemSettingsForm';
import { Subtitle } from '../../components';

export const PersonalAccountSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <Subtitle
        subtitle={t('systemSettings.onboarding.personalAccount.title')}
      />

      <Field.Autocomplete
        fullWidth
        label={t(
          'systemSettings.onboarding.personalAccount.personalAccountField.label'
        )}
        name="isAutomaticPersonalAccountGenerationEnabled"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'systemSettings.onboarding.personalAccount.personalAccountField.placeholder'
        )}
        variant="select"
      />
    </>
  );
};
