// eslint-disable-next-line no-restricted-imports
import { ClientOpenAccountOrderDto_Input } from 'api/generated/graphql';
import { ClientOpenAccountOrderEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';

export const parseClientOpenAccountOrderParam = (
  openAccountOrder: ClientOpenAccountOrderEntity
): ClientOpenAccountOrderDto_Input => ({
  date: openAccountOrder.date ? formatToISODate(openAccountOrder.date) : null,
  number: openAccountOrder.number || null,
});
