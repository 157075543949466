import { DefaultNamespace, ParseKeys, TFunction } from 'i18next';

import finance from './locales/ru/ngdem-finance.json';
import global from './locales/ru/ngdem-global.json';

/**
 * Enum representing language codes for different languages.
 */
export enum Langs {
  RU = 'ru',
}

export type TranslationPath = ParseKeys<
  DefaultNamespace,
  typeof finance & typeof global,
  undefined
>;

export type TFunc = TFunction<DefaultNamespace, undefined>;
