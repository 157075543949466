import { OccupationType } from 'api/types/entity';
import { UpdateRepresentativeClientParams } from 'api/types/params';
import { isGlobalProject } from 'config';
import { lodash } from 'helpers';
import {
  parseAdditionalInformationFileIdsParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseBiographyParam,
  parseCitizenshipsParam,
  parseAdditionalDocumentFileIdsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { RepresentativeFormType } from '../../forms/representativeForm';

import { getBankAccountsParam } from './getBankAccountsParam';
import { getDebtsParam } from './getDebtsParam';

export const getSubmitData = (
  versionNumber: number,
  values: RepresentativeFormType
): UpdateRepresentativeClientParams => {
  const isGlobal = isGlobalProject();
  const { placeOfBirth, registrationAddress, residentialAddress } =
    parseAddressWithFilesParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    firstName: values.personalData.firstName,
    lastName: values.personalData.lastName,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth,
    bankAccounts: !isGlobal ? getBankAccountsParam(values) : undefined,
    biography: !isGlobal ? parseBiographyParam(values) : undefined,
    citizenships: parseCitizenshipsParam(values),
    debts: !isGlobal
      ? getDebtsParam(
          values,
          values.biography?.occupation?.value === OccupationType.entrepreneur
        )
      : undefined,

    registrationAddress: !isGlobal ? registrationAddress : undefined,
    residentialAddress,
    placeOfBirth,

    taxResidences: parseTaxResidencyParam(values, true),

    pepConnection: !isGlobal
      ? values.pepConnection.relation?.value!
      : undefined,
    connectedPepInfo: !isGlobal ? parsePepConnectionParam(values) : undefined,

    basisForRepresentation: {
      documentName: values.basisForRepresentation.documentName,
      dateOfExpiration: values.basisForRepresentation.dateOfExpiration,
      dateOfIssue: values.basisForRepresentation.dateOfIssue,
      fileIds: lodash.compact(
        values.basisForRepresentation.files.map((v) => v.id)
      ),
      documentNumber: values.basisForRepresentation.documentNumber || undefined,
      notary:
        values.basisForRepresentation.notaryFullName ||
        values.basisForRepresentation.notaryLicenseInfo
          ? {
              fullName: values.basisForRepresentation.notaryFullName,
              licenseInfo: values.basisForRepresentation.notaryLicenseInfo,
            }
          : undefined,
    },

    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),

    additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
      values.documentsFiles
    ),

    identityDocument: parseIdentityDocumentParam(values),
  };
};
