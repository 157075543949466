import { SyntheticEvent, useCallback } from 'react';

import { Autocomplete, AutocompleteProps, AutocompleteValue } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface AutocompleteFieldProps<
  Value extends object,
  Multiple extends boolean | undefined
> extends FieldType,
    Omit<
      AutocompleteProps<Value, Multiple>,
      'name' | 'onChange' | 'value' | 'optionValueKey' | 'optionLabelKey'
    > {
  optionValueKey: string;
  optionLabelKey: string;
  onChange?: (value: AutocompleteValue<Value, Multiple>) => void;
}

export const AutocompleteField = <
  Value extends object,
  Multiple extends boolean | undefined
>({
  name,
  allowSubmit,
  optionValueKey,
  optionLabelKey,
  readOnly,
  onChange,
  ...props
}: AutocompleteFieldProps<Value, Multiple>) => {
  const { field, helpers, inputProps } =
    useField<AutocompleteValue<Value, Multiple>>(name);

  const onChangeHandler = useCallback(
    (
      e: SyntheticEvent<Element, Event>,
      value: AutocompleteValue<Value, Multiple>
    ) => {
      helpers.setError('');
      helpers.setValue(value);
      onChange?.(value);
    },
    [helpers, onChange]
  );

  return (
    <Autocomplete
      {...props}
      name={name}
      value={field.value}
      onChange={onChangeHandler}
      {...inputProps}
      optionLabelKey={optionLabelKey as keyof Value}
      optionValueKey={optionValueKey as keyof Value}
      readOnly={readOnly || inputProps.readOnly}
    />
  );
};
