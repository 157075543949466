// eslint-disable-next-line no-restricted-imports
import { LegalClientOpenAccountOrderDto } from 'api/generated/graphql';
import { LegalClientOpenAccountOrderEntity } from 'api/types/entity';

export const parseLegalClientOpenAccountOrderEntity = (
  openAccountOrder: LegalClientOpenAccountOrderDto
): LegalClientOpenAccountOrderEntity => ({
  date: openAccountOrder.date ? new Date(openAccountOrder.date) : undefined,
  number: openAccountOrder.number,
  orderReceivedDate: openAccountOrder.orderReceivedDate
    ? new Date(openAccountOrder.orderReceivedDate)
    : undefined,
});
