import { CountryEntity, IdentityDocumentType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/identityDocumentForm';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const IdentityDocumentSection = ({
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Section
      datax="identityDocumentSection"
      title={t('client.identityDocument.title')}
    >
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            fullWidth
            label={t('client.identityDocument.documenyType.label')}
            name="identityDocument.documentType"
            optionLabelKey="label"
            options={Object.values(IdentityDocumentType).map((v) => ({
              label: t(`client.identityDocument.documenyType.variants.${v}`),
              value: v,
            }))}
            optionValueKey="value"
            placeholder={t('client.identityDocument.documenyType.placeholder')}
            variant="select"
          />
        </Box>
        <Box flex={1}>
          <Field.Text
            fullWidth
            label={t('client.identityDocument.authority.label')}
            name="identityDocument.authority"
            placeholder={t('client.identityDocument.authority.placeholder')}
          />
        </Box>
      </Row>

      <Row>
        <Box flex={1}>
          <Field.Text
            fullWidth
            label={t('client.identityDocument.number.label')}
            name="identityDocument.number"
            placeholder={t('client.identityDocument.number.placeholder')}
          />
        </Box>
        <Box flex={1}>
          <Field.DatePicker
            fullWidth
            disableFuture={false}
            label={t('client.identityDocument.dateOfIssue.label')}
            name="identityDocument.dateOfIssue"
            placeholder={t('client.identityDocument.dateOfIssue.placeholder')}
          />
        </Box>
      </Row>

      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            label={t('client.identityDocument.countryOfIssue.label')}
            loading={countriesLoading}
            name="identityDocument.countryOfIssue"
            optionLabelKey="name"
            options={countries}
            optionValueKey="id"
            placeholder={t(
              'client.identityDocument.countryOfIssue.placeholder'
            )}
          />
        </Box>
        <Box flex={1}>
          <Field.DatePicker
            fullWidth
            disableFuture={false}
            label={t('client.identityDocument.expirationDate.label')}
            name="identityDocument.expirationDate"
            placeholder={t(
              'client.identityDocument.expirationDate.placeholder'
            )}
          />
        </Box>
      </Row>
    </Section>
  );
};
