import { ReactNode } from 'react';

import { Main } from 'modules/App/Main';
import { NotFound } from 'modules/App/NotFound';
import { Admin } from 'modules/admin/admin';
import { Admins } from 'modules/admin/admins';
import { ChangePassword } from 'modules/auth/changePassword';
import { Login } from 'modules/auth/login';
import { LoginByToken } from 'modules/auth/loginByToken';
import { UpdateExpiredPassword, SetPassword } from 'modules/auth/setPassword';
import { TwoFactorConnection } from 'modules/auth/twoFactorConnection';
import { Client, ClientVersion } from 'modules/client/client';
import { Clients } from 'modules/client/clients';
import {
  LegalClient,
  LegalClientVersion,
} from 'modules/client/legal/containers';
import {
  LegalClientBeneficiary,
  LegalClientBeneficiaryVersion,
} from 'modules/client/legalClientBeneficiary';
import {
  LegalClientBranch,
  LegalClientBranchVersion,
} from 'modules/client/legalClientBranch';
import {
  LegalClientFirstHead,
  LegalClientFirstHeadVersion,
} from 'modules/client/legalClientFirstHead';
import {
  LegalClientHead,
  LegalClientHeadVersion,
} from 'modules/client/legalClientHead';
import {
  LegalClientLegalHead,
  LegalClientLegalHeadVersion,
} from 'modules/client/legalClientLegalHead';
import { LegalClients } from 'modules/client/legalClients';
import {
  LegalRepresentative,
  LegalRepresentativeVersion,
} from 'modules/client/legalRepresentative/containers';
import {
  RepresentativeClient,
  RepresentativeClientVersion,
} from 'modules/client/representativeClient';
import { SystemSettings } from 'modules/system/systemSettings';
// eslint-disable-next-line no-restricted-imports
import { Navigate } from 'react-router-dom';

import { RoutesId } from './routesId';

// Define a mapping of route IDs to React components or elements
export const PAGES: Record<RoutesId, ReactNode> = {
  [RoutesId.root]: <Main />,
  [RoutesId.notFound]: <NotFound />,
  // TODO: temporarily there is no home page yet
  [RoutesId.home]: <Navigate replace to="/clients" />,
  [RoutesId.login]: <Login />,
  [RoutesId.loginByToken]: <LoginByToken />,
  [RoutesId.setPassword]: <SetPassword />,
  [RoutesId.twoFactorConnection]: <TwoFactorConnection />,
  [RoutesId.admins]: <Admins />,
  [RoutesId.admin]: <Admin />,
  [RoutesId.clients]: <Clients />,
  [RoutesId.personClients]: <Clients />,
  [RoutesId.personClient]: <Client />,
  [RoutesId.personClientVersion]: <ClientVersion />,
  [RoutesId.legalClients]: <LegalClients />,
  [RoutesId.legalClient]: <LegalClient />,
  [RoutesId.legalClientVersion]: <LegalClientVersion />,
  [RoutesId.legalClientRepresentativeVersion]: <LegalRepresentativeVersion />,
  [RoutesId.legalClientHeadVersion]: <LegalClientHeadVersion />,
  [RoutesId.legalClientLegalHeadVersion]: <LegalClientLegalHeadVersion />,
  [RoutesId.legalClientFirstHeadVersion]: <LegalClientFirstHeadVersion />,
  [RoutesId.legalClientBeneficiaryVersion]: <LegalClientBeneficiaryVersion />,
  [RoutesId.legalClientBranchVersion]: <LegalClientBranchVersion />,
  [RoutesId.legalRepresentative]: <LegalRepresentative />,
  [RoutesId.representativeClient]: <RepresentativeClient />,
  [RoutesId.representativeClientVersion]: <RepresentativeClientVersion />,
  [RoutesId.legalClientHead]: <LegalClientHead />,
  [RoutesId.legalClientLegalHead]: <LegalClientLegalHead />,
  [RoutesId.legalClientFirstHead]: <LegalClientFirstHead />,
  [RoutesId.legalClientBeneficiary]: <LegalClientBeneficiary />,
  [RoutesId.legalClientBranch]: <LegalClientBranch />,
  [RoutesId.systemSettings]: <SystemSettings />,
  [RoutesId.changePassword]: <ChangePassword />,
  [RoutesId.updateExpiredPassword]: <UpdateExpiredPassword />,
};
