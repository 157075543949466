import { ClientEntity, FileEntity } from 'api/types/entity';
import { lodash } from 'helpers';

export const parseDocumentsFiles = (client: ClientEntity): FileEntity[] =>
  lodash.compact([
    ...(client.additionalDocumentFiles?.map((v) => ({
      ...v,
      uploadedByAdmin: true,
    })) || []),
    ...(client.signaturesCardFiles || []),
    ...(client.selfieFiles || []),
    ...(client.identityDocument?.files || []),
    ...(client.documentGroups?.flatMap((v) => v.documents) || []),
    client.invoice?.file,
    ...(client.invoice?.paymentConfirmationFiles || []),
  ]);
