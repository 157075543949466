// eslint-disable-next-line no-restricted-imports
import { FinancialPositionDto_Input } from 'api/generated/graphql';
import { FinancialPositionParam } from 'api/types/params';

export const parseFinancialPositionParam = (
  data: FinancialPositionParam
): FinancialPositionDto_Input => ({
  assetValue: {
    liquidAssets: data.assetValue.liquidAssets
      ? (data.assetValue
          .liquidAssets as unknown as FinancialPositionDto_Input['assetValue']['liquidAssets'])
      : null,

    realEstate: data.assetValue.realEstate
      ? (data.assetValue
          .realEstate as unknown as FinancialPositionDto_Input['assetValue']['realEstate'])
      : null,

    securities: data.assetValue.securities
      ? (data.assetValue
          .securities as unknown as FinancialPositionDto_Input['assetValue']['securities'])
      : null,

    other:
      data.assetValue.other?.otherText && data.assetValue.other?.value
        ? ({
            description: data.assetValue.other?.otherText,
            value: data.assetValue.other?.value as unknown,
          } as FinancialPositionDto_Input['assetValue']['other'])
        : null,
  },

  expenses: {
    livingExpenses: data.expenses.livingExpenses
      ? (data.expenses
          .livingExpenses as unknown as FinancialPositionDto_Input['expenses']['livingExpenses'])
      : null,
    loanRepayment: data.expenses.loanRepayment
      ? (data.expenses
          .loanRepayment as unknown as FinancialPositionDto_Input['expenses']['loanRepayment'])
      : null,
    rent: data.expenses.rent
      ? (data.expenses
          .rent as unknown as FinancialPositionDto_Input['expenses']['rent'])
      : null,

    other:
      data.expenses.other?.otherText && data.expenses.other?.value
        ? ({
            description: data.expenses.other?.otherText,
            value: data.expenses.other?.value as unknown,
          } as FinancialPositionDto_Input['expenses']['other'])
        : null,
  },

  financialLiabilities: {
    bankLoans: data.financialLiabilities.bankLoans
      ? (data.financialLiabilities
          .bankLoans as unknown as FinancialPositionDto_Input['financialLiabilities']['bankLoans'])
      : null,
    mortgageLoan: data.financialLiabilities.mortgageLoan
      ? (data.financialLiabilities
          .mortgageLoan as unknown as FinancialPositionDto_Input['financialLiabilities']['mortgageLoan'])
      : null,

    other:
      data.financialLiabilities.other?.otherText &&
      data.financialLiabilities.other?.value
        ? ({
            description: data.financialLiabilities.other?.otherText,
            value: data.financialLiabilities.other?.value as unknown,
          } as FinancialPositionDto_Input['financialLiabilities']['other'])
        : null,
  },

  regularMonthlyIncome: {
    investmentIncome: data.regularMonthlyIncome.investmentIncome
      ? (data.regularMonthlyIncome
          .investmentIncome as unknown as FinancialPositionDto_Input['regularMonthlyIncome']['investmentIncome'])
      : null,
    businessIncome: data.regularMonthlyIncome.businessIncome
      ? (data.regularMonthlyIncome
          .businessIncome as unknown as FinancialPositionDto_Input['regularMonthlyIncome']['businessIncome'])
      : null,
    work: data.regularMonthlyIncome.work
      ? (data.regularMonthlyIncome
          .work as unknown as FinancialPositionDto_Input['regularMonthlyIncome']['work'])
      : null,

    other:
      data.regularMonthlyIncome.other?.otherText &&
      data.regularMonthlyIncome.other?.value
        ? ({
            description: data.regularMonthlyIncome.other?.otherText,
            value: data.regularMonthlyIncome.other?.value as unknown,
          } as FinancialPositionDto_Input['regularMonthlyIncome']['other'])
        : null,
  },
});
