import { PropsWithChildren } from 'react';

import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { PropsWithTestAttribute } from 'types/testAttribute';

import { FormContent, FormContentProps } from './FormContent';

interface Props
  extends FormContentProps,
    PropsWithChildren,
    PropsWithTestAttribute {}

export const StatusesSection = ({ children, datax, ...formProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Section datax={datax} title={t('client.client.statuses.title')}>
      <FormContent {...formProps} />
      {children}
    </Section>
  );
};
