import React from 'react';

import { Typography } from 'libs/ui';

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => (
  <header>
    <Typography as="h3" variant="headline">
      {title}
    </Typography>
  </header>
);
