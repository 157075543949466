import { Section } from 'modules/client/common/components';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {
  title: string;
}

export const CompanyTaxResidencySection = ({ title, ...formProps }: Props) => (
  <Section datax="companyTaxResidencySection" title={title}>
    <FormContent {...formProps} />
  </Section>
);
