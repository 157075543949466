import { KnowledgeAndExperienceEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { KnowledgeAndExperienceFormType } from '../../forms/knowledgeAndExperienceForm';

const patseFinancialInstruments = (
  v?: KnowledgeAndExperienceEntity['financialInstruments']['bonds']
) => ({
  yearOfFirstInvestment: v?.yearOfFirstInvestment
    ? {
        label: v?.yearOfFirstInvestment.toString(),
        value: v?.yearOfFirstInvestment,
      }
    : undefined,
  investmentAmount: v?.investmentAmount,
  transactionCount: v?.transactionCount,

  enabled: v?.investmentAmount !== undefined,
});

export const parseKnowledgeAndExperienceValue = (
  t: TFunc,
  data: KnowledgeAndExperienceEntity | undefined
) => {
  const values: KnowledgeAndExperienceFormType['knowledgeAndExperience'] = {
    education: {
      educationLevel: data?.education.educationLevel
        ? {
            label: t(
              `client.client.knowledgeAndExperience.education.types.${data?.education.educationLevel}`
            ),
            value: data?.education.educationLevel,
          }
        : undefined,
    },
    financialInstruments: {
      bonds: patseFinancialInstruments(data?.financialInstruments.bonds),
      derivatives: patseFinancialInstruments(
        data?.financialInstruments.derivatives
      ),
      mutualFunds: patseFinancialInstruments(
        data?.financialInstruments.mutualFunds
      ),
      stocks: patseFinancialInstruments(data?.financialInstruments.stocks),
      structuredProducts: patseFinancialInstruments(
        data?.financialInstruments.structuredProducts
      ),
    },
    futureOperations: {
      investmentDuration: data?.futureOperations.investmentDuration
        ? {
            label: t(
              `client.client.knowledgeAndExperience.futureOperations.investmentDuration.types.${data?.futureOperations.investmentDuration}`
            ),
            value: data?.futureOperations.investmentDuration,
          }
        : undefined,

      tradeAmount: data?.futureOperations.tradeAmount
        ? {
            label: t(
              `client.client.knowledgeAndExperience.futureOperations.tradeAmount.types.${data?.futureOperations.tradeAmount}`
            ),
            value: data?.futureOperations.tradeAmount,
          }
        : undefined,
      tradeFrequency: data?.futureOperations.tradeFrequency
        ? {
            label: t(
              `client.client.knowledgeAndExperience.futureOperations.tradeFrequency.types.${data?.futureOperations.tradeFrequency}`
            ),
            value: data?.futureOperations.tradeFrequency,
          }
        : undefined,
    },
  };

  return values;
};
