import { FC } from 'react';

import { useChangePassword } from '../hooks/useChangePassword';
import { ChangePasswordView } from '../views/ChangePasswordView';

export type ChangePasswordContainerProps = {};

export const ChangePasswordContainer: FC<ChangePasswordContainerProps> = () => {
  const {
    isOpenVerificationDialog,
    formError,
    submitLoading,
    onSubmit,
    onCloseVerificationDialog,
  } = useChangePassword();

  return (
    <ChangePasswordView
      initialErrors={formError}
      isOpenVerificationDialog={isOpenVerificationDialog}
      submitLoading={submitLoading}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onSubmit={onSubmit}
    />
  );
};
