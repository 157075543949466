import { useState } from 'react';

import { ClientOnboardingStatusType, CountryEntity } from 'api/types/entity';
import { Filters } from 'components';
import { isFinanceProject, isGlobalProject } from 'config';
import { lodash } from 'helpers';
import { Stack } from 'libs/ui';
import {
  LocationCountryFilter,
  RegistrationCountryFilter,
  RiskLevelFilter,
  StatusFilter,
} from 'modules/client/common/components';

import { ClientFilterType, RiskLevelFilterType } from '../../types';

interface Props {
  filter: ClientFilterType;
  registrationCountries?: CountryEntity[];
  locationCountries?: CountryEntity[];
  onApply: (f: ClientFilterType) => void;
}

export const ClientsFilters = ({
  filter,
  registrationCountries,
  locationCountries,
  onApply,
}: Props) => {
  const isGlobal = isGlobalProject();

  const [statuses, setStatuses] = useState<ClientOnboardingStatusType[]>(
    filter.onboardingStatuses ?? []
  );

  const [riskLevels, setRiskLevels] = useState<RiskLevelFilterType[]>(
    filter.riskLevels ?? []
  );

  const [selectedRegistrationCountries, setSelectedRegistrationCountries] =
    useState<string[]>(filter.registrationCountries ?? []);

  const [selectedLocationCountries, setSelectedLocationCountries] = useState<
    string[]
  >(filter.locationCountries ?? []);

  const countFilter =
    statuses.length +
    riskLevels.length +
    selectedRegistrationCountries.length +
    selectedLocationCountries.length;

  const onReset = () => {
    setStatuses([]);
    setRiskLevels([]);
    setSelectedRegistrationCountries([]);
    setSelectedLocationCountries([]);
    onApply({});
  };

  const handleApply = () => {
    onApply({
      onboardingStatuses: statuses.length ? statuses : undefined,
      riskLevels: riskLevels.length ? riskLevels : undefined,
      registrationCountries: selectedRegistrationCountries.length
        ? selectedRegistrationCountries
        : undefined,
      locationCountries: selectedLocationCountries.length
        ? selectedLocationCountries
        : undefined,
    });
  };

  return (
    <Filters countFilter={countFilter} onApply={handleApply} onReset={onReset}>
      <Stack spacing={32}>
        <StatusFilter
          excludeStatuses={lodash.compact([
            isGlobal && ClientOnboardingStatusType.AwaitingPaymentConfirmation,
            isGlobal && ClientOnboardingStatusType.PendingBoardReview,
            isGlobal && ClientOnboardingStatusType.BoardReview,
          ])}
          setStatuses={setStatuses}
          statuses={statuses}
        />
        <RiskLevelFilter
          riskLevels={riskLevels}
          setRiskLevels={setRiskLevels}
        />
        {isFinanceProject() && (
          <RegistrationCountryFilter
            countries={registrationCountries ?? []}
            selectedCountries={selectedRegistrationCountries}
            setSelectedCountries={setSelectedRegistrationCountries}
          />
        )}
        <LocationCountryFilter
          countries={locationCountries ?? []}
          selectedCountries={selectedLocationCountries}
          setSelectedCountries={setSelectedLocationCountries}
        />
      </Stack>
    </Filters>
  );
};
