import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { Field } from 'modules/client/common/forms/openAccountOrderForm';

export const OpenAccountOrderSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      datax="openAccountOrderSection"
      title={t('client.openAccountOrder.title')}
    >
      <Field.Text
        fullWidth
        label={t('client.openAccountOrder.number.label')}
        name="openAccountOrder.number"
        placeholder={t('client.openAccountOrder.number.placeholder')}
      />
      <Row>
        <Field.DatePicker
          fullWidth
          disableFuture={false}
          label={t('client.openAccountOrder.date.label')}
          name="openAccountOrder.date"
          placeholder={t('client.openAccountOrder.date.placeholder')}
        />
        <Field.DatePicker
          fullWidth
          disableFuture={false}
          label={t('client.openAccountOrder.orderReceivedDate.label')}
          name="openAccountOrder.orderReceivedDate"
          placeholder={t(
            'client.openAccountOrder.orderReceivedDate.placeholder'
          )}
        />
      </Row>
    </Section>
  );
};
