import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/client/client/forms/sourcesOfIncomeForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource =
    values.sourcesOfIncome.incomeSources?.find(
      (v) => v?.value === SourcesOfIncomeSourceType.Other
    ) !== undefined;

  const isOtherOccupation =
    values.sourcesOfIncome.occupation?.value ===
    SourcesOfIncomeOccupationType.Other;

  const isEmployeeOrBusinessOwner =
    values.sourcesOfIncome.occupation?.value ===
      SourcesOfIncomeOccupationType.Employee ||
    values.sourcesOfIncome.occupation?.value ===
      SourcesOfIncomeOccupationType.BusinessOwner;

  return (
    <>
      <Stack spacing={32}>
        <Typography variant="subtitle">
          {t('client.client.sourcesOfIncome.incomeSources.title')}
        </Typography>
        <Field.Autocomplete
          multiple
          label={t('client.client.sourcesOfIncome.incomeSources.label')}
          name="sourcesOfIncome.incomeSources"
          optionLabelKey="label"
          options={Object.values(SourcesOfIncomeSourceType).map((v) => ({
            label: t(`client.client.sourcesOfIncome.sourceType.${v}`),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            'client.client.sourcesOfIncome.incomeSources.placeholder'
          )}
          variant="select"
        />

        {isOtherIncomeSource && (
          <Field.Text
            label={t('client.client.sourcesOfIncome.otherIncomeSource.label')}
            name="sourcesOfIncome.otherIncomeSource"
            placeholder={t(
              'client.client.sourcesOfIncome.otherIncomeSource.placeholder'
            )}
          />
        )}
      </Stack>

      <Stack spacing={32}>
        <Field.Autocomplete
          label={t('client.client.sourcesOfIncome.occupation.label')}
          name="sourcesOfIncome.occupation"
          optionLabelKey="label"
          options={Object.values(SourcesOfIncomeOccupationType).map((v) => ({
            label: t(`client.client.sourcesOfIncome.occupationType.${v}`),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            'client.client.sourcesOfIncome.occupation.placeholder'
          )}
          variant="select"
        />

        {isOtherOccupation && (
          <Field.Text
            label={t('client.client.sourcesOfIncome.otherOccupation.label')}
            name="sourcesOfIncome.otherOccupation"
            placeholder={t(
              'client.client.sourcesOfIncome.otherOccupation.placeholder'
            )}
          />
        )}

        {isEmployeeOrBusinessOwner && (
          <>
            <Field.Text
              label={t('client.client.sourcesOfIncome.workplaceName.label')}
              name="sourcesOfIncome.workplaceName"
              placeholder={t(
                'client.client.sourcesOfIncome.workplaceName.placeholder'
              )}
            />
            <Field.Text
              label={t('client.client.sourcesOfIncome.position.label')}
              name="sourcesOfIncome.position"
              placeholder={t(
                'client.client.sourcesOfIncome.position.placeholder'
              )}
            />
          </>
        )}
      </Stack>
    </>
  );
};
