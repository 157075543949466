export enum ErrorCode {
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  EMAIL_CONFIRMATION_CODE_INVALID = 'EMAIL_CONFIRMATION_CODE_INVALID',
  INVALID_OTP_TOKEN = 'INVALID_OTP_TOKEN',
  USER_WITH_EMAIL_ALREADY_EXISTS = 'USER_WITH_EMAIL_ALREADY_EXISTS',
  FILE_NOT_SENT = 'FILE_NOT_SENT',
  QUANTITY_OF_CITIZENSHIPS_INVALID = 'QUANTITY_OF_CITIZENSHIPS_INVALID',
  QUANTITY_OF_TAX_RESIDENCES_INVALID = 'QUANTITY_OF_TAX_RESIDENCES_INVALID',
  CODE_ALREADY_TAKEN = 'CODE_ALREADY_TAKEN',
  CENTRAL_DEPOSITORY_ACCOUNT_NUMBER_ALREADY_TAKEN = 'CENTRAL_DEPOSITORY_ACCOUNT_NUMBER_ALREADY_TAKEN',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  OLD_PASSWORD = 'OLD_PASSWORD',
}

export interface ValidationError {
  constraint: string;
  description: string;
}
export interface ValidationErrors {
  field: string;
  errors: ValidationError[];
}

export type ValidationServerError = {
  status: number;
  code: ErrorCode.VALIDATION_FAILED;
  description?: string;
  localizedDescription?: string;
  message?: string;
  additionalParams: ValidationErrors[];
};

/** Server error  */
export type ServerError =
  | ValidationServerError
  | {
      status: number;
      code?: Exclude<ErrorCode, ErrorCode.VALIDATION_FAILED>;
      message?: string;
      localizedDescription?: string;
      description?: string;
    };
