import { ChangeEvent } from 'react';

import { Icon } from '../Icon';

import { StyledCheckbox } from './Checkbox.styles';
import { CheckboxProps } from './types';

/**
 * Checkbox Component
 */
export const Checkbox = ({
  disabled,
  checked,
  size = 'medium',
  indeterminate,
  name,
  onChange,
}: CheckboxProps) => {
  /**
   * Handle Checkbox Change
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <StyledCheckbox
      disableRipple
      checked={checked}
      checkedIcon={<Icon.Checked className="checkbox-checkedIcon" />}
      component="button"
      disabled={disabled}
      icon={
        <div className="checkbox-icon">
          <div />
        </div>
      }
      indeterminate={indeterminate}
      indeterminateIcon={
        <Icon.CheckedIntdeterminate className="checkbox-checkedIcon" />
      }
      name={name}
      size={size}
      onChange={handleChange}
    />
  );
};
