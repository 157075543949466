import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { Field } from '../../../forms/companyNameForm';

export const NameSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      datax="nameSection"
      title={t('client.legal.legalClientBranch.branchName.title')}
    >
      <Field.Text
        fullWidth
        label={t('client.legal.legalClientBranch.branchName.label')}
        name="companyName.fullName"
        placeholder={t('client.legal.legalClientBranch.branchName.placeholder')}
      />
    </Section>
  );
};
