import {
  EducationLevelType,
  InvestmentDurationType,
  TradeAmountType,
  TradeFrequencyType,
} from 'api/types/entity';
import { range } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  boolean,
  number,
  selectSchema,
} from 'libs/form';
import { SelectType } from 'types/selectType';

interface FinancialInstrument {
  yearOfFirstInvestment?: SelectType<number>;
  investmentAmount?: number;
  transactionCount?: number;
  enabled?: boolean;
}

const minYear = 1950;
export const yearOfFirstInvestmentYears = range(
  new Date().getFullYear() - minYear + 1,
  minYear
).reverse();

export interface KnowledgeAndExperienceFormType {
  knowledgeAndExperience: {
    education: {
      educationLevel?: SelectType<EducationLevelType>;
    };
    financialInstruments: {
      stocks?: FinancialInstrument;
      bonds?: FinancialInstrument;
      mutualFunds?: FinancialInstrument;
      structuredProducts?: FinancialInstrument;
      derivatives?: FinancialInstrument;
    };
    futureOperations: {
      investmentDuration?: SelectType<InvestmentDurationType>;
      tradeFrequency?: SelectType<TradeFrequencyType>;
      tradeAmount?: SelectType<TradeAmountType>;
    };
  };
}

const financialInstrumentsSchema = object({
  yearOfFirstInvestment: selectSchema(yearOfFirstInvestmentYears)
    .default(null)
    .when('enabled', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  investmentAmount: number().when('enabled', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  transactionCount: number().when('enabled', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  enabled: boolean().default(false).required(),
});

export const getKnowledgeAndExperienceSchema =
  (): ObjectSchema<KnowledgeAndExperienceFormType> =>
    object({
      knowledgeAndExperience: object({
        education: object({
          educationLevel: selectSchema(Object.values(EducationLevelType))
            .default(null)
            .required(),
        }),

        financialInstruments: object({
          stocks: financialInstrumentsSchema,
          bonds: financialInstrumentsSchema,
          mutualFunds: financialInstrumentsSchema,
          structuredProducts: financialInstrumentsSchema,
          derivatives: financialInstrumentsSchema,
        }),

        futureOperations: object({
          investmentDuration: selectSchema(
            Object.values(InvestmentDurationType)
          )
            .default(null)
            .required(),
          tradeFrequency: selectSchema(Object.values(TradeFrequencyType))
            .default(null)
            .required(),
          tradeAmount: selectSchema(Object.values(TradeAmountType))
            .default(null)
            .required(),
        }),
      }),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<KnowledgeAndExperienceFormType>();

export type FormErrors = FormErrorsBase<KnowledgeAndExperienceFormType>;
