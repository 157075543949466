import {
  InvestmentDurationType,
  TradeAmountType,
  TradeFrequencyType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/client/forms/knowledgeAndExperienceForm';

export const FutureOperationsFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t(
          `client.client.knowledgeAndExperience.futureOperations.investmentDuration.label`
        )}
        name="knowledgeAndExperience.futureOperations.investmentDuration"
        optionLabelKey="label"
        options={Object.values(InvestmentDurationType).map((v) => ({
          label: t(
            `client.client.knowledgeAndExperience.futureOperations.investmentDuration.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.selectPlaceholder`)}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          `client.client.knowledgeAndExperience.futureOperations.tradeFrequency.label`
        )}
        name="knowledgeAndExperience.futureOperations.tradeFrequency"
        optionLabelKey="label"
        options={Object.values(TradeFrequencyType).map((v) => ({
          label: t(
            `client.client.knowledgeAndExperience.futureOperations.tradeFrequency.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.selectPlaceholder`)}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          `client.client.knowledgeAndExperience.futureOperations.tradeAmount.label`
        )}
        name="knowledgeAndExperience.futureOperations.tradeAmount"
        optionLabelKey="label"
        options={Object.values(TradeAmountType).map((v) => ({
          label: t(
            `client.client.knowledgeAndExperience.futureOperations.tradeAmount.types.${v}`
          ),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(`common.selectPlaceholder`)}
        variant="select"
      />
    </>
  );
};
