import { AccessTokenType, LoginData } from 'api/types/data';
import { v4 as uuidv4 } from 'uuid';

// Key used for storing the refresh token in local storage.
const REFRESH_TOKEN_KEY = 'refreshToken';
const DEVICE_ID_TOKEN_KEY = 'deviceId';

/**
 * Class for managing token storage in local storage.
 */
class TokenStorage {
  /**
   * The access token stored in local storage.
   * @private
   */
  private accessToken?: string;

  /**
   * The refresh token stored in local storage.
   */
  private refreshToken?: string;

  /**
   * The uniq device id.
   */
  private deviceId?: string;

  private accessTokenType?: AccessTokenType;

  /**
   * Creates a new TokenStorage instance.
   * Initializes the accessToken and refreshToken from local storage if available.
   */
  constructor() {
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || undefined;
    this.deviceId = TokenStorage.generateDeviceId();
  }

  login(loginData: LoginData) {
    this.setAccessToken(loginData.accessToken);
    this.setRefreshToken(loginData.refreshToken);
    this.setAccessTokenType(loginData.accessTokenType);
  }

  /**
   * Sets the access token in local storage and updates the instance's accessToken.
   * @param {string | undefined} accessToken - The access token to set or undefined to remove.
   */
  setAccessTokenType(accessTokenType: AccessTokenType | undefined) {
    this.accessTokenType = accessTokenType;
  }

  setAccessToken(accessToken: string | undefined) {
    this.accessToken = accessToken;
  }

  /**
   * Gets the access token from the instance.
   * @returns {string | undefined} - The access token.
   */
  getAccessToken() {
    return this.accessToken;
  }

  /**
   * Gets the refresh token from the instance.
   * @returns {string | undefined} - The refresh token.
   */
  getRefreshToken() {
    return this.refreshToken;
  }

  getDeviceId() {
    return this.deviceId;
  }

  getAccessTokenType() {
    return this.accessTokenType;
  }

  /**
   * Sets the refresh token in local storage and updates the instance's refreshToken.
   * @param {string | undefined} refreshToken - The refresh token to set or undefined to remove.
   */
  setRefreshToken(refreshToken: string | undefined) {
    this.refreshToken = refreshToken;
    TokenStorage.setOrRemove(REFRESH_TOKEN_KEY, refreshToken);
  }

  /**
   * Clears both access token and refresh token from local storage and the instance.
   */
  clear() {
    this.setRefreshToken(undefined);
    this.setAccessToken(undefined);
  }

  /**
   * Sets or removes a key-value pair in local storage based on the value.
   * @param {string} key - The key to set or remove.
   * @param {string | undefined} value - The value to set or undefined to remove.
   * @private
   */
  private static setOrRemove(key: string, value: string | undefined) {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }

  /** Get or generate a device identifier */
  private static generateDeviceId() {
    // Check if device ID is already stred in localStorage
    let deviceId = localStorage.getItem(DEVICE_ID_TOKEN_KEY);

    if (!deviceId) {
      const uuid = uuidv4();
      deviceId = `${uuid}-${new Date().getTime()}`;

      // Store the device ID in localStorage for future use
      localStorage.setItem(DEVICE_ID_TOKEN_KEY, deviceId);
    }
    return deviceId;
  }
}

/**
 * Singleton instance of the TokenStorage class for managing token storage.
 */
export const tokenStorage = new TokenStorage();
