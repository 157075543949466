import { KycEntity, KycStatusType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Input } from 'libs/ui';

interface Props {
  kycData?: KycEntity;
}

export const KYCFields = ({ kycData }: Props) => {
  const { t } = useTranslation();

  const rejectReason = kycData?.rejectReason
    ?.map((v) => t(`client.client.kyc.rejectReason.types.${v}`))
    .join(', ');

  return (
    <>
      <Input
        readOnly
        showCopyButton
        label={t('client.client.kyc.kycStatus.label')}
        name="kycStatus-stasus"
        value={t(
          `client.client.kyc.kycStatus.statuses.${
            kycData?.kycStatus ?? KycStatusType.notStarted
          }`
        )}
      />
      {rejectReason && (
        <Input
          readOnly
          showCopyButton
          label={t('client.client.kyc.rejectReason.label')}
          name="kycStatus-rejectReason"
          value={rejectReason}
        />
      )}
    </>
  );
};
