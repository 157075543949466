import { PropsWithChildren } from 'react';

import { PropsWithTestAttribute } from 'types/testAttribute';

import { TypographyStyled } from './Typography.styles';
import { TypographyProps } from './types';

/**
 * Typography component.
 */

export const Typography = ({
  children,
  variant = 'body',
  className,
  title,
  align,
  color,
  as,
  maxLine,
  datax,
  onClick,
}: TypographyProps & PropsWithChildren & PropsWithTestAttribute) => (
  <TypographyStyled
    align={align}
    as={as}
    className={className}
    color={color}
    datax={datax}
    maxLine={maxLine}
    title={title}
    variant={variant}
    onClick={onClick}
  >
    {children}
  </TypographyStyled>
);
