import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { AddressFields, Section } from 'modules/client/common/components';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/companyResidentialAddressForm';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  title?: string;
}

export const CompanyRegistrationAndResidentialAddressSection = ({
  countriesLoading,
  countries,
  title,
}: Props) => {
  const { t } = useTranslation();

  const { values } = useFormContext();

  return (
    <Section
      datax="companyRegistrationAndResidentialAddressSection"
      title={
        title ??
        t('client.legal.companyRegistrationAndResidentialAddress.title')
      }
    >
      <Stack spacing={32}>
        <Stack spacing={24}>
          <Typography variant="subtitle">
            {t('client.legal.companyRegistrationAddress.titleRegistration')}
          </Typography>
          <AddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="companyRegistrationAddress"
          />
        </Stack>
        <Stack spacing={24}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle">
              {t('client.legal.companyRegistrationAddress.titleResidential')}
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              spacing={16}
            >
              <Typography variant="bodyMedium">
                {t(
                  'client.legal.companyRegistrationAndResidentialAddress.residencelabel'
                )}
              </Typography>
              <Field.Switch name="companyResidentialAddress.residentialAddressIsSame" />
            </Stack>
          </Stack>
          {!values.companyResidentialAddress.residentialAddressIsSame && (
            <AddressFields
              countries={countries}
              countriesLoading={countriesLoading}
              variant="companyResidentialAddress"
            />
          )}
        </Stack>
      </Stack>
    </Section>
  );
};
