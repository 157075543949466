import { KycEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { KYCFields } from 'modules/client/common/components/KYC/KYCFields';

interface Props {
  kycData?: KycEntity;
}

export const KYCSection = ({ kycData }: Props) => {
  const { t } = useTranslation();
  return (
    <Section datax="KYCSection" title={t('client.client.kycSection.title')}>
      <KYCFields kycData={kycData} />
    </Section>
  );
};
