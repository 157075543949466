// eslint-disable-next-line no-restricted-imports
import {
  SourcesOfIncomeFragment,
  Query_ClientController_GetList_AllOf_1_List_Items_IncomeSources_IncomeSources_Items as IncomeSourcesApi,
  Query_ClientController_GetList_AllOf_1_List_Items_IncomeSources_Occupation as OccupationTypeApi,
} from 'api/generated/graphql';
import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeEntity,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import { lodash } from 'helpers';

const incomeSources: Record<IncomeSourcesApi, SourcesOfIncomeSourceType> = {
  [IncomeSourcesApi.Salary]: SourcesOfIncomeSourceType.Salary,
  [IncomeSourcesApi.Savings]: SourcesOfIncomeSourceType.Savings,
  [IncomeSourcesApi.Inheritance]: SourcesOfIncomeSourceType.Inheritance,
  [IncomeSourcesApi.BusinessIncome]: SourcesOfIncomeSourceType.BusinessActivity,
  [IncomeSourcesApi.Other]: SourcesOfIncomeSourceType.Other,
};

const occupationTypes: Record<
  OccupationTypeApi,
  SourcesOfIncomeOccupationType
> = {
  [OccupationTypeApi.Employee]: SourcesOfIncomeOccupationType.Employee,
  [OccupationTypeApi.Entrepreneur]:
    SourcesOfIncomeOccupationType.IndividualEntrepreneur,
  [OccupationTypeApi.BusinessOwner]:
    SourcesOfIncomeOccupationType.BusinessOwner,
  [OccupationTypeApi.Student]: SourcesOfIncomeOccupationType.Student,
  [OccupationTypeApi.Retired]: SourcesOfIncomeOccupationType.Retiree,
  [OccupationTypeApi.Unemployed]: SourcesOfIncomeOccupationType.Unemployed,
  [OccupationTypeApi.Other]: SourcesOfIncomeOccupationType.Other,
};

export const parseSourcesOfIncomeEntity = (
  data: SourcesOfIncomeFragment
): SourcesOfIncomeEntity => {
  const res: SourcesOfIncomeEntity = {
    incomeSources: lodash.compact(
      data.incomeSources.map((v) => v && incomeSources[v])
    ),
    otherIncomeSource: data.otherIncomeSource || undefined,
    occupation: occupationTypes[data.occupation],
    otherOccupation: data.otherOccupation || undefined,
    workplaceName: data.workplaceName || undefined,
    position: data.position || undefined,
  };

  return res;
};
