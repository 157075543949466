// eslint-disable-next-line no-restricted-imports
import { FinancialPositionFragment } from 'api/generated/graphql';
import {
  FinancialPositionEntity,
  AssetRangeType,
  ExpenseRangeType,
  LiabilityRangeType,
  IncomeRangeType,
} from 'api/types/entity';

export const parseFinancialPositionEntity = (
  data: FinancialPositionFragment
): FinancialPositionEntity => ({
  regularMonthlyIncome: {
    work: data.regularMonthlyIncome.work
      ? IncomeRangeType[data.regularMonthlyIncome.work]
      : undefined,
    businessIncome: data.regularMonthlyIncome.businessIncome
      ? IncomeRangeType[data.regularMonthlyIncome.businessIncome]
      : undefined,
    investmentIncome: data.regularMonthlyIncome.investmentIncome
      ? IncomeRangeType[data.regularMonthlyIncome.investmentIncome]
      : undefined,
    other:
      data.regularMonthlyIncome.other?.description &&
      data.regularMonthlyIncome.other.value
        ? {
            otherText: data.regularMonthlyIncome.other?.description,
            value: IncomeRangeType[data.regularMonthlyIncome.other.value],
          }
        : undefined,
  },
  assetValue: {
    liquidAssets: data.assetValue.liquidAssets
      ? AssetRangeType[data.assetValue.liquidAssets]
      : undefined,
    securities: data.assetValue.securities
      ? AssetRangeType[data.assetValue.securities]
      : undefined,
    realEstate: data.assetValue.realEstate
      ? AssetRangeType[data.assetValue.realEstate]
      : undefined,
    other:
      data.assetValue.other?.description && data.assetValue.other.value
        ? {
            otherText: data.assetValue.other?.description,
            value: AssetRangeType[data.assetValue.other.value],
          }
        : undefined,
  },
  expenses: {
    livingExpenses: data.expenses.livingExpenses
      ? ExpenseRangeType[data.expenses.livingExpenses]
      : undefined,
    rent: data.expenses.rent ? ExpenseRangeType[data.expenses.rent] : undefined,
    loanRepayment: data.expenses.loanRepayment
      ? ExpenseRangeType[data.expenses.loanRepayment]
      : undefined,
    other:
      data.expenses.other?.description && data.expenses.other.value
        ? {
            otherText: data.expenses.other?.description,
            value: ExpenseRangeType[data.expenses.other.value],
          }
        : undefined,
  },
  financialLiabilities: {
    mortgageLoan: data.financialLiabilities.mortgageLoan
      ? LiabilityRangeType[data.financialLiabilities.mortgageLoan]
      : undefined,
    bankLoans: data.financialLiabilities.bankLoans
      ? LiabilityRangeType[data.financialLiabilities.bankLoans]
      : undefined,
    other:
      data.financialLiabilities.other?.description &&
      data.financialLiabilities.other.value
        ? {
            otherText: data.financialLiabilities.other?.description,
            value: LiabilityRangeType[data.financialLiabilities.other.value],
          }
        : undefined,
  },
});
