import { useTranslation } from 'libs/i18n';

import { useUpdateExpiredPassword } from '../hooks';
import { SetPasswordView } from '../views';

export const UpdateExpiredPasswordContainer = () => {
  const { t } = useTranslation();

  const { formErrors, submitLoading, onSubmit } = useUpdateExpiredPassword();

  return (
    <SetPasswordView
      formErrors={formErrors}
      submitLoading={submitLoading}
      subtitle={t('auth.updateExpiredPassword.subtitle')}
      title={t('auth.updateExpiredPassword.title')}
      onSubmit={onSubmit}
    />
  );
};
