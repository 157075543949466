import { RiskProfileType } from 'api/types/entity';
import { ObjectSchema, object, mixed, TypedForm, string } from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface RiskProfileFormType {
  riskProfile: Maybe<SelectType<RiskProfileType>>;
}

export const riskProfileSchema: ObjectSchema<RiskProfileFormType> = object({
  riskProfile: object({
    value: mixed<RiskProfileType>()
      .oneOf(Object.values(RiskProfileType))
      .required(),
    label: string().required(),
  })
    .nullable()
    .required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RiskProfileFormType>();
