import { memo, useLayoutEffect } from 'react';

import 'libs/i18n/instanse';
import { ClientProvider } from 'api/client';
import { useTranslation } from 'libs/i18n';
import { RouterProvider } from 'libs/navigation/router';
import { NotificationsProvider } from 'libs/notify';
import { SystemSettingsProvider, UserProvider } from 'libs/providers';
import { Snackbar } from 'libs/ui';
import { ThemeProvider } from 'libs/ui/theme';

export const App = memo(() => {
  const { language, t } = useTranslation();

  useLayoutEffect(() => {
    document.title = t('pageTitle');
  }, [t]);

  return (
    <ThemeProvider>
      <NotificationsProvider
        renderItem={({ state, close }) => (
          <Snackbar
            text={state.message?.text}
            variant={state.message?.type}
            visible={state.visible}
            onClose={close}
          />
        )}
      >
        <ClientProvider language={language}>
          <UserProvider>
            <SystemSettingsProvider>
              <RouterProvider />
            </SystemSettingsProvider>
          </UserProvider>
        </ClientProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
});
