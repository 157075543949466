import { ClientGenderType } from 'api/types/entity';
import { subYears } from 'date-fns';
import { isValidName } from 'helpers';
import {
  ObjectSchema,
  string,
  object,
  TypedForm,
  date,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface PersonalDataFormType {
  personalData: {
    firstName: string;
    lastName: string;
    middleName?: string;
    dateOfBirth: Date;
    gender?: Maybe<SelectType<ClientGenderType>>;
  };
}

export const registrationDateMax = subYears(new Date(), 100);
export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getBasePersonalDataSchema = (
  t: TFunc,
  enabledGender = false
): ObjectSchema<PersonalDataFormType['personalData']> =>
  object({
    firstName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    lastName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    middleName: string()
      .max(20)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),

    dateOfBirth: date()
      .min(
        dateOfBirthMinDate,
        t('client.client.personalData.dateOfBirth.maxError')
      )
      .max(
        dateOfBirthMaxDate,
        t('client.client.personalData.dateOfBirth.minError')
      )
      .required(),

    gender: object({
      value: mixed<ClientGenderType>()
        .oneOf(Object.values(ClientGenderType))
        .required(),
      label: string().required(),
    })
      .default(undefined)
      .when({
        is: () => enabledGender,
        then: (schema) => schema.default(null).required(),
      }),
  });

export const getPersonalDataSchema = (
  t: TFunc,
  enabledGender = false
): ObjectSchema<PersonalDataFormType> =>
  object({
    personalData: getBasePersonalDataSchema(t, enabledGender),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PersonalDataFormType>();
