export enum SourcesOfIncomeSourceType {
  Salary = 'Salary',
  Savings = 'Savings',
  Inheritance = 'Inheritance',
  BusinessActivity = 'BusinessActivity',
  Other = 'Other',
}

export enum SourcesOfIncomeOccupationType {
  Employee = 'Employee',
  IndividualEntrepreneur = 'IndividualEntrepreneur',
  BusinessOwner = 'BusinessOwner',
  Student = 'Student',
  Retiree = 'Retiree',
  Unemployed = 'Unemployed',
  Other = 'Other',
}

export interface SourcesOfIncomeEntity {
  incomeSources: SourcesOfIncomeSourceType[];
  otherIncomeSource?: string;

  occupation: SourcesOfIncomeOccupationType;
  otherOccupation?: string;

  workplaceAndPosition?: string;

  workplaceName?: string;
  position?: string;
}
