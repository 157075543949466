import { PropsWithChildren } from 'react';

import { OnboardingData } from 'api/types/data';
import { StatusesSection } from 'modules/client/common/components/sections';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, PropsWithChildren {
  onboardingData?: OnboardingData;
}

export const GeneralSection = ({
  children,
  onboardingData,
  ...formProps
}: Props) => (
  <StatusesSection datax="generalSection">
    <FormContent {...formProps} />
  </StatusesSection>
);
