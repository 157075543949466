import { DatePicker, DatePickerProps } from 'libs/ui';

import { TextField } from '../TextField';
import { FieldType } from '../types';
import { useField } from '../useField';

export interface DatePickerFieldProps
  extends FieldType,
    Omit<DatePickerProps, 'name' | 'onChange' | 'helperText' | 'value'> {}

/**
 * DatePicker Field
 */
export const DatePickerField = ({
  name,
  label,
  allowSubmit,
  placeholder,
  readOnly,
  ...datePickerProps
}: DatePickerFieldProps) => {
  const { field, helpers, inputProps } = useField<Date | undefined | null>(
    name,
    { allowSubmit }
  );
  const isReadOnly = readOnly || inputProps.readOnly;

  if (isReadOnly && !field.value) {
    return (
      <TextField
        label={label}
        name={name}
        placeholder={placeholder}
        {...inputProps}
        {...datePickerProps}
        readOnly={isReadOnly}
      />
    );
  }

  return (
    <DatePicker
      label={label}
      name={name}
      placeholder={placeholder}
      value={field.value ?? null}
      onChange={(v) => {
        helpers.setError('');
        helpers.setValue(v);
      }}
      {...datePickerProps}
      {...inputProps}
      readOnly={isReadOnly}
    />
  );
};
