import { useTranslation } from 'libs/i18n';
import { Input, Stack, Typography } from 'libs/ui';

import { Field, useFormContext } from '../../../../forms/systemSettingsForm';
import { Subtitle } from '../../components';

export const PasswordSettings = () => {
  const { t } = useTranslation();
  const { readOnly, values } = useFormContext();

  const editable = !readOnly;

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Subtitle subtitle={t('systemSettings.accountSystem.password.title')} />
        {editable && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={16}
          >
            <Typography>
              {t('systemSettings.accountSystem.password.switchLabel')}
            </Typography>
            <Field.Switch name="isPasswordLimited" />
            {/* <Switch checked={isPasswordLimited} onChange={setPasswordLimited} /> */}
          </Stack>
        )}
      </Stack>

      {!values.isPasswordLimited ? (
        <Input
          disabled={editable}
          label={t(
            'systemSettings.accountSystem.password.passwordExpiresInDaysField.label'
          )}
          value={t(
            'systemSettings.accountSystem.password.passwordExpiresInDaysField.unlimited'
          )}
        />
      ) : (
        <Field.Number
          format={(v) => (!v ? '' : Number(v))}
          label={t(
            'systemSettings.accountSystem.password.passwordExpiresInDaysField.label'
          )}
          name="passwordExpiresInDays"
          placeholder={t(
            'systemSettings.accountSystem.password.passwordExpiresInDaysField.placeholder'
          )}
        />
      )}
    </>
  );
};
