import { BusinessRelationshipEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { BusinessRelationshipFormType } from '../../forms/businessRelationshipForm';

export const parseBusinessRelationshipValue = (
  businessRelationship: BusinessRelationshipEntity | undefined,
  t: TFunc
) => {
  const values: BusinessRelationshipFormType['businessRelationship'] = {
    ...businessRelationship,

    businessRelationshipGoals:
      businessRelationship?.businessRelationshipGoals?.map((v) => ({
        value: v,
        label: t(
          `client.client.businessRelationship.businessRelationshipGoals.types.${v}`
        ),
      })) ?? [],

    investmentGoals:
      businessRelationship?.investmentGoals?.map((v) => ({
        value: v,
        label: t(
          `client.client.businessRelationship.investmentGoals.types.${v}`
        ),
      })) ?? [],
  };

  return values;
};
