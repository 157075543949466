import {
  SourcesOfIncomeOccupationType,
  SourcesOfIncomeSourceType,
} from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface SourcesOfIncomeFormType {
  sourcesOfIncome: {
    incomeSources?: SelectType<SourcesOfIncomeSourceType>[];
    otherIncomeSource?: string;

    occupation?: Maybe<SelectType<SourcesOfIncomeOccupationType>>;
    otherOccupation?: string;

    workplaceName?: string;
    position?: string;
  };
}

const isOtherIncomeSource = (values: SelectType<SourcesOfIncomeSourceType>[]) =>
  values?.find((v) => v?.value === SourcesOfIncomeSourceType.Other) !==
  undefined;

const isOtherOccupation = (value: SelectType<SourcesOfIncomeOccupationType>) =>
  value?.value === SourcesOfIncomeOccupationType.Other;

const isEmployeeOrBusinessOwner = (
  value: SelectType<SourcesOfIncomeOccupationType>
) =>
  value?.value === SourcesOfIncomeOccupationType.Employee ||
  value?.value === SourcesOfIncomeOccupationType.BusinessOwner;

export const getSourcesOfIncomeSchema = (
  t: TFunc
): ObjectSchema<SourcesOfIncomeFormType> =>
  object({
    sourcesOfIncome: object({
      otherIncomeSource: string().when('incomeSources', {
        is: isOtherIncomeSource,
        then: (schema) => schema.required().max(200),
      }),
      otherOccupation: string().when('occupation', {
        is: isOtherOccupation,
        then: (schema) => schema.required().max(200),
      }),

      workplaceName: string().when('occupation', {
        is: isEmployeeOrBusinessOwner,
        then: (schema) => schema.required().max(200),
      }),
      position: string().when('occupation', {
        is: isEmployeeOrBusinessOwner,
        then: (schema) => schema.required().max(200),
      }),

      incomeSources: array()
        .of(
          object({
            value: mixed<SourcesOfIncomeSourceType>()
              .oneOf(Object.values(SourcesOfIncomeSourceType))
              .required(),
            label: string().required(),
          })
            .default(null)
            .required()
        )
        .required()
        .min(1, t('validation.required')),
      occupation: object({
        value: mixed<SourcesOfIncomeOccupationType>()
          .oneOf(Object.values(SourcesOfIncomeOccupationType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .nullable()
        .required(),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<SourcesOfIncomeFormType>();

export type FormErrors = FormErrorsBase<SourcesOfIncomeFormType>;
