import { useAdmin as useAdminApi } from 'api/requests';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { AdminFormType } from '../forms/adminForm';

import { useActivate } from './useActivate';
import { useDeactivate } from './useDeactivate';
import { useResendInvite } from './useResendInvite';
import { useResetPassword } from './useResetPassword';
import { useRevokeOtpVerification } from './useRevokeOtpVerification';
import { useUpdateAdmin } from './useUpdateAdmin';

type AdminNavigationParams = {
  adminId: string;
};

export const useAdmin = () => {
  const { t } = useTranslation();

  const { params } = useNavigate<AdminNavigationParams>();

  const { admin, loading, error, refetch } = useAdminApi(params.adminId!);

  const { formError, submitLoading, onSubmit } = useUpdateAdmin(
    params.adminId!
  );

  const { resetPassword, resetPasswordLoading } = useResetPassword();
  const { resendInvite, timerData } = useResendInvite();
  const { activate } = useActivate();
  const { deactivate } = useDeactivate();
  const { revokeOtpVerification, revokeOtpVerificationLoading } =
    useRevokeOtpVerification();

  const fullName = admin ? parseFullName(admin) : '';

  const initialValues: AdminFormType | undefined = admin
    ? {
        ...admin,
        role: {
          label: t(`admin.adminRoles.${admin.role?.name!}`),
          value: admin.role?.name!,
        },
      }
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.admin]: fullName,
  };

  const onResetPassword = async () => resetPassword(params.adminId!);
  const onResendInvite = () => resendInvite(params.adminId!);
  const onActivate = () => activate(params.adminId!);
  const onDeactivate = () => deactivate(params.adminId!);
  const onRevokeOtpVerification = admin?.otpVerified
    ? async () => revokeOtpVerification(admin?.id!, refetch)
    : undefined;

  usePageTitle(fullName);

  return {
    onSubmit,
    onResetPassword,
    onResendInvite,
    onActivate,
    onDeactivate,
    onRevokeOtpVerification,
    timerData,
    breadcrumbsTitles,
    admin,
    formError,
    initialValues,
    loading: !admin && loading,
    error,
    submitLoading,
    revokeOtpVerificationLoading,
    resetPasswordLoading,
  };
};
