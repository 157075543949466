import { Layout } from 'components';
import { Outlet } from 'libs/navigation';

import { NavigationWrapper } from './NavigationWrapper';

export const Main = () => (
  <NavigationWrapper>
    {({
      isAuthPage,
      isTwoFactorConnectionPage,
      isUpdateExpiredPasswordPage,
    }) => {
      const hideHeader =
        isAuthPage || isTwoFactorConnectionPage || isUpdateExpiredPasswordPage;
      return (
        <Layout
          hideChangePassword={hideHeader}
          hideNavigationMenu={hideHeader}
          hideNotifications={hideHeader}
          hideNotificationsSettings={hideHeader}
          hideTimezoneSettings={hideHeader}
        >
          <Outlet />
        </Layout>
      );
    }}
  </NavigationWrapper>
);
