import { HiddenBoxStyled } from 'components';
import { useField } from 'libs/form/fields/useField';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  Field,
  KnowledgeAndExperienceFormType,
  useFormContext,
  yearOfFirstInvestmentYears,
} from 'modules/client/client/forms/knowledgeAndExperienceForm';

interface Props {
  type: keyof KnowledgeAndExperienceFormType['knowledgeAndExperience']['financialInstruments'];
}

const Section = ({ type }: Props) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  const { field } = useField(
    `knowledgeAndExperience.financialInstruments.${type}.enabled`
  );

  const editable = !readOnly;

  const enabled = !!field.value;

  if (!editable && !enabled) {
    return null;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">
          {t(
            `client.client.knowledgeAndExperience.financialInstruments.${type}.title`
          )}
        </Typography>
        {editable && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={16}
          >
            <Typography variant="bodyMedium">
              {t(
                'client.client.knowledgeAndExperience.financialInstruments.switchLabel'
              )}
            </Typography>
            <Field.Switch
              name={`knowledgeAndExperience.financialInstruments.${type}.enabled`}
            />
          </Stack>
        )}
      </Stack>

      <HiddenBoxStyled hidden={!enabled}>
        <Field.Autocomplete
          label={t(
            `client.client.knowledgeAndExperience.financialInstruments.yearOfFirstInvestmentField.label`
          )}
          name={`knowledgeAndExperience.financialInstruments.${type}.yearOfFirstInvestment`}
          optionLabelKey="label"
          options={yearOfFirstInvestmentYears.map((v) => ({
            label: v.toString(),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            `client.client.knowledgeAndExperience.financialInstruments.yearOfFirstInvestmentField.placeholder`
          )}
          variant="select"
        />
        <Field.Amount
          fullWidth
          label={t(
            `client.client.knowledgeAndExperience.financialInstruments.investmentAmountField.label`
          )}
          name={`knowledgeAndExperience.financialInstruments.${type}.investmentAmount`}
          placeholder={t(
            `client.client.knowledgeAndExperience.financialInstruments.investmentAmountField.placeholder`
          )}
        />
        <Field.Amount
          fullWidth
          label={t(
            `client.client.knowledgeAndExperience.financialInstruments.transactionCountField.label`
          )}
          name={`knowledgeAndExperience.financialInstruments.${type}.transactionCount`}
          placeholder={t(
            `client.client.knowledgeAndExperience.financialInstruments.transactionCountField.placeholder`
          )}
        />
      </HiddenBoxStyled>
    </>
  );
};

export const FinancialInstrumentsFields = () => (
  <>
    <Section type="stocks" />
    <Section type="bonds" />
    <Section type="mutualFunds" />
    <Section type="structuredProducts" />
    <Section type="derivatives" />
  </>
);
