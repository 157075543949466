import { LegalClientBodyEntity } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Section } from 'modules/client/common/components';
import { Row } from 'modules/client/common/components/Row';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/legalClientHeadPositionForm';

interface Props {
  bodies?: LegalClientBodyEntity[];
  onlyPosition?: boolean;
}

export const LegalClientAddHeadPositionSection = ({
  bodies = [],
  onlyPosition,
}: Props) => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  return (
    <Section
      datax="legalClientAddHeadPositionSection"
      title={t('client.legal.legalClientHead.position.title')}
    >
      {!onlyPosition && (
        <Row>
          <Box flex={1}>
            <Field.Autocomplete
              label={t('client.legal.legalClientHead.position.body.label')}
              name="position.body"
              optionLabelKey="label"
              options={bodies
                .filter((v) => !v.archived)
                .map((b) => ({
                  value: b.id,
                  label: b.name,
                }))}
              optionValueKey="value"
              placeholder={t(
                'client.legal.legalClientHead.position.body.placeholder'
              )}
              variant="select"
            />
          </Box>
          <Box flex={1}>
            <Field.Autocomplete
              fullWidth
              label={t('client.legal.legalClientHead.position.firstHead.label')}
              name="position.firstHead"
              optionLabelKey="label"
              options={getYesOrNoOptions(t)}
              optionValueKey="value"
              placeholder={t(
                'client.legal.legalClientHead.position.firstHead.placeholder'
              )}
              variant="select"
            />
          </Box>
        </Row>
      )}
      <Row>
        <Field.Text
          fullWidth
          label={t('client.legal.legalClientHead.position.position.label')}
          name="position.position"
          placeholder={t(
            'client.legal.legalClientHead.position.position.placeholder'
          )}
        />
        <Field.DatePicker
          fullWidth
          disableFuture={false}
          label={t(
            'client.legal.legalClientHead.position.dateOfExpiration.label'
          )}
          name="position.dateOfExpiration"
          placeholder={t(
            'client.legal.legalClientHead.position.dateOfExpiration.placeholder'
          )}
        />
      </Row>

      <DocumentsTableFeature
        name="position.positionConfirmationFiles"
        readOnly={readOnly}
      />
    </Section>
  );
};
