import { isGlobalProject } from 'config';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const LogoFinance = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 116 40"
      width="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.312 39.8395V34.1592H116V35.2303H113.518V38.7684H115.981V39.8395H112.312ZM112.89 37.4619V36.4232H115.461V37.4619H112.89Z"
        fill="#04ACF4"
      />
      <path
        d="M101.109 39.9338C100.684 39.9338 100.293 39.8634 99.9354 39.7228C99.5828 39.5767 99.2744 39.3738 99.01 39.1142C98.7511 38.8491 98.5501 38.538 98.4068 38.181C98.2636 37.8185 98.192 37.4236 98.192 36.9962C98.192 36.5796 98.2664 36.1956 98.4151 35.8439C98.5638 35.4869 98.7731 35.1785 99.043 34.9188C99.3129 34.6537 99.6297 34.4482 99.9932 34.3021C100.357 34.156 100.751 34.083 101.175 34.083C101.45 34.083 101.72 34.1209 101.984 34.1966C102.254 34.2669 102.499 34.3724 102.72 34.5131C102.946 34.6537 103.138 34.8215 103.298 35.0162L102.521 35.8601C102.389 35.7303 102.252 35.6194 102.108 35.5274C101.971 35.4301 101.822 35.357 101.662 35.3083C101.508 35.2542 101.343 35.2272 101.166 35.2272C100.93 35.2272 100.707 35.2705 100.497 35.357C100.293 35.4436 100.114 35.5653 99.9602 35.7222C99.8059 35.8737 99.682 36.0603 99.5884 36.2821C99.5002 36.4985 99.4562 36.7392 99.4562 37.0043C99.4562 37.2748 99.5002 37.521 99.5884 37.7428C99.6765 37.9592 99.8004 38.1458 99.9602 38.3027C100.12 38.4542 100.31 38.5732 100.53 38.6597C100.756 38.7409 101.004 38.7815 101.274 38.7815C101.45 38.7815 101.621 38.7571 101.786 38.7084C101.951 38.6597 102.103 38.5921 102.241 38.5056C102.384 38.419 102.51 38.3189 102.621 38.2053L103.224 39.1547C103.092 39.3008 102.913 39.4333 102.687 39.5524C102.466 39.6714 102.219 39.766 101.943 39.8364C101.668 39.9013 101.39 39.9338 101.109 39.9338Z"
        fill="#04ACF4"
      />
      <path
        d="M84.7775 39.8524V34.1592H85.7954L88.7353 38.4128H88.5226C88.5023 38.2447 88.4846 38.0821 88.4694 37.9248C88.4542 37.7622 88.439 37.5995 88.4238 37.4368C88.4137 37.2688 88.4036 37.098 88.3934 36.9245C88.3884 36.7455 88.3833 36.5585 88.3782 36.3633C88.3732 36.1626 88.3706 35.9458 88.3706 35.7126V34.1592H89.4873V39.8524H88.4542L85.4536 35.5581L85.7422 35.5662C85.7675 35.8482 85.7878 36.0894 85.803 36.2901C85.8233 36.4853 85.8385 36.6561 85.8486 36.8025C85.8587 36.9489 85.8663 37.0763 85.8714 37.1847C85.8815 37.2932 85.8866 37.3962 85.8866 37.4938C85.8916 37.5914 85.8942 37.6944 85.8942 37.8028V39.8524H84.7775Z"
        fill="#04ACF4"
      />
      <path
        d="M70.5753 39.8527L72.8392 34.1724H73.9463L76.202 39.8527H74.9296L73.7646 36.7934C73.7315 36.7069 73.6902 36.596 73.6406 36.4607C73.5966 36.3255 73.5498 36.1821 73.5002 36.0306C73.4506 35.8792 73.4038 35.7358 73.3597 35.6006C73.3157 35.4599 73.2826 35.3436 73.2606 35.2516L73.4837 35.2435C73.4506 35.3679 73.4121 35.4978 73.368 35.633C73.3294 35.7629 73.2881 35.8981 73.2441 36.0388C73.2 36.174 73.1532 36.3065 73.1036 36.4364C73.054 36.5662 73.0072 36.6933 72.9631 36.8178L71.7981 39.8527H70.5753ZM71.6246 38.6598L72.0295 37.6779H74.6817L75.0948 38.6598H71.6246Z"
        fill="#04ACF4"
      />
      <path
        d="M57.6052 39.8524V34.1592H58.6232L61.563 38.4128H61.3503C61.3301 38.2447 61.3123 38.0821 61.2971 37.9248C61.282 37.7622 61.2668 37.5995 61.2516 37.4368C61.2414 37.2688 61.2313 37.098 61.2212 36.9245C61.2161 36.7455 61.2111 36.5585 61.206 36.3633C61.2009 36.1626 61.1984 35.9458 61.1984 35.7126V34.1592H62.3151V39.8524H61.282L58.2813 35.5581L58.57 35.5662C58.5953 35.8482 58.6156 36.0894 58.6308 36.2901C58.651 36.4853 58.6662 36.6561 58.6763 36.8025C58.6865 36.9489 58.6941 37.0763 58.6991 37.1847C58.7093 37.2932 58.7143 37.3962 58.7143 37.4938C58.7194 37.5914 58.7219 37.6944 58.7219 37.8028V39.8524H57.6052Z"
        fill="#04ACF4"
      />
      <path
        d="M47.1343 39.8527V34.1724H47.754C47.8586 34.1724 47.9633 34.1724 48.0679 34.1724C48.1726 34.1724 48.2745 34.1724 48.3736 34.1724V39.8527H47.1343Z"
        fill="#04ACF4"
      />
      <path
        d="M34.8079 39.8527V34.1724H36.0472V39.8527H34.8079ZM35.378 37.5968V36.55H38.3689V37.5968H35.378ZM35.378 35.2679V34.1724H38.7159V35.2679H35.378Z"
        fill="#04ACF4"
      />
      <path
        d="M100.529 27.3601V12.0386H103.858L109.444 21.1221L107.056 21.1004L112.708 12.0386H115.906V27.3601H112.292V22.5881C112.292 21.2749 112.321 20.0937 112.379 19.0427C112.453 17.9921 112.57 16.9488 112.73 15.9127L113.146 17.0509L108.896 23.6392H107.451L103.289 17.0947L103.705 15.9127C103.865 16.8904 103.975 17.8972 104.034 18.9333C104.106 19.9544 104.143 21.1726 104.143 22.5881V27.3601H100.529Z"
        fill="#0F4B61"
      />
      <path
        d="M86.4098 27.3601V12.0386H97.0992V15.2123H90.0242V24.1864H97.3182V27.3601H86.4098ZM88.1622 21.0787V18.0577H96.1134V21.0787H88.1622Z"
        fill="#0F4B61"
      />
      <path
        d="M69.4886 27.3601V12.0386H75.9064C77.0306 12.0386 78.0608 12.2282 78.9949 12.6077C79.93 12.9724 80.7329 13.4977 81.4045 14.1836C82.0917 14.8694 82.6166 15.6793 82.9824 16.6131C83.3471 17.547 83.53 18.5757 83.53 19.6993C83.53 20.8226 83.3471 21.8592 82.9824 22.807C82.6166 23.7414 82.0917 24.5508 81.4045 25.2374C80.7329 25.9085 79.93 26.434 78.9949 26.8129C78.0608 27.1774 77.0306 27.3601 75.9064 27.3601H69.4886ZM73.2126 24.8213L72.7084 24.0769H75.7969C76.4106 24.0769 76.9583 23.9747 77.4398 23.7703C77.9223 23.5514 78.3305 23.252 78.6663 22.8731C79.0176 22.4942 79.2873 22.0337 79.4774 21.4937C79.6676 20.9538 79.7616 20.356 79.7616 19.6993C79.7616 19.0427 79.6676 18.4444 79.4774 17.9045C79.2873 17.3647 79.0176 16.905 78.6663 16.5256C78.3305 16.1462 77.9223 15.8544 77.4398 15.6501C76.9583 15.4312 76.4106 15.3218 75.7969 15.3218H72.6422L73.2126 14.6213V24.8213Z"
        fill="#0F4B61"
      />
      <path
        d="M59.472 27.5788C58.2455 27.5788 57.1357 27.3816 56.1428 26.9882C55.1497 26.5938 54.2953 26.0394 53.5798 25.3239C52.8641 24.6095 52.3165 23.7701 51.9369 22.8068C51.5571 21.8291 51.3673 20.7718 51.3673 19.6335C51.3673 18.4953 51.5645 17.452 51.9588 16.5035C52.3676 15.5404 52.9445 14.7014 53.6893 13.9864C54.4341 13.2714 55.3103 12.7169 56.318 12.3229C57.3402 11.9289 58.4501 11.7319 59.6477 11.7319C60.495 11.7319 61.2906 11.8414 62.0356 12.0603C62.7951 12.2791 63.4812 12.5782 64.0949 12.9577C64.7077 13.3371 65.2264 13.7748 65.65 14.2709L63.3933 16.6567C63.0286 16.3065 62.6483 16.0146 62.2547 15.7812C61.8744 15.5478 61.4663 15.3653 61.0281 15.234C60.59 15.0881 60.1219 15.0151 59.626 15.0151C58.9833 15.0151 58.3922 15.1319 57.8514 15.3653C57.3257 15.5988 56.8583 15.9271 56.4494 16.3503C56.0551 16.7588 55.7485 17.2477 55.5294 17.8168C55.3103 18.3713 55.2008 18.9768 55.2008 19.6335C55.2008 20.2897 55.3176 20.903 55.5513 21.4718C55.7849 22.0407 56.1062 22.5373 56.5152 22.9606C56.924 23.3695 57.4059 23.6978 57.9609 23.9456C58.5307 24.1789 59.1362 24.2956 59.7789 24.2956C60.2315 24.2956 60.6551 24.2223 61.0498 24.0767C61.459 23.9311 61.8093 23.7339 62.1007 23.4861C62.3931 23.2373 62.6266 22.9462 62.8023 22.6106C62.9769 22.2596 63.0648 21.8879 63.0648 21.4935V20.9246L63.5473 21.669H59.3191V18.7579H66.5263C66.5552 18.9185 66.5769 19.1374 66.5914 19.4146C66.6069 19.6918 66.6141 19.9614 66.6141 20.2246C66.6286 20.4869 66.6358 20.6841 66.6358 20.8152C66.6358 21.8074 66.4529 22.7201 66.0882 23.5512C65.7379 24.3689 65.2409 25.0761 64.5982 25.6749C63.9554 26.2727 63.196 26.7394 62.3198 27.0749C61.4435 27.4105 60.495 27.5788 59.472 27.5788Z"
        fill="#0F4B61"
      />
      <path
        d="M34.683 27.3601V12.0386H38.0127L46.052 22.9825L45.3292 22.8731C45.2561 22.392 45.1977 21.9315 45.1539 21.4937C45.1101 21.056 45.0663 20.6254 45.0225 20.2032C44.9932 19.7796 44.9641 19.3491 44.9349 18.9114C44.9203 18.4736 44.9056 17.9994 44.891 17.4887C44.891 16.9779 44.891 16.4235 44.891 15.8252V12.0386H48.5055V27.3601H45.132L36.7421 16.0659L37.8593 16.2192C37.9324 16.9196 37.9907 17.5179 38.0346 18.014C38.0929 18.4955 38.1367 18.9259 38.166 19.3054C38.2098 19.6848 38.239 20.0277 38.2536 20.3343C38.2682 20.6409 38.2755 20.9465 38.2755 21.2532C38.2901 21.5454 38.2974 21.8737 38.2974 22.2381V27.3601H34.683Z"
        fill="#0F4B61"
      />
      <path
        d="M5.14178 37.4855L0 40.0001V16.3456C0 13.7203 1.51725 11.3162 3.9055 10.1418L9.35635 7.4751V30.7843C9.35635 33.6167 7.72672 36.2143 5.14178 37.4855Z"
        fill="url(#paint0_linear_476_2305)"
      />
      <path
        d="M9.35634 22.881L6.77141 19.8965C3.3295 15.9172 4.59388 9.81016 9.35634 7.4751V22.881Z"
        fill="url(#paint1_linear_476_2305)"
      />
      <path
        d="M9.35632 7.4751V22.881L16.6335 32.5252V17.0917L9.35632 7.4751Z"
        fill="#95D9E5"
      />
      <path
        d="M22.6323 29.5958L16.6476 32.525V9.21588C16.6476 6.38341 18.2772 3.78583 20.8622 2.51467L26.0039 0V24.2349C25.9899 26.5147 24.6974 28.5872 22.6323 29.5958Z"
        fill="url(#paint2_linear_476_2305)"
      />
      <path
        d="M16.6335 32.5248V17.0913L19.2325 20.1034C22.6744 24.0965 21.396 30.1898 16.6335 32.5248Z"
        fill="url(#paint3_linear_476_2305)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_476_2305"
          x1="4.6799"
          x2="4.6799"
          y1="7.47333"
          y2="39.9982"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_476_2305"
          x1="7.08997"
          x2="7.08997"
          y1="22.8795"
          y2="7.47333"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_476_2305"
          x1="21.3181"
          x2="21.3181"
          y1="32.5267"
          y2="0.00190761"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_476_2305"
          x1="18.911"
          x2="18.911"
          y1="17.092"
          y2="32.5265"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);
export const LogoGlobal = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="44"
      viewBox="0 0 126 44"
      width="126"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8791 44.0001C40.4015 44.0001 39.9622 43.9195 39.5612 43.7584C39.166 43.5913 38.824 43.3616 38.5351 43.0692C38.2461 42.7768 38.022 42.4338 37.8628 42.0399C37.7095 41.6461 37.6328 41.2195 37.6328 40.76C37.6328 40.3006 37.7124 39.8769 37.8716 39.4891C38.0368 39.0953 38.2667 38.7552 38.5616 38.4688C38.8624 38.1764 39.2132 37.9496 39.6142 37.7885C40.0152 37.6274 40.4546 37.5469 40.9322 37.5469C41.2625 37.5469 41.5779 37.5916 41.8787 37.6811C42.1794 37.7706 42.4537 37.893 42.7013 38.0481C42.949 38.2032 43.1554 38.3822 43.3205 38.5851L42.4979 39.4622C42.3446 39.3131 42.1824 39.1878 42.0114 39.0863C41.8463 38.9789 41.6723 38.8984 41.4895 38.8447C41.3067 38.785 41.118 38.7552 40.9234 38.7552C40.6462 38.7552 40.3897 38.8059 40.1538 38.9073C39.9238 39.0088 39.7204 39.149 39.5435 39.328C39.3665 39.507 39.228 39.7188 39.1277 39.9635C39.0334 40.2081 38.9862 40.4736 38.9862 40.76C38.9862 41.0405 39.0363 41.306 39.1366 41.5566C39.2427 41.8013 39.3842 42.0161 39.5612 42.201C39.744 42.38 39.9563 42.5233 40.198 42.6307C40.4398 42.7321 40.6993 42.7828 40.9764 42.7828C41.1711 42.7828 41.3568 42.753 41.5337 42.6933C41.7106 42.6336 41.864 42.5501 41.9937 42.4427C42.1293 42.3293 42.2325 42.1981 42.3033 42.0489C42.38 41.8997 42.4183 41.7416 42.4183 41.5745V41.3597L42.5952 41.6103H40.8261V40.4915H43.692C43.6979 40.5572 43.7038 40.6377 43.7097 40.7332C43.7215 40.8287 43.7274 40.9241 43.7274 41.0196C43.7333 41.1091 43.7363 41.1807 43.7363 41.2344C43.7363 41.6402 43.6626 42.0131 43.5151 42.3532C43.3736 42.6933 43.1731 42.9857 42.9136 43.2303C42.6601 43.475 42.3593 43.6659 42.0114 43.8032C41.6635 43.9344 41.286 44.0001 40.8791 44.0001Z"
        fill="#04ACF4"
      />
      <path
        d="M54.5859 43.9137V37.6484H55.9128V42.7054H58.7168V43.9137H54.5859Z"
        fill="#04ACF4"
      />
      <path
        d="M71.235 44.0035C70.7809 44.0035 70.3622 43.9229 69.9789 43.7618C69.5956 43.6007 69.2595 43.3769 68.9705 43.0905C68.6874 42.7982 68.4692 42.4551 68.3159 42.0612C68.1626 41.6615 68.0859 41.2289 68.0859 40.7635C68.0859 40.2921 68.1626 39.8625 68.3159 39.4746C68.4692 39.0808 68.6874 38.7377 68.9705 38.4453C69.2595 38.1529 69.5956 37.9262 69.9789 37.7651C70.3622 37.604 70.7809 37.5234 71.235 37.5234C71.6949 37.5234 72.1136 37.604 72.491 37.7651C72.8744 37.9262 73.2075 38.1529 73.4906 38.4453C73.7796 38.7377 74.0007 39.0808 74.154 39.4746C74.3073 39.8684 74.384 40.298 74.384 40.7635C74.384 41.2289 74.3073 41.6585 74.154 42.0523C74.0007 42.4461 73.7796 42.7892 73.4906 43.0816C73.2075 43.374 72.8744 43.6007 72.491 43.7618C72.1136 43.9229 71.6949 44.0035 71.235 44.0035ZM71.235 42.7325C71.4944 42.7325 71.7333 42.6848 71.9515 42.5893C72.1756 42.4938 72.3672 42.3566 72.5264 42.1776C72.6856 41.9986 72.8095 41.7898 72.8979 41.5511C72.9923 41.3124 73.0395 41.0499 73.0395 40.7635C73.0395 40.477 72.9923 40.2145 72.8979 39.9758C72.8095 39.7371 72.6856 39.5313 72.5264 39.3583C72.3672 39.1792 72.1756 39.042 71.9515 38.9465C71.7333 38.8451 71.4944 38.7944 71.235 38.7944C70.9755 38.7944 70.7367 38.8451 70.5185 38.9465C70.3003 39.042 70.1086 39.1792 69.9435 39.3583C69.7784 39.5313 69.6516 39.7371 69.5632 39.9758C69.4806 40.2145 69.4393 40.477 69.4393 40.7635C69.4393 41.0499 69.4806 41.3124 69.5632 41.5511C69.6516 41.7898 69.7784 41.9986 69.9435 42.1776C70.1086 42.3566 70.3003 42.4938 70.5185 42.5893C70.7367 42.6848 70.9755 42.7325 71.235 42.7325Z"
        fill="#04ACF4"
      />
      <path
        d="M85.9688 43.9137V37.6484H88.6578C89.0706 37.6484 89.4185 37.7111 89.7016 37.8364C89.9905 37.9557 90.2087 38.1318 90.3562 38.3645C90.5036 38.5972 90.5773 38.8746 90.5773 39.1968C90.5773 39.5549 90.4889 39.8532 90.3119 40.0919C90.135 40.3305 89.8815 40.4946 89.5512 40.5841L89.5424 40.4051C89.8136 40.4708 90.0525 40.5782 90.2589 40.7273C90.4653 40.8765 90.6245 41.0615 90.7365 41.2823C90.8486 41.503 90.9046 41.7536 90.9046 42.0341C90.9046 42.3444 90.8545 42.6188 90.7542 42.8575C90.654 43.0902 90.5065 43.2842 90.3119 43.4393C90.1173 43.5944 89.8874 43.7138 89.622 43.7973C89.3625 43.8749 89.0706 43.9137 88.7463 43.9137H85.9688ZM87.2425 42.7501H88.6932C88.8819 42.7501 89.0411 42.7233 89.1709 42.6696C89.3065 42.6099 89.4097 42.5204 89.4805 42.4011C89.5512 42.2817 89.5866 42.1415 89.5866 41.9804C89.5866 41.8253 89.5483 41.694 89.4716 41.5866C89.3949 41.4792 89.2858 41.3986 89.1443 41.3449C89.0087 41.2852 88.8436 41.2554 88.649 41.2554H87.2425V42.7501ZM87.2425 40.1903H88.4986C88.6578 40.1903 88.7964 40.1605 88.9143 40.1008C89.0323 40.0412 89.1207 39.9606 89.1797 39.8592C89.2446 39.7577 89.277 39.6384 89.277 39.5012C89.277 39.2804 89.2062 39.1103 89.0647 38.991C88.9232 38.8716 88.7109 38.812 88.4278 38.812H87.2425V40.1903Z"
        fill="#04ACF4"
      />
      <path
        d="M102.719 43.9137L105.142 37.6484H106.328L108.743 43.9137H107.38L106.133 40.5394C106.098 40.4439 106.054 40.3216 106 40.1724C105.953 40.0233 105.903 39.8651 105.85 39.6981C105.797 39.531 105.747 39.3729 105.7 39.2237C105.653 39.0686 105.617 38.9403 105.594 38.8388L105.832 38.8299C105.797 38.9671 105.756 39.1103 105.709 39.2595C105.667 39.4027 105.623 39.5519 105.576 39.707C105.529 39.8562 105.479 40.0024 105.426 40.1456C105.372 40.2888 105.322 40.429 105.275 40.5662L104.028 43.9137H102.719ZM103.842 42.598L104.276 41.515H107.115L107.557 42.598H103.842Z"
        fill="#04ACF4"
      />
      <path
        d="M121.867 43.9137V37.6484H123.194V42.7054H125.998V43.9137H121.867Z"
        fill="#04ACF4"
      />
      <path
        d="M109.148 30.6697V13.4688H112.757L118.812 23.6664L116.224 23.6421L122.351 13.4688H125.817V30.6697H121.899V25.3123C121.899 23.838 121.931 22.512 121.994 21.332C122.074 20.1525 122.2 18.9813 122.374 17.8181L122.825 19.0959L118.219 26.4923H116.652L112.14 19.1451L112.591 17.8181C112.765 18.9157 112.884 20.046 112.948 21.2092C113.026 22.3555 113.066 23.7232 113.066 25.3123V30.6697H109.148Z"
        fill="#0F4B61"
      />
      <path
        d="M93.8438 30.6697V13.4688H105.431V17.0318H97.7617V27.1066H105.668V30.6697H93.8438ZM95.7434 23.6178V20.2263H104.362V23.6178H95.7434Z"
        fill="#0F4B61"
      />
      <path
        d="M75.5 30.6697V13.4688H82.4567C83.6753 13.4688 84.792 13.6817 85.8045 14.1076C86.8182 14.5171 87.6885 15.1069 88.4165 15.8769C89.1614 16.6469 89.7304 17.5561 90.1269 18.6045C90.5222 19.6529 90.7205 20.8078 90.7205 22.0692C90.7205 23.3303 90.5222 24.494 90.1269 25.5581C89.7304 26.6071 89.1614 27.5158 88.4165 28.2866C87.6885 29.04 86.8182 29.63 85.8045 30.0554C84.792 30.4645 83.6753 30.6697 82.4567 30.6697H75.5ZM79.5367 27.8195L78.9901 26.9838H82.338C83.0033 26.9838 83.5969 26.869 84.1189 26.6395C84.6419 26.3938 85.0843 26.0576 85.4484 25.6323C85.8292 25.2069 86.1215 24.6899 86.3276 24.0837C86.5337 23.4775 86.6356 22.8064 86.6356 22.0692C86.6356 21.332 86.5337 20.6603 86.3276 20.0542C86.1215 19.4482 85.8292 18.9321 85.4484 18.5062C85.0843 18.0802 84.6419 17.7526 84.1189 17.5233C83.5969 17.2775 83.0033 17.1547 82.338 17.1547H78.9184L79.5367 16.3683V27.8195Z"
        fill="#0F4B61"
      />
      <path
        d="M64.6447 30.9157C63.3152 30.9157 62.1122 30.6943 61.0358 30.2527C59.9594 29.8099 59.0333 29.1875 58.2576 28.3842C57.4819 27.5821 56.8883 26.6398 56.4768 25.5583C56.0651 24.4607 55.8594 23.2738 55.8594 21.9958C55.8594 20.718 56.0731 19.5467 56.5005 18.4819C56.9437 17.4007 57.569 16.4587 58.3764 15.656C59.1837 14.8533 60.1335 14.2308 61.2258 13.7885C62.3339 13.3462 63.5369 13.125 64.8351 13.125C65.7535 13.125 66.616 13.2479 67.4235 13.4936C68.2468 13.7393 68.9905 14.0751 69.6558 14.5011C70.32 14.927 70.8823 15.4185 71.3415 15.9754L68.8953 18.6539C68.4999 18.2607 68.0877 17.933 67.661 17.671C67.2488 17.4089 66.8064 17.2041 66.3315 17.0566C65.8566 16.8929 65.3492 16.8109 64.8116 16.8109C64.1149 16.8109 63.4742 16.942 62.888 17.2041C62.3181 17.4661 61.8115 17.8347 61.3683 18.3098C60.9409 18.7685 60.6084 19.3173 60.371 19.9562C60.1335 20.5788 60.0148 21.2586 60.0148 21.9958C60.0148 22.7325 60.1414 23.421 60.3947 24.0596C60.648 24.6983 60.9962 25.2558 61.4395 25.731C61.8827 26.19 62.4051 26.5586 63.0067 26.8368C63.6243 27.0988 64.2807 27.2298 64.9773 27.2298C65.4679 27.2298 65.9271 27.1475 66.355 26.984C66.7985 26.8206 67.1782 26.5992 67.4941 26.321C67.8111 26.0417 68.0642 25.7148 68.2546 25.3381C68.4439 24.944 68.5391 24.5268 68.5391 24.084V23.4453L69.0622 24.281H64.4789V21.0129H72.2913C72.3227 21.1931 72.3462 21.4388 72.3619 21.75C72.3787 22.0612 72.3865 22.3639 72.3865 22.6595C72.4022 22.9539 72.41 23.1753 72.41 23.3225C72.41 24.4363 72.2118 25.461 71.8164 26.394C71.4367 27.312 70.898 28.106 70.2013 28.7783C69.5046 29.4494 68.6814 29.9733 67.7316 30.35C66.7817 30.7267 65.7535 30.9157 64.6447 30.9157Z"
        fill="#0F4B61"
      />
      <path
        d="M37.7734 30.6697V13.4688H41.3827L50.0972 25.7551L49.3136 25.6323C49.2344 25.0921 49.1712 24.5752 49.1237 24.0837C49.0762 23.5923 49.0287 23.1089 48.9812 22.6348C48.9495 22.1593 48.9179 21.676 48.8862 21.1846C48.8704 20.6931 48.8545 20.1608 48.8387 19.5874C48.8387 19.014 48.8387 18.3915 48.8387 17.7198V13.4688H52.7567V30.6697H49.0999L40.0055 17.9901L41.2165 18.1621C41.2957 18.9485 41.359 19.6202 41.4065 20.1771C41.4697 20.7177 41.5172 21.2009 41.5489 21.6269C41.5964 22.0529 41.6281 22.4378 41.6439 22.7821C41.6597 23.1263 41.6677 23.4694 41.6677 23.8136C41.6835 24.1417 41.6914 24.5103 41.6914 24.9194V30.6697H37.7734Z"
        fill="#0F4B61"
      />
      <path
        d="M5.46061 41.0147L0 43.7661V17.8851C0 15.0128 1.61133 12.3823 4.14768 11.0973L9.93652 8.17969V33.6828C9.93652 36.7818 8.20583 39.6239 5.46061 41.0147Z"
        fill="url(#paint0_linear_12674_13107)"
      />
      <path
        d="M9.93501 25.0356L7.18978 21.7702C3.53446 17.4164 4.87723 10.7345 9.93501 8.17969V25.0356Z"
        fill="url(#paint1_linear_12674_13107)"
      />
      <path
        d="M9.9375 8.17969V25.0356L17.6659 35.5876V18.7014L9.9375 8.17969Z"
        fill="#95D9E5"
      />
      <path
        d="M24.0355 32.3815L17.6797 35.5864V10.0833C17.6797 6.98424 19.4104 4.14216 22.1556 2.75136L27.6162 0V26.5159C27.6013 29.0103 26.2287 31.2779 24.0355 32.3815Z"
        fill="url(#paint2_linear_12674_13107)"
      />
      <path
        d="M17.6641 35.5893V18.7031L20.4242 21.9987C24.0795 26.3677 22.7218 33.0344 17.6641 35.5893Z"
        fill="url(#paint3_linear_12674_13107)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_12674_13107"
          x1="4.97009"
          x2="4.97009"
          y1="8.17775"
          y2="43.7639"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_12674_13107"
          x1="7.5281"
          x2="7.5281"
          y1="25.034"
          y2="8.17775"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_12674_13107"
          x1="22.6398"
          x2="22.6398"
          y1="35.5882"
          y2="0.00208716"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_12674_13107"
          x1="20.0828"
          x2="20.0828"
          y1="18.7039"
          y2="35.5911"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export const Logo = isGlobalProject() ? LogoGlobal : LogoFinance;
